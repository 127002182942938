import { Box, Button } from '@mui/material'
import { FormattedDate } from 'react-intl'
import { BusinessPaginatedTable } from 'src/components/BusinessPaginatedTable'
import { useMerchantContext } from 'src/contexts/merchant-context'

import { DocumentUtils } from './DocumentUtils'

export const DocumentsTable = (props: any) => {
  const merchantContext = useMerchantContext()

  const columns = [
    {
      header: "Data",
      Cell: (row) => {
        return (
          <div>
            <FormattedDate value={row.documentDate} />
          </div>
        )
      }
    },
    {
      header: "Rodzaj / Numer",
      Cell: (row) => {
        return (
          <div>
            <Box>{DocumentUtils.documentTypeLabel(row.type)}</Box>
            <Box>{row.number}</Box>
          </div>
        )
      }
    },
    {
      header: "Status",
      Cell: (row) => {
        return <Box>{DocumentUtils.documentStatusLabel(row.status)}</Box>
      }
    },
    {
      header: "Akcje",
      Cell: (row) => {
        return (
          <div>
            <Button
              onClick={() => {
                props.onClickPay(row)
              }}
            >
              Opłać
            </Button>
          </div>
        )
      }
    }
  ]

  const handleBuildRowLink = (row) => {
    return `/firma/dokumenty/${row.id}`
  }
  return (
    <div>
      {merchantContext.merchant && (
        <BusinessPaginatedTable
          apiUrl={`/v1/documents?merchant_id=${merchantContext.merchant.id}`}
          columns={columns}
          rowLinkFn={handleBuildRowLink}
        />
      )}
    </div>
  )
}
