import React from "react"
import {
  getHintBaloonHorizontalPosStyle,
  getHintBottomOffsetClass,
  getHintBaloonVerticalPosClass
} from "./map-marker-helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt"
import { faMale } from "@fortawesome/free-solid-svg-icons/faMale"

function getMarkerHolderStyle(size, origin) {
  const left = -size.width * origin.x
  const top = -size.height * origin.y
  return {
    position: "absolute",
    width: size.width,
    height: size.height,
    left: left,
    top: top,
    cursor: "pointer"
  }
}

function getSvg(isHoovered, isCurrentPin) {
  //  W width="108.7px"
  //     height="115.4px"
  //     viewBox="0 0 108.7 115.4"
  let fillColor = isHoovered ? "#ec683a" : "#eeeeee"
  let borderColor = isHoovered ? "#ec683a" : "#EC683A"
  if (isCurrentPin) {
    fillColor = "#0000FF"
    borderColor = "#ec683a"
  }
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      scale="0.5"
      width="28px"
      height="28px"
      viewBox="0 0 108.7 115.4"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={borderColor}
          d="M54.6,10.7c21.6,0,39.2,21,39.2,47c0,26-28.2,47-39.2,47
  c-9.7,0-39.2-21-39.2-47C15.4,31.8,33,10.7,54.6,10.7z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fillColor}
          d="M54.6,16.5c16.7,0,30.2,16.3,30.2,36.3c0,20-21.8,36.3-30.2,36.3
  c-7.5,0-30.2-16.3-30.2-36.3C24.3,32.7,37.9,16.5,54.6,16.5z"
        />
      </g>
    </svg>
  )
}

function getMarkerStyle(size, origin) {
  const sizeOriginX = size.width * origin.x
  const sizeOriginY = size.height * origin.y

  return {
    position: "absolute",
    width: size.width,
    height: size.height,
    left: 0,
    top: 0,
    willChange: "transform", // it looks like this setting make firefox random marker movements smaller
    backgroundSize: `${size.width}px ${size.height}px`,
    backgroundRepeat: "no-repeat",
    // transition: 'transform 0.25s ease',
    transition: "transform 0.25s cubic-bezier(0.485, 1.650, 0.545, 0.835)",
    WebkitTransition:
      "-webkit-transform 0.25s cubic-bezier(0.485, 1.650, 0.545, 0.835)",
    transformOrigin: `${sizeOriginX}px ${sizeOriginY}px`,
    WebkitTransformOrigin: `${sizeOriginX}px ${sizeOriginY}px`
  }
}

class MapMarker extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    console.log("Render MaMarker", this.props)
    const { item } = this.props
    const { hasTooltip, isHoovered } = this.state
    const origin = { x: 14 / 28, y: 1 }
    const size = { width: 28, height: 28 }
    const markerHolderStyle = getMarkerHolderStyle(size, origin)
    const isCurrentPin = false
    const svg = getSvg(isHoovered, isCurrentPin)

    const mapWidth = this.props.$geoService.getWidth()
    const mapHeight = this.props.$geoService.getHeight()
    const markerDim = this.props.$getDimensions(this.props.$dimensionKey)
    console.log("markerDim", markerDim)

    const markerHintStyle = {}
    // getHintBaloonHorizontalPosStyle(
    //   markerDim.x,
    //   this.props.size.width,
    //   this.props.origin.x,
    //   mapWidth
    // )
    const hintBalloonBottomOffsetClass = {}
    // getHintBottomOffsetClass(
    //   this.props.size.width,
    //   this.props.origin.x
    // )
    const hintBaloonVerticalPosClass = getHintBaloonVerticalPosClass(
      markerDim.y
      // mapHeight
    )

    let icon = faMapMarkerAlt
    let iconStyle = {}
    return (
      <div className="RecoMapMarker__wrapper">
        <div
          className={`MapMarker 
          ${hasTooltip ? " MapMarker--showHint " : ""}
          ${isHoovered ? "MapMarker--hoovered " : ""}
          ${hintBalloonBottomOffsetClass} ${hintBaloonVerticalPosClass}`}
        >
          <span className="MapMarker-icon">
            <FontAwesomeIcon
              icon={icon}
              style={iconStyle}
              size={item.id === "user_location" ? "2x" : undefined}
            />
          </span>
          <span className="RecoMapMarker__name">{item ? item.name : ""}</span>
        </div>
      </div>
    )
  }
}

export default MapMarker
