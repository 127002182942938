import axios from 'axios'
import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useAuthContext } from 'src/auth/auth-context'
import { ToastrType, useToastr } from 'src/components/MojeToastr'
import { useMerchantContext } from 'src/contexts/merchant-context'

export enum PaymentStep {
  IDLE,
  GETTING_LINK,
  LINK_RECEIVED
}

export interface PaymentContextType {
  step: PaymentStep
  startPayingDocument: Function
  payment?: any
  showWindow?: boolean
  closePopup: Function
}

export const PaymentContext = createContext<PaymentContextType>({
  step: PaymentStep.IDLE,
  startPayingDocument: (doc) => {},
  closePopup: () => {}
})

const initialState = {
  step: PaymentStep.IDLE
}

console.log("Merchsant context initial State", initialState)

function reducer(state, action) {
  console.log("Reducer: ", action)
  switch (action.type) {
    case "gettingLink":
      return {
        ...state,
        step: PaymentStep.GETTING_LINK,
        showWindow: true,
        merchant: action.payload.merchant
      }
    case "linkReceived":
      return {
        ...state,
        step: PaymentStep.LINK_RECEIVED,
        showWindow: true,
        payment: action.payload.payment
      }
    case "closePopup": {
      return {
        ...state,
        showWindow: false
      }
    }
    default:
      throw new Error()
  }
}

export const PaymentContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [popupBlockedLink, setPopupBlockedLink] = useState<any>()
  const authContext = useAuthContext()
  const merchantContext = useMerchantContext()
  const showToastr = useToastr()
  const initialize = async () => {}

  useEffect(() => {
    console.log("PaymentContext Initialization")
    initialize()
  }, [])

  useEffect(() => {}, [authContext.currentUser])

  //   const getPaymentLink = async ({ amount, description }) => {
  //     const order = {
  //       amount: 12350,
  //       description: "Abonament MojeUrodziny.pl",
  //       email: authContext.cognitoUser.attributes.email,
  //       merchantId: merchants[0].id,
  //       type: "MERCHANT_SUBSCRIPTION",
  //       isRecurrent: true
  //     }

  //     setIsGettingLink(true)
  //     try {
  //       const url = `${process.env.REACT_APP_API_URL}/v1/payments/initMerchantPayment`
  //       const res = await axios({ url, method: "POST", data: order })
  //       console.log("res", res)
  //       if (res.status === 200 && res.data?.link) {
  //         setIsGettingLink(false)
  //         setPendingTransactionToken(res.data.token)
  //         window.open(res.data?.link, "_blank").focus()
  //       }
  //     } catch (err) {
  //       setIsGettingLink(false)
  //     }
  //   }

  const startPayingDocument = async (document) => {
    console.log("startPayingDocument", document)
    const url = `${process.env.REACT_APP_API_URL}/v1/documents/${document.id}`

    const command = {
      aggregateId: document.id,
      type: "Document.CreatePayment",
      payload: {}
    }

    try {
      console.log("Dispatch gettingLink")
      dispatch({
        type: "gettingLink",
        payload: {}
      })
      const res = await axios({ url, method: "POST", data: { command } })
      console.log("Res", res.data)

      if (res.status === 200 && res.data?.payment) {
        const payment = res.data.payment
        console.log("PAyment:", payment)
        dispatch({
          type: "linkReceived",
          payload: {
            payment
          }
        })
        // setIsGettingLink(false)
        // setPendingTransactionToken(payment.token)
        console.log("Open link: ", payment.link)
        var w = window.open(payment.link, "_blank")
        setPopupBlockedLink(payment.link)
        if (w) {
          w.focus()
        } else {
        }
        // console.log("w:", w)
        return payment
      }
    } catch (err) {
      console.error(err)
      showToastr("Nie udało się utworzyć linku do płatności", {
        type: ToastrType.ERROR,
        message: (err as any).response?.data?.message
      })
    }
    // const link = await getPaymentLink({amount: document.amount, })
  }

  const closePopup = () =>
    dispatch({
      type: "closePopup"
    })

  const contextValue: PaymentContextType = {
    step: state.step,
    payment: state.payment,
    showWindow: state.showWindow,
    startPayingDocument,
    closePopup
  }
  console.log("PaymentContext", contextValue, state)
  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  )
}

export const usePaymentContext = (): PaymentContextType =>
  useContext(PaymentContext) as PaymentContextType
