import { Box } from '@mui/material'
import { FormattedDate, FormattedTime } from 'react-intl'
import { BusinessPaginatedTable } from 'src/components/BusinessPaginatedTable'
import { useMerchantContext } from 'src/contexts/merchant-context'
import { PaymentUtils } from 'src/pages/company/PaymentUtils'

export const PaymentsTable = (props) => {
  const merchantContext = useMerchantContext()

  const columns = [
    {
      accessor: "created_at",
      header: "Data",
      Cell: (row) => {
        return (
          <div>
            {row.createdAt && (
              <>
                <FormattedDate value={row.createdAt} />{" "}
                <FormattedTime value={row.createdAt} />
              </>
            )}
          </div>
        )
      }
    },

    {
      header: "Rodzaj",
      Cell: (row) => {
        return (
          <Box textAlign="right">{PaymentUtils.paymentTypeLabel(row.type)}</Box>
        )
      }
    },
    {
      header: "Kwota",
      Cell: (row) => {
        return (
          <Box textAlign="right">{PaymentUtils.formatAmount(row.amount)}</Box>
        )
      }
    },
    {
      header: "Status",
      Cell: (row) => {
        return (
          <Box textAlign="right">{PaymentUtils.formatStatus(row.status)}</Box>
        )
      }
    }
  ]

  return (
    <Box>
      {merchantContext.merchant && (
        <BusinessPaginatedTable
          columns={columns}
          apiUrl={`/v1/payments?merchant_id=${merchantContext.merchant?.id}`}
          //   rowLinkFn={(article) => `/admin/articles/${article.id}`}
        />
      )}
    </Box>
  )
}
