import { Box, Typography } from '@mui/material'

export const MojeNotFound = ({
  title,
  children
}: {
  title: string
  children?: any
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f6f7",
        border: "1px solid #adb1b5",
        borderRadius: "4px",
        color: "#70767c"
      }}
      p={4}
    >
      <Box>
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      {children && <Box mt={2}>{children}</Box>}
    </Box>
  )
}
