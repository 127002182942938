import { MojeDropdown } from "./MojeDropdown"

export const HoursDropdown = (props) => {
  var options = [
    {
      value: undefined,
      name: "Wybierz godzinę"
    }
  ]
  for (let i = 0; i < 24; i++) {
    var label = (i < 10 ? "0" : "") + i
    options.push({
      value: label + ":00",
      name: label + ":00"
    })
    options.push({
      value: label + ":30",
      name: label + ":30"
    })
  }
  options.push({
    value: "24:00",
    name: "24:00"
  })

  return <MojeDropdown options={options} {...props} />
}
