import React from "react"

import { SectionMedia } from "../../components/media/SectionMedia"
import { useToastr } from "../../components/MojeToastr"
import { useMerchantApi } from "./api/use_merchant_api"
import { MerchantForm } from "./MerchantForm"

export const MerchantPage = (props) => {
  const { runCommand, isSaving } = useMerchantApi()
  const showToastr = useToastr()
  const handleAddMedia = async (newPhoto) => {
    console.log("handleAddMedia", newPhoto)

    const command = {
      type: "Merchant.AddMedia",
      aggregateId: props.merchant.id,
      payload: {
        ...newPhoto
      }
    }

    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr("Zdjęcie dodane")
      props.refetch()
    } else {
      showToastr("Save error")
    }
  }

  const handleRemoveMedia = async (mediaId) => {
    console.log("handleRemoveMedia", mediaId)

    const command = {
      type: "Merchant.RemoveMedia",
      aggregateId: props.merchant.id,
      payload: {
        id: mediaId
      }
    }

    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr("Zdjęcie usunięte")
      props.refetch()
    } else {
      showToastr("Problem z zapisem")
    }
  }
  return (
    <div>
      <MerchantForm
        merchant={props.merchant}
        isEdited={props.isEdited}
        adminMode={props.adminMode}
        refetch={props.refetch}
      />
      {props.merchant && (
        <SectionMedia
          media={props.merchant.media}
          onAddMedia={handleAddMedia}
          onRemoveMedia={handleRemoveMedia}
        />
      )}
    </div>
  )
}
