import useAxios from 'axios-hooks'
import { useState } from 'react'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { LoadingWidget } from '../../../components/LoadingWidget'
import { CategoriesTable } from '../../../modules/categories/CategoriesTable'

export default function AdminCategoriesIndex(props) {
  let url = `${process.env.REACT_APP_API_URL}/v1/categories`
  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })
  return (
    <PageContainer>
      {loading && <LoadingWidget />}
      <CategoriesTable items={data} refetch={refetch} />
    </PageContainer>
  )
}
