import { Box, Button } from "@mui/material"
import { convertFromHTML, convertToHTML } from "draft-convert"
import { EditorState } from "draft-js"
import React, { useState } from "react"
import uuidv4 from "uuid"

import { BusinessTable } from "../../../../components/business-table"
import { DraftEditor } from "../../../../components/DraftEditor"
import { MButton } from "../../../../components/MButton"
import { MojeFormField } from "../../../../components/MojeFormField"
import { MojeTextInput } from "../../../../components/MojeTextInput"
import { ToastrType, useToastr } from "../../../../components/MojeToastr"
import { DowUtils } from "../../../../utils/dow-utils"
import { usePlaceApi } from "../../api/use_place_api"
import { ProductGalleryEdit } from "./ProductGalleryEdit"

export const buildPriceRows = (product) => {
  const rows = []
  for (let i = 0; i < 7; i++) {
    var dayRow = { dow: i + 1 }
    const pricingRow =
      product.pricing && Array.isArray(product.pricing)
        ? product.pricing.find((r) => r.dow === i + 1)
        : undefined
    if (pricingRow) {
      dayRow = { ...dayRow, ...pricingRow }
    }
    rows.push(dayRow)
  }
  return rows
}

export const ProductForm = ({
  product,
  onRemove,
  venue,
  onCancel,
  onSaved,
  nextProductId,
  setNextProductId
}) => {
  const [value, setValue] = useState<any>({ ...product })
  const [description, setDescription] = useState(
    product?.description
      ? EditorState.createWithContent(convertFromHTML(product.description))
      : EditorState.createEmpty()
  )
  const { runCommand, isSaving } = usePlaceApi()
  const showToastr = useToastr()
  const onChange = () => {}

  const rows = buildPriceRows(value)

  console.log("rows", rows)
  var updatePrice = (dow, newPrice) => {
    console.log("updatePrice", dow, value, value)
    const newPricing =
      value.pricing && Array.isArray(value.pricing) ? [...value.pricing] : []
    newPricing[dow - 1] = newPrice
    setValue({ ...value, pricing: newPricing })
  }
  const handleCopyAllDays = () => {
    console.log("handleCopyAllDays", value.pricing)
    if (!value.pricing) {
      return
    }
    const newPricing =
      value.pricing && Array.isArray(value.pricing) ? [...value.pricing] : []

    for (var i = 1; i < 7; i++) {
      if (!newPricing[i]) {
        newPricing[i] = { dow: i + 1 }
      }
      newPricing[i].dow = i + 1
      newPricing[i].price = newPricing[0].price
      newPricing[i].additionalPersonPrice = newPricing[0].additionalPersonPrice
    }
    console.log("newPricing", newPricing)
    setValue({ ...value, pricing: newPricing })
  }

  var columns = [
    {
      accessor: "dow",
      header: "Dzien tygodnia",
      align: "center",
      Cell: (row) => {
        return <div>{DowUtils.getDowName(row.dow)}</div>
      }
    },
    {
      accessor: "price",
      header: "Cena",
      align: "center",
      Cell: (row: any) => (
        <MojeTextInput
          name="price"
          type="number"
          inputProps={{ min: "0", step: "0.01" }}
          value={row.price}
          onChange={(event) =>
            updatePrice(row.dow, {
              ...row,
              price: event.currentTarget.value
            })
          }
        />
      )
    },
    {
      accessor: "additionalPersonPrice",
      header: "Dodatkowa osoba",
      align: "center",
      Cell: (row) => (
        <MojeTextInput
          name="additionalPersonPrice"
          type="number"
          inputProps={{ min: "0", step: "0.01" }}
          value={row.additionalPersonPrice}
          onChange={(event) =>
            updatePrice(row.dow, {
              ...row,
              additionalPersonPrice: event.currentTarget.value
            })
          }
        />
      )
    },
    {
      header: "",
      align: "center",
      Cell: (row: any) =>
        row && row.dow == 1 ? (
          <div>
            <Button onClick={handleCopyAllDays}> Kopiuj </Button>
          </div>
        ) : (
          <div />
        )
    }
  ]

  const handleSave = async () => {
    console.log("handleSave")

    const product = {
      ...value,
      description: convertToHTML(description.getCurrentContent())
    }
    const command = {
      aggregateId: venue.id,
      type: product.id ? "Venue.UpdateProduct" : "Venue.AddProduct",
      payload: {
        ...product
      }
    }
    if (!command.payload.id) {
      command.payload.id = nextProductId
    }
    try {
      const res: any = await runCommand(command)
      console.log("Update reslt ", res)
      if (res && res.success) {
        showToastr("Scenariusz zapisany", {
          message: "Super!",
          onYes: () => {
            setNextProductId(uuidv4())
            onSaved()
          }
        })
        // props.refetch()
      }
    } catch (error) {
      showToastr("Problem z zapisaniem produktow", { type: ToastrType.ERROR })
      // toastr.error("Can't save Opening Hours")
    }
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <MojeTextInput
            name="name"
            value={value.name}
            placeholder="Nazwa Scenariusza"
            onChange={(event) =>
              setValue({ ...value, name: event.currentTarget.value })
            }
          />
        </div>
      </div>

      <MojeFormField
        label="Opis"
        name="description"
        isEdited
        value={description}
        onChange={(value) => {
          setDescription(value)
        }}
        roComponent={
          <div
            style={{ border: "1px solid #eee", padding: 16 }}
            dangerouslySetInnerHTML={{
              __html: product.description
            }}
          ></div>
        }
      >
        <DraftEditor placeholder={"Wprowadź opis scenariusza"} />
      </MojeFormField>

      <Box p={2}>
        <ProductGalleryEdit
          productId={product && product.id ? product.id : nextProductId}
          editable
        />
      </Box>

      <MojeFormField
        name="guestsCount"
        value={value.guestsCount}
        onChange={(event) =>
          setValue({
            ...value,
            guestsCount: event.currentTarget.value
          })
        }
        label="Ilość osób w cenie podstawowej"
        isEdited
      >
        <MojeTextInput type="number" placeholder="Ilość osób" />
      </MojeFormField>
      <BusinessTable small data={rows} columns={columns} />
      <Box
        display="flex"
        style={{
          borderTop: "1px solid #ccc",
          paddingTop: 8
        }}
      >
        {product.id && (
          <MButton
            label="Usuń Scenariusz"
            onClick={() => onRemove(product)}
            color="secondary"
          />
        )}
        <div style={{ flex: 1 }} />
        <Button title="Anuluj" onClick={onCancel}>
          Anuluj
        </Button>
        <MButton
          label="Zapisz"
          onClick={handleSave}
          variant="contained"
          color="primary"
          isSaving={isSaving}
        ></MButton>
      </Box>

      {/* {JSON.stringify(value)} */}
    </>
  )
}
