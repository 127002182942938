import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useState } from "react"
import { MButton } from "../../components/MButton"
import { MojeTextInput } from "../../components/MojeTextInput"

import { LoginByEmailFormStyled } from "./LoginByEmailForm"

const STATUS_REQUEST_OK = "REQUEST_OK"
const STATUS_ENTER_PASSWORD = "STATUS_ENTER_PASSWORD"
const STATUS_ERROR = "STATUS_ERROR"
const STATUS_SUCCESS = "STATUS_SUCCESS"

export const ResetTemporaryPasswordForm = (props) => {
  const [status, setStatus] = useState<string>(STATUS_ENTER_PASSWORD)

  const [message, setMessage] = useState<string>(undefined)

  const handleSubmit = async (values, props) => {
    console.log("handleSubmit", values, props)
    console.log("tmp user", props.tmpUser)
    props.setSubmitting(true)
    try {
      //   const user = new CognitoUser({
      //     Username: values.email,
      //     Pool: new CognitoUserPool({
      //       UserPoolId: "eu-central-1_TfARXO8oi",
      //       ClientId: "eu-central-1"
      //     })
      //   })
      const user = await Auth.signIn(values.email, values.oldPassword)
      console.log("user", user)
      var res = await Auth.completeNewPassword(
        user,
        // values.oldPassword,
        values.newPassword
      )
      console.log("Forgot password res", res)
    } catch (err) {
      console.log("err", err)
    }
    setStatus(STATUS_REQUEST_OK)
  }

  //   const doSetNewPassword = async (values) => {
  //     console.log("doSetNewPassword")
  //     try {
  //       var res = await Auth.set(values.email, values.code, values.newPassword)
  //       console.log("Result", res)
  //       setStatus(STATUS_SUCCESS)
  //     } catch (err) {
  //       console.log("Err")
  //       setStatus(STATUS_ERROR)
  //       setErrorMessage(err.message)
  //     }
  //   }

  const setErrorMessage = (message) => {
    setMessage(message)
  }

  return (
    <LoginByEmailFormStyled>
      <div>Ustaw nowe hasło</div>

      <Formik initialValues={{} as any} onSubmit={handleSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit} className="Form">
            <div className="Field">
              <MojeTextInput
                type="text"
                className="form-control mps-metro-input"
                id="email"
                name="email"
                placeholder="Email"
                required
                onChange={handleChange}
                value={values.email}
              />
              <MojeTextInput
                type="text"
                className="form-control mps-metro-input"
                id="oldPassword"
                name="oldPassword"
                placeholder="Stare hasło"
                required
                onChange={handleChange}
                value={values.oldPassword}
              />
              <>
                <div className="Field">
                  <MojeTextInput
                    type="password"
                    className="form-control mps-metro-input"
                    id="newPassword"
                    name="newPassword"
                    placeholder="Nowe hasło"
                    required
                    onChange={handleChange}
                    value={values.newPassword}
                  />
                </div>
              </>
              <div className="Field">
                <MButton
                  label={"Ustaw nowe hasło"}
                  submit
                  isSaving={isSubmitting}
                />
              </div>
              {status == STATUS_ERROR && <>{message}</>}
              {status == STATUS_SUCCESS && (
                <>
                  <div>Hasło zostało zmienione!</div>
                  <MButton
                    label="Zaloguj"
                    primary
                    onClick={props.onGotoLogin}
                  />
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </LoginByEmailFormStyled>
  )
}
