import { Box, Button, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"

export const MerchanSavedDialog = ({ closePopup }) => {
  const router = useHistory()
  return (
    <Box
      style={{
        padding: 24
      }}
    >
      <Typography variant="h6" style={{ textAlign: "center" }}>
        Co dalej ?
      </Typography>
      <Box display="flex">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            closePopup()
            router.push("/venues")
          }}
        >
          Dodaj Ofertę
        </Button>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            closePopup()
          }}
        >
          Zamknij
        </Button>
      </Box>
    </Box>
  )
}
