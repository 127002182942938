import styled from "styled-components"

export const Table = styled.table<any>`
  border-collapse: collapse !important;
  width: 100% !important;
  vertical-align: middle !important;
  color: #484848;
  tr {
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`

export const Th = styled.th<any>`
  border-bottom: 1px solid rgb(235, 235, 235) !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  text-align: left !important;
`

export const Td = styled.td`
  border-bottom: 1px solid rgb(235, 235, 235) !important;
  padding: 16px 8px !important;
  text-align: left !important;
`
