import { Box } from '@mui/material'
import styled from 'styled-components'

const MojeInfoBarStyled = styled.div`
  background: #fafafa;
  border-radius: 15px;
  padding: 16px;

  .Title {
    font-weight: bold;
    padding: 16px 0;
  }

  .Subtitle {
    font-weight: normal;
  }
`

export const MojeInfoBar = (
  {
    title,
    subtitle,
    children
  }: { title: string; subtitle?: string | any; children?: any } = {
    title: undefined,
    subtitle: undefined
  }
) => {
  return (
    <Box my={2}>
      <MojeInfoBarStyled>
        <div className="Title">{title}</div>
        <div className="Subtitle">{subtitle}</div>
        {children && <div className="Content">{children}</div>}
      </MojeInfoBarStyled>
    </Box>
  )
}
