import { Box, Card } from "@mui/material"
import useAxios from "axios-hooks"
import { useLocation } from "react-router"
import { LoadingWidget } from "src/components/LoadingWidget"
import { PaymentUtils } from "./PaymentUtils"
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ContinuePaymentInner = ({ paymentId }) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/payments/${paymentId}`
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return (
    <Card elevation={2}>
      <Box m={4} bgcolor="white">
        {loading && <LoadingWidget />}

        {!loading && data && (
          <>
            <Box my={2} fontSize={28} textAlign="center">
              Płatność
            </Box>
            <Box my={2} fontSize={24} textAlign="center">
              {PaymentUtils.formatAmount(data.amount)} zł
            </Box>
            <Box my={2} fontSize={24} textAlign="center">
              {PaymentUtils.paymentTypeLabel(data.type)}
            </Box>
            <Box my={2} fontSize={18} textAlign="center">
              {data.status === "VERIFIED" && <>Płatność potwierdzona!</>}
            </Box>
          </>
        )}
      </Box>
    </Card>
  )
}
export const ContinuePayment = (props) => {
  console.log("ContinuePayment")
  const loc = useLocation()

  console.log("loc", loc)
  const search = new URLSearchParams(loc.search)

  console.log("search", search)
  const paymentId = search.get("paymentId")
  return (
    <Box>{paymentId && <ContinuePaymentInner paymentId={paymentId} />}</Box>
  )
}
