import React from 'react'

import { BusinessTable } from '../../../../components/business-table'
import { useOwnerSpecialDates } from './useSpecialDatesApi'

export const SpecialDatesList = (props) => {
  const { specialDates } = useOwnerSpecialDates(props.merchantId, props.venueId)

  var columns = [
    {
      accessor: "date",
      header: "Data",
      align: "center",
      Cell: (row) => {
        return <div>{row.date}</div>
      }
    },
    {
      header: "Rodzaj",
      Cell: (row) => {
        return (
          <div>
            {row.type == "UNAVAILABLE" ? "Miejsce niedostępne" : row.type}
          </div>
        )
      }
    },
    {
      header: "Uwagi",
      Cell: (row) => {
        return <div>{row.remarks}</div>
      }
    }
  ]
  return (
    <div>
      {specialDates && <BusinessTable data={specialDates} columns={columns} />}
    </div>
  )
}
