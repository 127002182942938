import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Typography } from "@mui/material"
import React, { useState } from "react"
import styled from "styled-components"

import { ReservationConditions } from "./ReservationConditions"
import { useReservationApi } from "./useReservationsApi"

const BigButtonStyled = styled.div<any>`
  padding: 16px;
  border: 1px solid #eee;
  margin: 16px;

  cursor: pointer;
  transition: background 0.15s ease, border 0.15s ease;
  border-radius: 10px;
  border-radius: 10px;
  font-size: 1.4rem;

  :hover {
    background-color: #f0f0f0;
  }
  ${({ selected }) =>
    selected &&
    `
  border: 1px solid orange;
`}
`

export const ACTION_REJECT = "REJECT"
export const ACTION_ACCEPT = "ACCEPT"
export const ACTION_QUESTION = "QUESTION"

export const AcceptRejectPopup = ({
  reservation,
  action,
  onSaved,
  onClose
}: {
  reservation: any
  action: string
  onSaved: Function
  onClose: Function
}) => {
  const [selectedAction, setSelectedAction] = React.useState(undefined)
  const { runCommand, isSaving } = useReservationApi()
  const [isSuccess, setIsSuccess] = useState(false)
  const renderAction = (name, code) => {
    return (
      <BigButtonStyled
      // selected={code == selectedAction}
      // onClick={() => {
      //   setSelectedAction(code)
      // }}
      >
        {name}
      </BigButtonStyled>
    )
  }

  const handleSave = async () => {
    console.log("handleSave")
    var command
    if (!action) {
      return
    }
    if (action == ACTION_ACCEPT) {
      command = {
        type: "Reservation.Accept",
        aggregateId: reservation.id,
        payload: {}
      }
    }

    if (action == ACTION_REJECT) {
      command = {
        type: "Reservation.Reject",
        aggregateId: reservation.id,
        payload: {}
      }
    }
    try {
      const res: any = await runCommand(command)
      if (res && res.success) {
        setIsSuccess(true)
        onSaved()
      }
    } catch (err) {
      console.log("Error saving res")
    }
  }

  var actionLabel = "Zaakceptuj"
  var title = "Zaakceptuj rezerwację"
  if (action == ACTION_REJECT) {
    actionLabel = "Odrzuć"
    title = "Odrzuć rezerwację"
  }
  return (
    <Box>
      <Box my={2} px={4} textAlign="center">
        <Typography variant="h5">
          <strong> {title}</strong>
        </Typography>
      </Box>
      {/* {renderAction("Zaakceptuj", ACTION_ACCEPT)}
      {renderAction("Zaproponuj Zmianę", ACTION_QUESTION)}
      {renderAction("Odrzuć", ACTION_REJECT)} */}
      <Box my={2} px={2}>
        {action == ACTION_ACCEPT && (
          <div>
            <ReservationConditions reservation={reservation.finalConditions} />
          </div>
        )}

        {/* {selectedAction == ACTION_QUESTION && (
        <SuggestChangeForm
          onSave={handleSendSuggestion}
          reservation={reservation}
        />
      )} */}
        {action == ACTION_REJECT && (
          <div>
            <div>szczegoly odrzucenia</div>
          </div>
        )}
      </Box>
      {!isSuccess && (
        <Box display="flex" bgcolor="#f0f0f0" py={4} px={2}>
          <Box>
            <Button
              variant="text"
              color="secondary"
              onClick={(ev: any) => onClose()}
            >
              Anuluj
            </Button>
          </Box>
          <Box flex={1} />
          <Box>
            <Button color="primary" variant="contained" onClick={handleSave}>
              {isSaving ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                actionLabel
              )}
            </Button>
          </Box>
        </Box>
      )}

      {isSuccess && <Box>Zapisano!</Box>}
    </Box>
  )
}
