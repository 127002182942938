import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import makeStyles from "@mui/styles/makeStyles"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AdminMerchantsDropdown } from "src/components/admin/AdminMerchantsDropdown"
import { BusinessPaginatedTable } from "src/components/BusinessPaginatedTable"
import { MojeFormFieldLabel } from "src/components/MojeFormField"

import { transform } from "../../utils/cloudinary"

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345
    cursor: "pointer",
    textDecoration: "none !important",
    "&:hover": {
      border: "1px solid #ccc"
    }
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto"
    // transition: theme.transitions.create("transform", {
    //   duration: theme.transitions.duration.shortest
    // })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    // backgroundColor: red[500]
  }
}))

export const PlacesList = ({ places }) => {
  const classes = useStyles()
  console.log("Plkaces list ", places)

  return (
    <>
      <>
        <Grid container spacing={2}>
          {places.map((place) => (
            <Grid item xs={12} sm={6}>
              <Link to={`/venues/${place.id}`}>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image={
                      place.media && place.media.length > 0
                        ? transform(
                            place.media[0].url,
                            "c_lfill,g_auto,f_auto,w_512,ar_16:9"
                          )
                        : `/static/images/cards/paella.jpg`
                    }
                    title="Paella dish"
                  />
                  <CardContent>
                    <Typography variant="h5" color="textPrimary" component="h2">
                      {place.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {place.city} {place.address}
                      <div>
                        <strong style={{ textTransform: "uppercase" }}>
                          {place.category?.name}
                        </strong>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </>
    </>
  )
}
