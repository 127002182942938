import { Button, FormControlLabel, Grid } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import { Box } from "@mui/system"
import { Formik } from "formik"
import { useState } from "react"
import { MojeCard } from "src/components/card/MojeCard"
import { MButton } from "src/components/MButton"
import { MojeFormFieldLabel } from "src/components/MojeFormField"
import { useToastr } from "src/components/MojeToastr"
import { VerificationCheckBox } from "src/components/VerificationCheckBox"

import { usePlaceApi } from "../api/use_place_api"

export const VenueCardAdmin = ({ venue, onSaved }) => {
  const { runCommand, isSaving } = usePlaceApi()
  const [values, setValues] = useState(venue)

  const [isEditing, setIsEditing] = useState(false)
  const showToastr = useToastr()

  const placeCheckOptions = [
    {
      label: "Lokalizacja",
      code: "location"
    },
    {
      label: "Opis lokalizacji",
      code: "Location Description"
    },
    {
      label: "Zdjęcia",
      code: "photos"
    },
    {
      label: "Godziny otwarcia",
      code: "opening_hours"
    },
    {
      label: "Oferta (produkty)",
      code: "products"
    }
  ]

  const updateValues = (val) => setValues({ ...values, ...val })
  const handleSubmit = async (values, { setSubmitting }) => {
    const command = {
      type: "Venue.AdminUpdate",
      aggregateId: venue.id,
      aggregateType: "Venue",
      payload: { ...values }
    }
    console.log("command", command)
    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr("Dane administratora zapisane")
      if (onSaved) {
        setIsEditing(false)
        onSaved()
      }
      // TODO - show toastr success, redirect to /merchants/:id
    } else {
      showToastr("Problem z zapisem")
    }

    return true
  }

  const initialValues = { ...venue, verification: venue?.verification ?? {} }
  return (
    <Box>
      <MojeCard title="Narzędzia Administratora">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            return (
              <form onSubmit={formikProps.handleSubmit} className="Form">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MojeFormFieldLabel>
                      Weryfikacja Administratora
                    </MojeFormFieldLabel>
                    <Box>
                      {placeCheckOptions.map((op) => {
                        return (
                          <Box>
                            <VerificationCheckBox
                              label={op.label}
                              code={op.code}
                              isEditing={isEditing}
                              formikProps={formikProps}
                            />
                          </Box>
                        )
                      })}
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      disabled={!isEditing}
                      control={
                        <Checkbox
                          onChange={(ev) => {
                            console.log("Changed is promoted", ev.target.value)
                            updateValues({
                              isPromoted: !values.isPromoted
                            })
                          }}
                          checked={!!values.isPromoted}
                        />
                      }
                      label="Miejsce Promowane"
                    />
                    {/* 
            <MojeFormField
              label="Miejsce promowane"
              name="isPromoted"
              isEdited={true}
              roComponent={() =>
                venue.isPromoted ? "Tak - miejsce promowane" : "Nie"
              }
              onChange={(event) => {
                // formikProps.setFieldValue("isPromoted", event.target.checked)
              }}
            >
              <Checkbox checked={venue.isPromoted} />
            </MojeFormField> */}
                  </Grid>
                </Grid>
                <Box display="flex">
                  <Box flex={1}></Box>
                  <Box>
                    {isEditing && (
                      <MButton
                        label="Zapisz"
                        submit
                        variant="contained"
                        color="primary"
                      />
                    )}
                    {!isEditing && (
                      <Button
                        onClick={(ev: any) => {
                          setIsEditing(true)
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Edytuj
                      </Button>
                    )}
                  </Box>
                </Box>
              </form>
            )
          }}
        </Formik>
      </MojeCard>
    </Box>
  )
}
