import React from "react"
import { Auth, Hub } from "aws-amplify"
import axios from "axios"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"

export interface CurrentUser {
  id: string
  name: string
}

export type License = {
  type?: string
  status?: string
  package_code: string
  valid_from: string
  valid_till: string
  created_at?: string
  canceled_at?: string
}
export interface Merchant {
  id: string
  name: string
  city: string
  address: string
  license?: License
}
export interface AuthContextType {
  currentUser?: CurrentUser
  // merchant?: Merchant
  logout: Function
  refetchCurrentUser: Function
  cognitoUser?: any
  initializationCompleted: boolean
  isLoadingProfile: boolean
  isSuperAdmin: boolean
  homeData?: {
    unreadMessagesCount: number
    reservationsToApprove: number
  }
  refreshHomeData: Function
}

const LOCAL_STORAGE_KEY = "mojeAdminAuthContext"
export const AuthContext = createContext<AuthContextType>({
  logout: () => {},
  refetchCurrentUser: () => {},
  initializationCompleted: false,
  isLoadingProfile: false,
  isSuperAdmin: false,
  refreshHomeData: () => {}
})

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined)
  const [currentCognitoUser, setCurrentCognitoUser] = useState<any>()
  const [isInitializationCompleted, setIsInitializationCompleted] =
    useState<boolean>(false)
  const [isLoadingProfile, setIsLoadingProfile] = useState(false)
  const [homeData, setHomeData] = useState()

  const _rehydrate = async () => {
    console.log("_rehydrate")
    if (window.localStorage) {
      const dataStr = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (dataStr != null) {
        console.log("_rehydrate data", dataStr)
        const data = JSON.parse(dataStr)
        if (data.currentUser) {
          setCurrentUser(data.currentUser)
          return data.currentUser
        }
      }
    }
  }

  // useEffect(() => {
  //   console.log("AuthContext - merchants loaded", merchants)
  //   if (merchants && merchants.length === 1) {
  //     setMerchant(merchants[0])
  //   }
  // }, [merchants])

  useEffect(() => {
    // Access the user session on the client
    console.log("AuthContext Initialization")
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        console.log("User: ", user)
        const rehydrated = await _rehydrate()
        console.log("rehydrated", rehydrated)
        // const currentUser = await refetchCurrentUser()
        setIsInitializationCompleted(true)
        refetchCurrentUser()
      })
      .catch((err) => {
        console.log("check cognito current user err")
        console.log(err)
        setCurrentUser(null)
        setIsInitializationCompleted(true)
      })
  }, [])

  const refreshHomeData = useCallback(async () => {
    console.log("refreshHomeData ")
    let meUrl = process.env.REACT_APP_API_URL + `/home`

    try {
      var result = await axios({
        method: "GET",
        url: meUrl
      })
      console.log("Get home result", result)
      if (result && result.status == 200) {
        setHomeData(result.data)
      } else {
        setHomeData(undefined)
      }
    } catch (err) {
      console.error("Error get homedata")
    }
  }, [])

  useEffect(() => {
    let onCognitoAuthEvent = (event) => {
      console.log("onCognitoAuthEvent", event)
      if (event && event) {
        recheckCognitoUser()
      }
    }
    console.log("Auth Context Init")
    Hub.listen("auth", onCognitoAuthEvent) // listen for login/signup events
    recheckCognitoUser()
    return () => Hub.remove("auth", onCognitoAuthEvent) // cleanup
  }, [])

  const logout = () => {
    console.log("logout")
    Auth.signOut()
    setCurrentUser(undefined)
  }

  const _persist = useCallback(() => {
    if (window.localStorage) {
      if (currentUser) {
        const data = {
          currentUser
        }
        console.log("AuthContext .persist")
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)
      }
    }
  }, [currentUser])

  useEffect(() => {
    console.log("AuthContext Persister")
    if (isInitializationCompleted) {
      _persist()
      refreshHomeData()
    }
  }, [currentUser, isInitializationCompleted, refreshHomeData])

  const recheckCognitoUser = async () => {
    console.log("recheckCognitoUser")
    try {
      let user = await Auth.currentAuthenticatedUser()
      console.log("User:", user)
      if (user) {
        setCurrentCognitoUser(user)
      }
    } catch (e) {
      console.log("AuthContext.Unauthorized")
      setCurrentCognitoUser(undefined)
    }
  }

  const getCurrentCognitoUser = async () => {
    console.log("getCurrentCognitoUser")
    try {
      var cu = await Auth.currentUserInfo()
      console.log("current Cognito User Info", cu)
      return cu
    } catch (e) {
      console.log("getCurrentCognitoUser null")
      return undefined
    }
  }
  const refetchCurrentUser = useCallback(async () => {
    console.log("refetchCurrentUser")
    let meUrl = process.env.REACT_APP_API_URL + `/me`
    const currentCognitoUser = await getCurrentCognitoUser()
    console.log("currentCognitoUser", currentCognitoUser)
    if (!currentCognitoUser) {
      return
    }
    try {
      setIsLoadingProfile(true)
      var result = await axios({
        method: "GET",
        url: meUrl
      })
      console.log("Get me result", result)
      if (result && result.status === 200) {
        setCurrentUser(result.data)
      } else {
        setCurrentUser(undefined)
      }
      setIsLoadingProfile(false)
    } catch (err) {
      console.error("Error get me")
      if (err.response && err.response.status == 401) {
        console.log("GetMe AuthContext.Unauthorized")
        Auth.signOut()
      }
      setIsLoadingProfile(false)
    }
  }, [currentCognitoUser])

  useEffect(() => {
    console.log(
      "on currentCognitoUser changed effect currentCognitoUser",
      currentCognitoUser
    )
    refetchCurrentUser()
  }, [currentCognitoUser])

  console.log("CurrentUser", currentUser)
  console.log("currentCognitoUser", currentCognitoUser?.attributes)
  // should check custom:moje.admin
  var isSuperAdmin =
    currentCognitoUser &&
    currentCognitoUser?.attributes &&
    currentCognitoUser.attributes["custom:moje.admin"] === "true"

  console.log("isSuperAdmin", isSuperAdmin)
  // currentUser &&
  // (currentUser.id === "2a99df6f-d2ce-4f48-a8e8-2d455a0a63f0" ||
  //   currentUser.id === "a0339dcb-5e73-4c2b-b444-4454d698895a" ||
  //   currentUser.id === "81e4186f-7cb1-4539-b23b-eee43704140a")

  const contextValue = {
    logout,
    currentUser,
    recheckCognitoUser,
    refetchCurrentUser,
    cognitoUser: currentCognitoUser,
    initializationCompleted: isInitializationCompleted,
    isLoadingProfile,
    isSuperAdmin,
    homeData,
    refreshHomeData
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextType =>
  useContext(AuthContext) as AuthContextType
