import "./index.css"

import { Box, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material"
import { Auth } from "aws-amplify"
import Amplify from "aws-amplify"
import axios from "axios"
import React from "react"
import ReactDOM from "react-dom"
import { IntlProvider } from "react-intl"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import {
  AuthContext,
  AuthContextProvider,
  AuthContextType
} from "./auth/auth-context"
import { WsContextProvider } from "./auth/ws-context"
import awsexports from "./aws-exports-prod"
import { LoadingWidget } from "./components/LoadingWidget"
import { ToastrContextProvider, ToastRenderer } from "./components/MojeToastr"
import { GlobalCss, theme } from "./components/theme"
import { MerchantContextProvider } from "./contexts/merchant-context"
import { NavBar } from "./modules/menu/NavBar"
import { PaymentContextProvider } from "./modules/payments/api/PaymentContext"
import { PublicHome } from "./modules/public/PublicHome"
import ContactPage from "./pages/contact"
import Profile from "./pages/profile"
import { SignInScreen } from "./pages/profile/SignInScreen"
import { SignUpScreen } from "./pages/profile/SignUpScreen"
import { Routes } from "./Routes"
import { AdminGlobalStyle } from "./theme/admin_global_style"

// import * as serviceWorker from './serviceWorker'
// import App from './App'
// import { AuthContextProvider } from './contexts/auth-context'
// import { ScopeContextProvider } from './contexts/scope-context'
// import { ToastrContextProvider } from '@bold7/ui'

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

axios.interceptors.request.use(async (config) => {
  try {
    console.log("currentAuthenticatedUser")
    console.log(await Auth.currentAuthenticatedUser())

    var cu = await Auth.currentUserInfo()
    console.log("interceptors current user", cu)
    if (cu) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()

      // console.log("Token:", token)
      // const token = store.getState().session.token;
      if (token) {
        config.headers.Authorization = "Bearer " + token
      }
    } else {
      // console.log("Not logged in , skip")
    }
  } catch (err) {
    // console.error("Not logged in")
  }
  return config
})

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log("Catch error interceptor")
    if (err.response && err.response.status === 401) {
      console.log("Unauthorized")
      // Auth.signOut()
      throw err
    } else {
      throw err
      // throw new Error(err.response?.data?.message)
    }
  }
)
Amplify.configure(awsexports)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <IntlProvider locale="pl" defaultLocale="pl">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <GlobalCss />
              <ToastrContextProvider>
                <WsContextProvider>
                  <AuthContext.Consumer>
                    {(authContext: AuthContextType) => {
                      console.log(
                        "authContext.cognitoUser",
                        authContext.cognitoUser
                      )
                      if (!authContext.initializationCompleted) {
                        return (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                          >
                            <LoadingWidget label="Ładowanie..." />
                          </Box>
                        )
                      }
                      if (authContext.cognitoUser) {
                        if (!authContext.currentUser) {
                          return (
                            <>
                              <NavBar />
                              <AdminGlobalStyle />
                              <Profile />
                            </>
                          )
                        }
                        return (
                          <MerchantContextProvider>
                            <div
                              className="min-h-screen"
                              style={{
                                backgroundColor:
                                  "rgba(245,247,250,var(--tw-bg-opacity))"
                              }}
                            >
                              <AdminGlobalStyle />
                              <PaymentContextProvider>
                                <Routes />
                              </PaymentContextProvider>
                            </div>
                          </MerchantContextProvider>
                        )
                      }
                      return (
                        <div
                          className="min-h-screen"
                          style={{
                            backgroundColor:
                              "rgba(245,247,250,var(--tw-bg-opacity))"
                          }}
                        >
                          <NavBar />
                          <AdminGlobalStyle />
                          {/* Not authorized routes */}
                          <Switch>
                            <Route path="/signin" component={SignInScreen} />
                            <Route path="/signup" component={SignUpScreen} />
                            <Route path="/profile" component={Profile} />
                            <Route path="/login" component={Profile} />
                            <Route path="/contact" component={ContactPage} />
                            <Route component={PublicHome} />
                          </Switch>
                          {/* here 2<Component {...pageProps} /> */}
                          {/* <LoginScreen /> */}
                        </div>
                      )
                    }}
                  </AuthContext.Consumer>
                </WsContextProvider>
                <ToastRenderer />
              </ToastrContextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </IntlProvider>
      </AuthContextProvider>
    </Router>
    {/* 
      <AuthContextProvider>
        <ScopeContextProvider>
          <ToastrContextProvider>
            
          </ToastrContextProvider>
        </ScopeContextProvider>
      </AuthContextProvider>
    </Router> */}
    {/* <div>root 2 3</div> */}
  </React.StrictMode>,
  document.getElementById("root")
)
// render()
// serviceWorker.register()
// render(App)
// console.log('Index.tsx, hot', module.hot)

// if ((module as any).hot) {
//   console.log('Run module hot')
//   module.hot.accept('./App', () => {
//     console.log('hot 19')
//     // const NextApp = require('./App').default
//     render(App)
//     // render(NextApp)
//   })
// }
// serviceWorker.unregister()
