import { PageHeader } from 'src/components/PageHeader'
import { usePaymentContext } from 'src/modules/payments/api/PaymentContext'
import { PaymentPendingPopup } from 'src/modules/payments/PaymentPendingPopup'

import { DocumentsTable } from './DocumentsTable'

export const CompanySectionDocuments = (props) => {
  const { startPayingDocument, step, payment } = usePaymentContext()

  console.log("CompanySectionDocuments", step, payment)
  const handlePayDocument = async (document) => {
    console.log("handlePayDocument", document)

    var payment = await startPayingDocument(document)
    console.log("Got payment", payment)
  }
  return (
    <div>
      <PageHeader
        title="Faktury"
        // subtitle="Zacznij od wprowadzenia danych firmy. Twoje miejsca (sale) wprowadzimy później."
        toolsComponents={() => {
          return (
            <>
              {/* {!editedMerchant &&
                !isCreating &&
                merchants &&
                merchants.length > 0 && (
                  <MButton primary onClick={() => setIsCreating(true)}>
                    Dodaj Firmę
                  </MButton>
                )} */}
            </>
          )
        }}
      ></PageHeader>
      <DocumentsTable onClickPay={handlePayDocument} />
      <PaymentPendingPopup />
    </div>
  )
}
