import useAxios from 'axios-hooks'
import { useParams } from 'react-router-dom'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { EditArticlePage } from '../../../modules/articles/EditArticlePage'

export default function ArticlePage(props) {
  const router = useParams<{ id: string }>()
  console.log("Router", router)
  let url = `${process.env.REACT_APP_API_URL}/v1/admin/articles/${router.id}`

  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })

  return (
    <PageContainer>{data && <EditArticlePage article={data} />}</PageContainer>
  )
}
