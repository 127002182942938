import { Button } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useHistory } from "react-router"
import { PageContainer } from "src/components/containers/PageConatiner"

import { CouponPopup } from "./CouponPopup"
import { CouponsTable } from "./CouponsTable"

export default function CouponsIndex(props) {
  const router = useHistory()
  const [item, setItem] = useState<any>()

  const [isAdding, setIsAdding] = useState(false)

  return (
    <PageContainer
      title="Kupony"
      toolsComponents={() => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={(e: any) => {
              setIsAdding(true)
            }}
          >
            Dodaj
          </Button>
        </Box>
      )}
    >
      <CouponsTable onRowClick={setItem} />

      <CouponPopup
        open={isAdding || !!item}
        onClose={() => {
          setIsAdding(false)
          setItem(undefined)
        }}
        onSaved={() => {
          console.log("Coupon saved")
          setIsAdding(false)
          setItem(undefined)
        }}
        isNew={!item}
        coupon={item}
      />
    </PageContainer>
  )
}
