import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, LinearProgress } from '@mui/material'
import axios from 'axios'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useReducer } from 'react'
import { useState } from 'react'

import { BusinessTable, SortParams } from './business-table'
import { MojeFormFieldLabel } from './MojeFormField'
import { MojeTextInput } from './MojeTextInput'

const PaginationWidget = ({
  pageInfo,
  onNext,
  onPrev,
  page,
  limit
}: {
  pageInfo?: any
  onNext: any
  onPrev: any
  page: number
  limit: number
}) => {
  console.log("Page info", pageInfo)
  return (
    <Box display="flex" flexDirection="row" py={2}>
      <Box
        style={{
          cursor: "pointer"
        }}
        onClick={onPrev}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronLeft} />
      </Box>
      <Box flex={1}>
        {pageInfo && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mx={1}>Wyników : {pageInfo.count}</Box>
            <Box mx={1}>Na strone : {limit}</Box>
            <Box mx={1}>Strona : {page} </Box>
            <Box mx={1}>Stron : {pageInfo.pages}</Box>
          </Box>
        )}
      </Box>
      <Box
        style={{
          cursor: "pointer"
        }}
        onClick={onNext}
      >
        <FontAwesomeIcon icon={faChevronRight} />
        <FontAwesomeIcon icon={faChevronRight} />
      </Box>
    </Box>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case "searchChanged":
      return {
        ...state,
        query: action.payload.query,
        page: 0
      }
    case "pageChanged":
      return {
        ...state,
        query: action.payload.query,
        page: action.payload.page
      }
    default:
      throw new Error()
  }
}
export const BusinessPaginatedTable = ({
  apiUrl,
  columns,
  rowLinkFn,
  queryParams,
  filtersComponent,
  onRowClick
}: {
  apiUrl: string
  columns: any
  rowLinkFn?: any
  queryParams?: any
  filtersComponent?: any
  onRowClick?: any
}) => {
  const [state, dispatch] = useReducer(reducer, {
    page: 0
  })
  const [sort, setSort] = useState<SortParams | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<any>()
  // const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(25)

  const handleSortChanged = (_sort) => {
    console.log("handleSortChanged", _sort)
    setSort(_sort)
  }

  const refresh = useCallback(async () => {
    console.log("BusinessPaginatedTable Refresh", sort, queryParams, state)

    var url = `${process.env.REACT_APP_API_URL}${apiUrl}`

    const params: any = queryParams ? { ...queryParams } : {}
    if (sort && sort.field) {
      params.sort = (sort.order === "desc" ? "-" : "") + sort.field
    }
    //
    if (state.page > 0) {
      params.after = state.page * limit
    }
    if (state.query) {
      params.search = state.query
    }

    console.log("params", params, new URLSearchParams(params).toString())
    url = url + "?" + new URLSearchParams(params).toString()

    setIsLoading(true)
    try {
      const res = await axios(url)
      console.log("res", res)
      console.log("Refreshed")
      setResult(res.data)
      if (!res.data.items && Array.isArray(res.data)) {
        setResult({ items: res.data })
      }
      setIsLoading(false)
    } catch (err) {
      console.error("Error loading")
      setIsLoading(false)
    }
  }, [sort, state, queryParams, apiUrl, limit])

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    console.log("queryParams changed", queryParams)
    refresh()
  }, [queryParams])

  useEffect(() => {
    console.log("Refresh on sort changed")
    refresh()
  }, [sort, state])

  const handleSwitchPage = (delta) => {
    console.log("handleSwitchPage", delta, state.page, result)
    if (delta === -1) {
      if (state.page > 0) {
        // setPage(page - 1)
        dispatch({
          type: "pageChanged",
          payload: {
            page: state.page - 1
          }
        })
      }
    } else if (
      delta === 1 &&
      result &&
      result.pageInfo.pages > state.page + 1
    ) {
      console.log("result.pageInfo.pages ", result.pageInfo.pages)
      // setPage(page + 1)
      dispatch({
        type: "pageChanged",
        payload: {
          page: state.page + 1
        }
      })
    }
  }

  const setDebounced = debounce((val) => {
    console.log("set debounced", val)
    dispatch({
      type: "searchChanged",
      payload: {
        query: val
      }
    })
    // setPage(0)
    // setQuery(val ?? "")
  }, 1000)
  const handleQueryChanged = (ev) => {
    console.log("handleQueryChanged", ev.target.value)
    setDebounced(ev.target.value ?? undefined)
  }

  return (
    <Box>
      <Box
        p={1}
        mb={1}
        display="flex"
        bgcolor="white"
        borderRadius={2}
        alignItems="center"
      >
        <Box>
          <Box mx={1}>
            <MojeFormFieldLabel>Szukaj</MojeFormFieldLabel>
          </Box>
          <Box ml={2}>
            <MojeTextInput onChange={handleQueryChanged} />
          </Box>
        </Box>
        <Box flex={1}>{filtersComponent}</Box>
      </Box>
      <Box my={1}>{isLoading && <LinearProgress />}</Box>
      {result && (
        <>
          <BusinessTable
            data={result.items}
            columns={columns}
            rowLinkFn={rowLinkFn}
            onSortChanged={handleSortChanged}
            onRowClick={onRowClick}
          />

          <PaginationWidget
            pageInfo={result?.pageInfo}
            onPrev={() => handleSwitchPage(-1)}
            onNext={() => handleSwitchPage(1)}
            page={state.page}
            limit={limit}
          />
        </>
      )}
    </Box>
  )
}
