import { Menu, MenuItem, Popover } from "@mui/material"
import Box from "@mui/material/Box"
import { ReactNode, useState } from "react"
import { Link, LinkProps, useLocation } from "react-router-dom"
import {
  MerchantContextType,
  useMerchantContext
} from "src/contexts/merchant-context"
import { SuperAdminUtils } from "src/pages/profile/SuperAdminUtils"
import styled from "styled-components"

import { useAuthContext } from "../../auth/auth-context"
import { MessagesIcon } from "./MessagesIcon"
import { WsIcon } from "./WsIcon"
import { OnboardingTasksDropdown } from "./OnboardingTasksDropdown"

const NavBarStyled = styled.div`
  left: 0;
  top: 0;
  right: 0;
  height: 56px;
  width: 100vw;
  // border-bottom: 1px solid #ebebeb;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: fixed;
  background-color: white;
  transition: opacity 0.2s ease;
  z-index: 1;
  box-shadow: 0px 5px 10px 0 #e4e6e8;

  :before {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.2)),
      to(transparent)
    );
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), transparent);
    content: "";
    pointer-events: none;
  }

  .logo {
    height: 100%;
    padding: 16px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    height: 70px;
    font-size: 16px;
    .logo {
      height: 50px;
    }
  }
`

const MenuItemStyledDiv = styled.div<{ selected?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  font-weight: 600;
  transition: background 150ms ease;
  position: relative;
  text-decoration: none !important;
  color: initial;

  :hover {
    background-color: #eee;
  }

  a {
    text-decoration: none !important;
    color: initial;
  }
  ${({ selected }) =>
    selected &&
    `
    border-bottom: 3px solid var(--moje-color-primary);
    font-weight: bold;
    color: var(--moje-color-primary);
  `}
`

const MenuItemStyled = (props) => (
  <Link to={props.to}>
    <MenuItemStyledDiv selected={props.selected}>
      {props.children}
    </MenuItemStyledDiv>
  </Link>
)

const MenuSeparator = styled.div`
  border-left: 1px solid #ddd;
  margin: 8px 0;
`

const OnboardingHelp = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleToggleHelp = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  return (
    <>
      <MenuItemStyledDiv
        className="cursor-pointer rounded hover:bg-gray-100 px-4 py-2 font-medium"
        onClick={handleToggleHelp}
        selected={false}
      >
        Instrukcja konfiguracji
      </MenuItemStyledDiv>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <OnboardingTasksDropdown small />
      </Popover>
    </>
  )
}
const SuperUserNavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const routes = SuperAdminUtils.listRoutes()
  return (
    <>
      <MenuItemStyledDiv onClick={handleClick} selected={false}>
        Super
      </MenuItemStyledDiv>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {routes.map((r) => (
          <Link to={r.path}>
            <MenuItem key={r.label}>{r.label}</MenuItem>
          </Link>
        ))}
        {/* <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem> */}
      </Menu>
    </>
  )
}
const NavBarItem = ({
  children,
  href = "/",
  onClick,
  title
}: {
  children: ReactNode
  href?: LinkProps["to"]
  onClick?: Function
  title?: string
}) => {
  const location = useLocation()
  return (
    <MenuItemStyled
      to={onClick ? undefined : href}
      selected={href && location.pathname === href}
      onClick={onClick}
      title={title}
    >
      {children}
    </MenuItemStyled>
  )
}

export const NavBar = (props) => {
  const location = useLocation()
  const authContext = useAuthContext()
  const merchantContext: MerchantContextType = useMerchantContext()

  return (
    <NavBarStyled
      style={{
        height: authContext.currentUser ? 56 : 56
      }}
    >
      <Link to="/" className="logo">
        <img
          src="/assets/moje_logo.png"
          alt="Logo"
          style={{ height: "100%" }}
        />
      </Link>
      {authContext.cognitoUser ? (
        <>
          <NavBarItem href="/">Home</NavBarItem>
          {merchantContext.onboardingProgress.onboardingCompleted ? (
            <>
              <NavBarItem href="/venues">Oferta</NavBarItem>
              <NavBarItem href="/reservations">
                Rezerwacje
                {authContext.homeData?.reservationsToApprove > 0 && (
                  <Box
                    component="span"
                    sx={{
                      fontSize: "0.75rem",
                      color: "#FFFFFF",
                      backgroundColor: "var(--moje-color-red)",
                      borderRadius: 1,
                      margin: 1,
                      marginLeft: 0.25,
                      padding: 1
                    }}
                  >
                    {authContext.homeData?.reservationsToApprove}
                  </Box>
                )}
              </NavBarItem>
              <NavBarItem href="/messages">Wiadomości</NavBarItem>
              <NavBarItem href="/finances">Rozliczenia</NavBarItem>
              <NavBarItem href={undefined}>
                <Box textAlign="center">
                  Promocje
                  <Box fontSize="0.75rem">Już wkrótce</Box>
                </Box>
              </NavBarItem>
            </>
          ) : (
            <Box>{/* <Box>Ukończ konfigurację</Box> */}</Box>
          )}
          {/* <MenuItemStyled
            to="/camps"
            className="bg-yellow-500"
            selected={location.pathname.indexOf("/camps") >= 0}
            style={{
              backgroundColor: "var(--moje-color-pink)",
              borderRadius: 5
            }}
          >
            <div
              style={{
                backgroundColor: "var(--moje-color-pink)",
                color: "white"
              }}
            >
              <div>Półkolonie</div>
              <div>Dodaj ofertę</div>
            </div>
          </MenuItemStyled> */}
          <div style={{ flex: 1 }} />

          <MenuSeparator />
          <WsIcon />
          <MessagesIcon />
          <NavBarItem href="/pomoc">Pomoc Techniczna</NavBarItem>
          <OnboardingHelp />
          <NavBarItem href="/firma" title={merchantContext.merchant?.name}>
            Firma
          </NavBarItem>
          {authContext.isSuperAdmin && <SuperUserNavBar />}
          <NavBarItem href="/profile">Profil</NavBarItem>
        </>
      ) : (
        <>
          <NavBarItem href="/#dlaczego">Dlaczego warto</NavBarItem>
          <NavBarItem href="/#cennik">Cennik</NavBarItem>
          <NavBarItem href="/contact">Kontakt</NavBarItem>
          <div style={{ flex: 1 }} />
          <MenuSeparator />
          <NavBarItem href="/signin">Logowanie dla firm</NavBarItem>
        </>
      )}
    </NavBarStyled>
  )
}
