import { Box, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const MojeFormFieldDiv = styled.div`
  // padding-bottom: var(--clap-spacing-05);
  margin-bottom: 24px;
`

export const MojeFormFieldLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #323b43;
  margin-bottom: 8px;
`

const MojeFormValueDiv = styled.div<any>`
  ${({ strong }) =>
    strong &&
    `font-weight: 800;
`}
`

const MojeFormFieldErrors = styled.div<any>`
  color: red;
`

export const MojeFormSectionTitle = ({
  label,
  tools
}: {
  label?: string
  tools?: any
}) => {
  return (
    <Box
      mb={3}
      sx={{
        borderTop: "1px solid #ddd"
      }}
      pt={1}
    >
      <Box display="flex">
        <Box flex={1}>
          <Typography variant="h5">{label}</Typography>
        </Box>
        {tools && <div>{tools()}</div>}
      </Box>
    </Box>
  )
}

export const MojeFormField = (props: any) => {
  const Renderer = props.component
  const value = props.value ?? props.formikProps?.values?.[props.name]
  const onChange =
    props.onChange ??
    ((e) => {
      const value: string = e.target.value
      if (props.type === "postcode") {
        const filteredValue = value
          .split("")
          .filter(
            (char) => !(isNaN(Number(char)) || char === " " || char === "-")
          )
          .join("")
        const formatted =
          filteredValue.length > 2
            ? filteredValue.substring(0, 2) +
              "-" +
              filteredValue.substring(2, 5)
            : filteredValue
        props.formikProps.setFieldValue(props.name, formatted)
      } else {
        props.formikProps.handleChange(e)
      }
    })
  const onBlur = props.onBlur ?? props.formikProps?.handleBlur
  return (
    <MojeFormFieldDiv>
      <MojeFormFieldLabel>{props.label}</MojeFormFieldLabel>
      {props.isEdited ? (
        <MojeFormValueDiv strong={props.strong}>
          {props.children && React.Children.count(props.children) === 1
            ? React.cloneElement(props.children, {
                name: props.name,
                value: value,
                onChange: onChange,
                onBlur: onBlur
              })
            : props.children}
        </MojeFormValueDiv>
      ) : (
        <Box fontWeight="bold">
          {props.roComponent
            ? props.roComponent
            : value instanceof Object
            ? ""
            : value}

          {props.isEdited && props.children ? props.children : <></>}
        </Box>
      )}
      {props.isEdited && Renderer && (
        <Renderer
          name={props.name}
          type={props.type === "postcode" ? "text" : props.type}
          inputProps={props.inputProps}
          placeholder={props.placeholder ?? props.label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={!!props.disabled}
          valid={props.valid}
        />
      )}
      {props.name &&
        props.formikProps?.errors?.[props.name] &&
        (props.formikProps?.touched?.[props.name] ||
          props.formikProps.submitCount > 0) && (
          <MojeFormFieldErrors>
            {props.formikProps.errors[props.name]}
          </MojeFormFieldErrors>
        )}
    </MojeFormFieldDiv>
  )
}
