import React from 'react'
import styled from 'styled-components'

// import { calculatePhotoUrl } from './photo-utils'
// type PhotoProps = {

// }

const PhotoStyled = styled.div<any>`
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  ${(props) =>
    props.fullHeight &&
    `
    width: 100%;
    height: 100%;
  `}

  &--fullHeight {
    height: 100%;
  }

  img {
    border-radius: 12px;
  }

  &:hover {
    .Overlay {
      display: initial;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    display: none;
    transition: background-color 150ms ease;
  }

  .Loading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
    color: white;
    text-align: center;
    font-size: 30px;
  }
`

export const LoadingPhoto = (props) => {
  const { photo, src, loadingProgress } = props

  let alt = props.alt || "Image"
  return (
    <PhotoStyled {...props}>
      <img
        alt={alt}
        src={props.src}
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
      />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff90",
          height: loadingProgress + "%",
          color: "red",
          borderRadius: 10,
          display: "flex",
          alignContent: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ margin: "auto" }}>
          {loadingProgress < 100 ? <div>Wgrywam</div> : <div>Zapisuję</div>}
          {/* <FontAwesomeIcon size={36} icon={faSpinner} spin /> */}
        </div>
      </div>
    </PhotoStyled>
  )
}

export const Photo = (props) => {
  const { photo, src, loadingProgress } = props
  //   let url = calculatePhotoUrl(photo, props.width)
  let url = ""
  let isUploading = false
  let alt = props.alt || "Image"
  if (!url && photo.preview) {
    url = photo.preview
    isUploading = true
  }
  if (props.photo && props.photo.url) {
    url = props.photo.url
  }
  console.log("Photo widget", props.photo)
  const photoStyle: any = {}
  if (props.photo?.crop) {
    if (props.photo.crop.y) {
      // photoStyle.marginTop = "-" + props.photo.crop.y.toString() + "%"
      photoStyle.objectPosition = `50% ${props.photo.crop.y}%`
    }
  }
  if (photo) {
    return (
      <PhotoStyled fullHeight={props.fullHeight}>
        {url && (
          <img
            alt={alt}
            src={url}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              ...photoStyle
            }}
          />
        )}
        {!url && photo.data && (
          <img
            alt={alt}
            src={photo.file}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        )}

        <div className="Overlay" style={{ padding: 20 }}>
          {props.actionsRenderer && props.actionsRenderer({ photo })}
        </div>
        {isUploading && (
          <div className="Loading">
            <div>Uploading</div>
            {photo.percentUploaded && <div>{photo.percentUploaded}</div>}
          </div>
        )}
      </PhotoStyled>
    )
  }
  return <div>{photo}</div>
}
