import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select
} from "@mui/material"
import uuidv4 from "uuid"
import axios from "axios"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { MojeFormFieldLabel } from "src/components/MojeFormField"
import { MojeTextInput } from "src/components/MojeTextInput"
import { ToastrType, useToastr } from "src/components/MojeToastr"

import { CouponUtils } from "./CouponUtils"
import { MButton } from "src/components/MButton"

export const CouponPopup = ({
  coupon,
  isNew,
  onClose,
  onSaved,
  open
}: {
  coupon?: any
  isNew?: boolean
  onClose: any
  onSaved: any
  open: boolean
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue
  } = useForm()

  const showToastr = useToastr()
  useEffect(() => {
    if (coupon) {
      setValue("type", coupon.type)
      setValue("code", coupon.code)
      setValue("label", coupon.label)
      setValue("redeem_type", coupon.redeem_type)
      setValue("valid_till", coupon.valid_till)
      // setValue("redeem_type", coupon.redeem_type)
    }
  }, [coupon])

  useEffect(() => {
    console.log("Use effect open", open)
    if (open === false) {
      console.log("Reset form")
      reset()
    }
  }, [open, reset])

  const onSubmit = async (data) => {
    console.log("onSubmit", data)
    const couponId = uuidv4()
    let url = process.env.REACT_APP_API_URL + `/v1/coupons/${couponId}`
    if (!data.code || !data.type) {
      return
    }
    try {
      const command = {
        type: "Coupon.Create",
        aggregateId: couponId,
        payload: {
          id: couponId,
          ...data
        }
      }
      setIsSaving(true)
      var result = await axios(url, {
        method: "POST",
        data: { command }
      })
      setIsSaving(false)
      if (result.status === 200) {
        console.log("Kupon dodany")
        showToastr("Kupon dodany")
        onSaved(result.data)
      }
    } catch (err) {
      setIsSaving(false)
      console.log("err", err)
      console.log(err.response)
      showToastr("Nie udało się dodać kuponu", {
        type: ToastrType.ERROR,
        message: err.response?.data?.message
      })
    }
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle id="simple-dialog-title">Kupon promocyjny</DialogTitle>

      <DialogContent
        style={{ width: "fit-content", minWidth: "500px" }}
      ></DialogContent>
      <DialogActions>
        <Box display="flex" padding={1} width="100%" flexDirection="column">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={1}>
              <MojeFormFieldLabel>Rodzaj</MojeFormFieldLabel>
              <Select {...register("type")} fullWidth>
                <MenuItem value="">Wybierz rodzaj kuponu</MenuItem>
                {CouponUtils.couponTypes.map((type: any) => (
                  <MenuItem value={type.code}>{type.label}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box mb={1}>
              <MojeFormFieldLabel>Kod Kuponu</MojeFormFieldLabel>
              <MojeTextInput {...register("code")} />
            </Box>
            <Box mb={1}>
              <MojeFormFieldLabel>Opis</MojeFormFieldLabel>
              <MojeTextInput {...register("label")} />
            </Box>
            <Box mb={1} display="flex">
              <Box flex={1} mr={1}>
                <MojeFormFieldLabel>Wykorzystanie</MojeFormFieldLabel>
                <MojeTextInput {...register("redeem_type")} />
              </Box>
              <Box flex={1} ml={1}>
                <MojeFormFieldLabel>Ważny do</MojeFormFieldLabel>
                <MojeTextInput {...register("valid_till")} />
              </Box>
            </Box>

            <Box mb={1} display="flex" flexDirection="row">
              <Button variant="contained" color="primary" onClick={onClose}>
                Zamknij
              </Button>
              <Box flex={1} />
              <MButton
                type="submit"
                color="primary"
                variant="contained"
                isSaving={isSaving}
                label="Zapisz"
              />
            </Box>
          </form>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
