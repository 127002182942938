import axios from "axios"
import { useState } from "react"

export const useRegonSearch = () => {
  const [isSearchingNip, setIsSearching] = useState(false)

  let searchUrl = process.env.REACT_APP_API_URL + `/searchNip`

  const doSearchNip = async (nip) => {
    console.log("do search nip", nip)
    setIsSearching(true)
    try {
      var result = await axios({
        method: "POST",
        url: searchUrl,
        data: {
          nip
        }
      })
      console.log("Search nip", result)
      setIsSearching(false)
      return result.data
    } catch (err) {
      console.error("Error searchnip")
    }
    setIsSearching(false)
  }

  return { doSearchNip, isSearchingNip }
}
