import "@draft-js-plugins/image/lib/plugin.css"
import "@draft-js-plugins/inline-toolbar/lib/plugin.css"

import { Box, Button } from "@mui/material"
import axios from "axios"
import { Formik } from "formik"
import { MojeTextArea } from "src/components/MojeTextArea"

import { MojeDropdown } from "../../components/MojeDropdown"
import { MojeFormField } from "../../components/MojeFormField"
import { MojeTextInput } from "../../components/MojeTextInput"

const pageTypeOptions = [
  {
    name: "Strona Landing Page",
    value: "site"
  }
]
export const EditPageForm = ({
  item,
  onSaved
}: {
  item?: any
  onSaved: any
}) => {
  console.log("EditPageForm", item)

  const doSubmit = async (values: any, actions) => {
    console.log("doSubmit", values, actions)

    try {
      actions.setSubmitting(true)
      var res = await save(values)
      console.log("Saved", res)
      onSaved()
    } catch (err) {
      console.error("Error saving article", err)
    }
    actions.setSubmitting(false)
  }

  const save = async (item) => {
    console.log("location", item)
    let url = `${process.env.REACT_APP_API_URL}/v1/admin/pages/${
      item.id ?? "new"
    }`
    const res = await axios(url, {
      method: "POST",
      data: item
    })
    console.log("Saved", item)
    return res.data
  }

  const initialValues = {
    ...item
  }
  return (
    <Box p={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={doSubmit}
        enableReinitialize
      >
        {(formikProps: any) => {
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Box>Tytuł strony (Title HTML)</Box>
              <MojeTextInput
                name="name"
                id="name"
                onChange={formikProps.handleChange}
                value={formikProps.values.name}
              ></MojeTextInput>

              <Box
                display="flex"
                flexDirection="row"
                justifyItems="center"
                alignItems="center"
              >
                <Box flex={1} mr={2}>
                  <MojeFormField
                    label="Url (rozpocznij od /)"
                    name="url"
                    formikProps={formikProps}
                    isEdited={true}
                    roComponent={<div>{formikProps.values.url}</div>}
                  >
                    <MojeTextInput
                      name="url"
                      id="url"
                      onChange={formikProps.handleChange}
                      value={formikProps.values.url}
                    ></MojeTextInput>
                  </MojeFormField>
                </Box>
                <a
                  href={`https://www.mojeurodziny.pl${formikProps.values.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Otwórz
                </a>
              </Box>

              <Box>
                <MojeFormField
                  label="Tytuł w nagłówku"
                  name="title"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.title}</div>}
                >
                  <MojeTextInput
                    name="title"
                    id="title"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.title}
                  />
                </MojeFormField>
              </Box>
              <Box>
                <MojeFormField
                  label="Treść strony"
                  name="headline"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.headline}</div>}
                >
                  <MojeTextArea
                    name="headline"
                    id="headline"
                    onChange={formikProps.handleChange}
                    rows={10}
                    value={formikProps.values.headline}
                  />
                  {/* // </MojeFormField> 
                  //   name="headline"
                  //   id="headline"
                  //   onChange={formikProps.handleChange}
                  //   value={formikProps.values.headline}
                  // ></MojeTextInput> */}
                </MojeFormField>
              </Box>

              <Box>
                <MojeFormField
                  label="Słowa kluczowe (Meta)"
                  name="keywords"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.keywords}</div>}
                >
                  <MojeTextInput
                    name="keywords"
                    id="keywords"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.keywords}
                  ></MojeTextInput>
                </MojeFormField>
              </Box>
              <Box>
                <MojeFormField
                  label="Rodzaj"
                  name="type"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.type}</div>}
                >
                  <MojeDropdown options={pageTypeOptions} />
                </MojeFormField>
              </Box>

              <Box my={2} display="flex" alignItems="flex-end">
                <Button variant="contained" color="primary" type="submit">
                  Zapisz
                </Button>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
