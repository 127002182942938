import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useAuthContext } from 'src/auth/auth-context'

export const MessagesIcon = () => {
  const authContext = useAuthContext()

  const counter = authContext.homeData?.unreadMessagesCount
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: "600"
      }}
      onClick={() => authContext.refreshHomeData()}
    >
      <FontAwesomeIcon icon={faEnvelope} size="1x" />
      {counter > 0 ? (
        <Box
          component="span"
          sx={{
            fontSize: "0.75rem",
            color: "#FFFFFF",
            backgroundColor: "var(--moje-color-red)",
            borderRadius: 1,
            margin: 1,
            marginLeft: 0.25,
            padding: 1
          }}
        >
          {counter}
        </Box>
      ) : (
        counter === 0 && (
          <Box component="span" sx={{ margin: 0.5, marginLeft: 0.25 }}>
            0
          </Box>
        )
      )}
    </Box>
  )
}
