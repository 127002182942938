import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Box } from '@mui/system'

import { PaymentStep, usePaymentContext } from './api/PaymentContext'

export const PaymentPendingPopup = (props: any) => {
  const { startPayingDocument, step, showWindow, closePopup, payment } =
    usePaymentContext()

  const handleClose = () => {}

  console.log("PaymentPendingPopup", payment, step)
  return (
    <Dialog
      onClose={handleClose}
      open={showWindow}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">Płatność</DialogTitle>
      {/* <Box style={getModalStyle()}> */}
      {/* <Box>
        <Typography>Edytuj dzien</Typography>
      </Box> */}
      <DialogContent style={{ width: "fit-content", minWidth: "500px" }}>
        {step === PaymentStep.GETTING_LINK && (
          <Box my={1}>
            <CircularProgress />
            <Box>Trwa generowanie płatności Przelewy24</Box>
          </Box>
        )}
        {step === PaymentStep.LINK_RECEIVED && (
          <Box my={1}>
            <Box>Płatność otwarta w oddzielnym oknie</Box>
            <Box>Jeżeli okno nie otworzyło się - kliknij na link poniżej:</Box>
            {payment && (
              <Box>
                <a href={payment.link} target="_blank" rel="noreferrer">
                  {payment.link}
                </a>
              </Box>
            )}
            <Box my={1}>
              Możesz zamknąć to okno. Po dokonaniu płatności - odśwież okno.
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={(ev: any) => closePopup()}
              >
                Zamknij
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      {/* <DialogActions>
        <Box display="flex" padding={1} width="100%" flexDirection="column">
          {props.product && (
            <LicenseBox product={props.product} mode={props.mode} />
          )}
          <Box flex={1} />
          <Box p={1}>
            <Button variant="contained" color="primary" onClick={handleBuy}>
              Zamów i opłać
            </Button>
          </Box>
          {/* <Button variant="contained" color="primary" onClick={props.onClose}>
            Zamknij
          </Button> */}
      {/* </Box>
      </DialogActions> */}
    </Dialog>
  )
}
