import useAxios from "axios-hooks"

export const useCategories = () => {
  var url = `${process.env.REACT_APP_API_URL}/v1/categories`
  const [{ data, loading }] = useAxios(url, { useCache: false })
  console.log("useCategories", data)
  return {
    categories: data ?? [],
    loading
  }
}

// export const useCategories = () => {
//   const categories = [
//     {
//       id: "SALA_ZABAW",
//       name: "Sala zabaw",
//       type: "PLACE"
//     },
//     {
//       id: "SPORT",
//       name: "Obiekt sportowy",
//       type: "PLACE"
//     },
//     {
//       id: "kawiarnie",
//       name: "Kawiarnie",
//       type: "PLACE"
//     },
//     {
//       id: "RESTAURANT",
//       name: "Restauracja",
//       type: "PLACE"
//     },
//     {
//       id: "OTHER",
//       name: "Inne",
//       type: "PLACE"
//     },
//     {
//       id: "ANIMATIONS",
//       name: "Animacje",
//       type: "SERVICES"
//     },
//     {
//       id: "CAMP",
//       name: "Półkolonie",
//       type: "SERVICES"
//     },
//     {
//       id: "PHOTO",
//       name: "Fotograf",
//       type: "SERVICES"
//     },
//     {
//       id: "BAKERY",
//       name: "Cukiernia",
//       type: "SERVICES"
//     },
//     {
//       id: "CATERING",
//       name: "Catering",
//       type: "SERVICES"
//     }
//   ]

//   return { categories }
// }
