import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useState } from 'react'

import { PageHeader } from '../../components/PageHeader'
import { EditLocationPage } from './EditLocationPage'

export const LocationsList = ({
  items,
  refetch
}: {
  items: any
  refetch: any
}) => {
  const [edited, setEditedItem] = useState<any>()

  return (
    <div>
      <PageHeader
        title="Lokalizacje"
        toolsComponents={() => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={(e: any) => {
                  setEditedItem({})
                }}
              >
                Dodaj
              </Button>
            </>
          )
        }}
      ></PageHeader>

      <Grid container spacing={2}>
        {items &&
          items.map((item: any) => {
            return (
              <Grid item xs={3}>
                {/* <Link href={`/admin/locations/${item.id}`}> */}
                <Box onClick={() => setEditedItem(item)}>
                  <Card>
                    <CardHeader title={item.name} />
                    <CardContent className="p-8">{item.type}</CardContent>
                  </Card>
                </Box>
                {/* </Link> */}
              </Grid>
            )
          })}
      </Grid>
      {edited && (
        <Dialog
          onClose={() => {
            setEditedItem(undefined)
          }}
          aria-labelledby="simple-dialog-title"
          open={edited}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="simple-dialog-title">Edytuj lokalizacje</DialogTitle>

          <DialogContent style={{ width: "fit-content", minWidth: "500px" }}>
            <EditLocationPage
              location={edited}
              locations={items}
              onSaved={() => {
                refetch()
                setEditedItem(undefined)
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
