export enum CouponType {
  SUBSCRIPTION = "SUBSCRIPTION"
}

export enum CouponStatus {
  ACTIVE = "ACTIVE",
  REDEEMED = "REDEEMED",
  INACTIVE = "INACTIVE"
}
export enum CouponRedeemType {
  SINGLE = "SINGLE",
  MULTI = "MULTI"
}

export class CouponUtils {
  static statusLabel = (status: string): string => {
    if (status === "ACTIVE") {
      return "Aktywny"
    }
    return status
  }

  static couponTypes = [
    {
      code: CouponType.SUBSCRIPTION,
      label: "Abonament MojeUrodziny (bizness)"
    }
  ]

  static couponRedeemTypes = [
    {
      code: CouponRedeemType.MULTI,
      label: "Wielorazowy"
    },
    {
      code: CouponRedeemType.SINGLE,
      label: "Jednorazowy"
    }
  ]
}
