import { Box } from "@mui/system"
import { useHistory } from "react-router-dom"
import { PageContainer } from "src/components/containers/PageConatiner"
import { MojeNotFound } from "src/components/MojeNotFound"
import {
  InitializationStatus,
  useMerchantContext
} from "src/contexts/merchant-context"

import { LoadingWidget } from "../../components/LoadingWidget"
import { MButton } from "../../components/MButton"
import { MojeInfoBar } from "../../components/MojeInfoBar"
import { PageHeader } from "../../components/PageHeader"
import { useOwnerVenues } from "../../modules/places/api/use_places"
import { PlacesList } from "../../modules/places/PlacesList"

interface IPlaceProps {
  places?: any
}

const PlacesIndex = (props) => {
  const { venues, loading } = useOwnerVenues()
  const merchantContext = useMerchantContext()
  const router = useHistory()

  const queryParams = {
    merchantId: merchantContext.merchant?.id
  }
  return (
    <PageContainer>
      <PageHeader
        title="Moje Miejsca"
        toolsComponents={() => {
          return (
            <>
              {merchantContext.merchants &&
                merchantContext.merchants.length > 0 && (
                  <MButton
                    variant="contained"
                    color="primary"
                    onClick={() => router.push("/venues/new")}
                  >
                    Dodaj Miejsce
                  </MButton>
                )}
            </>
          )
        }}
      ></PageHeader>

      {merchantContext.initializationStatus !== InitializationStatus.READY ? (
        <>
          <LoadingWidget />
        </>
      ) : (
        <>
          {!merchantContext.merchants ||
          merchantContext.merchants.length === 0 ? (
            <>
              <MojeInfoBar title="Przed dodaniem miejsca ustaw dane firmy">
                <MButton
                  variant="contained"
                  color="primary"
                  onClick={() => router.push("/merchants/new")}
                >
                  Ustaw dane firmy
                </MButton>
              </MojeInfoBar>
            </>
          ) : (
            <>
              <PlacesList places={venues} />
              {!loading &&
                venues?.length === 0 &&
                merchantContext.merchants &&
                merchantContext.merchants.length > 0 && (
                  <Box mt={2}>
                    <div style={{ textAlign: "center" }}>
                      <MojeNotFound title="Nie masz jeszcze żadnych miejsc">
                        <MButton
                          primary
                          variant="contained"
                          color="primary"
                          onClick={() => router.push("/venues/new")}
                        >
                          Dodaj Miejsce
                        </MButton>
                      </MojeNotFound>
                    </div>
                  </Box>
                )}
            </>
          )}
        </>
      )}
    </PageContainer>
  )
}

export default PlacesIndex
