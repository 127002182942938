import axios from "axios"
import { makeUseAxios } from "axios-hooks"
import { useState } from "react"

const useAxios = makeUseAxios({ axios })

export const useSpecialDatesApi = () => {
  const [isSaving, setIsSaving] = useState(false)
  const runCommand = async (command) => {
    console.log("Runing command")

    let commandUrl =
      process.env.REACT_APP_API_URL +
      `/owner/special-dates/${command.aggregateId}`

    return new Promise(async (resolve, reject) => {
      setIsSaving(true)

      var result = await axios({
        method: "POST",
        url: commandUrl,
        data: {
          ...command
        }
      })
      setIsSaving(false)
      if (result && result.status == 200) {
        setIsSaving(false)
        resolve(result.data)
      } else {
        setIsSaving(false)
        reject(result.data)
      }
    })
  }

  return { runCommand, isSaving }
}

// export const useAdminVenues = () => {
//   // TODO here to useAxios
//   const url = `${process.env.REACT_APP_API_URL}/owner/special-dates`

//   const [{ data, loading, error }, refetch] = useAxios(url)

//   const venues = data

//   return { venues, loading: false }
// }

export const useOwnerSpecialDates = (merchantId, venueId = undefined) => {
  var url = `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/special-dates`

  if (venueId) {
    url += "?venueId=" + venueId
  }
  console.log("useOwnerSpecialDates")
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { specialDates: data, loading, refetch }
}
