import { Box, Button } from "@mui/material"
import { MButton } from "../../../components/MButton"
import { useToastr } from "../../../components/MojeToastr"
import { usePlaceApi } from "../api/use_place_api"

export const PublishPlaceWidget = ({ venue, onSaved }) => {
  const { runCommand, isSaving } = usePlaceApi()
  const showToastr = useToastr()

  const doPublish = async () => {
    const command = {
      type: venue.publishedAt ? "Venue.Unpublish" : "Venue.Publish",
      aggregateId: venue.id,
      aggregateType: "Venue",
      payload: {}
    }

    const res: any = await runCommand(command)
    if (res.success) {
      showToastr("Zapisano")
      onSaved()
      // router.push("/venues/" + command.aggregateId)
      // setIsEdited(false)
      // TODO - show toastr success, redirect to /place/:id
    } else {
      showToastr("Problem z zapisem")
      // TODO -show error toastr , res.message
    }
  }

  return (
    <Box>
      {/* <Button variant="contained" color="primary">
        Ukryj{" "}
      </Button> */}
      <MButton
        variant="contained"
        color="primary"
        isSaving={isSaving}
        onClick={doPublish}
      >
        {venue.publishedAt ? "Ukryj ofertę" : "Opublikuj"}
      </MButton>
    </Box>
  )
}
