import { Container } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { useAdminMerchants } from '../../../modules/merchants/api/use_merchants'
import { PlaceForm } from '../../../modules/places/PlaceForm'

export default function AdminNewVenuePage() {
  const router = useHistory()
  const { merchants } = useAdminMerchants()
  return (
    <Container>
      <div>Dodaj Salę (tryb administratora)</div>
      <PlaceForm
        isEdited
        adminMode={true}
        merchants={merchants}
        onSaved={(value) => {
          console.log("On venue saved")
          console.log(value)
          router.push("/admin/venues")
        }}
      />
    </Container>
  )
}
