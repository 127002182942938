import { createGlobalStyle } from 'styled-components'

// ;<link
//   href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
//   rel="stylesheet"
// ></link>
export const AdminGlobalStyle = createGlobalStyle<any>`

* {
    box-sizing: border-box;
  }
  
  body {
    color: ${(props) => (props.whiteColor ? "white" : "black")};

    background-color: #fdfeff;
    /* color: var(--clap-color-black); */
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.42857;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    font-family: Montserrat, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {

    --moje-color-black: #666;
    --moje-border-color: #ccc;
    --moje-color-red:  #D54027;
    --moje-color-pink: #DE416F;
    --moje-color-black-blue: #0F466E;
    --moje-color-blue-ocean: #30799F;
    --moje-color-blue-light: #0099E1;
    --moje-color-blue-sky:  #8BD7F3;

    --moje-color-orange: #F09B0B;
    --moje-color-yellow:  #FECB10;

    --moje-color-green-dark: #73A93C;
    --moje-color-green-olive: #C4CE2E;
    --moje-color-grey: #B2B1B2;

    # --moje-background-gradient-red: linear-gradient(90deg, #D54027 0%, #DE416F 100%);
    --moje-background-gradient-red: linear-gradient(90deg, #dee6e8 0%, #eef4f5 100%);
    
    --moje-color-primary: #0F466E; /* blue */
    --moje-color-accent-1: #DE416F; /* pinkish */
    --moje-color-accent-2:  #F09B0B; /* orange */

    --primaryColor: red;
    --clap-color-red: #ff0000;
    
    --clap-color-blue:#3f8cff;
    --clap-color-blue-light: #E5F1FF;

    --clap-color-green: #7ab775;
    --clap-color-green-light: #EAFAF3;

    --clap-color-pink: #fea0be;
    --clap-color-pink-light: #FFE3DE;

    --clap-color-yellow: #fccf71;
    --clap-color-yellow-light: #FFF2D8;

    --clap-color-violet:#6c5dd3;
    --clap-color-orange: #fd9c7e;

    --clap-spacing-01: 2px;
    --clap-spacing-02: 4px;
    --clap-spacing-03: 8px;
    --clap-spacing-04: 12px;
    --clap-spacing-05: 16px;
    --clap-spacing-06: 24px;
    --clap-spacing-07: 32px;
    --clap-spacing-08: 40px;
    --clap-spacing-09: 48px;

  }
`
