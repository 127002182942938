import Box from "@mui/material/Box"
import { Link } from "react-router-dom"
import Typography, { TypographyProps } from "@mui/material/Typography"
import Button, { ButtonProps } from "@mui/material/Button"
import makeStyles from "@mui/styles/makeStyles"

function RegisterButton(props: ButtonProps<"a">) {
  return (
    <Link to="/signup">
      <Button
        variant="contained"
        href="/signup"
        sx={{
          backgroundColor: "#e6007e",
          borderRadius: 50,
          padding: {
            xs: "10px 20px",
            md: "15px 30px"
          },
          fontWeight: 800,
          "&:hover": {
            backgroundColor: "#e6007e"
          }
        }}
        {...props}
      >
        Zarejestruj firmę za darmo
      </Button>
    </Link>
  )
}

const useStyles = makeStyles((theme: any) => ({
  root: {},
  content: {
    display: "flex",
    flexDirection: "row",
    minHeight: "calc(100vh - 100px)",
    background: "linear-gradient(to right, #DDE5E7,#FAFEFF)",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      minHeight: "50vh",
      height: "initial"
    },
    "& .left": {
      flex: 1,
      padding: "5vw",
      boxSizing: "border-box",
      background: "url('/assets/home/section1_left.png')",
      backgroundSize: "100% 100%",
      marginTop: "30px",
      [theme.breakpoints.down("lg")]: {
        // flexDirection: "column",
      }
    },
    "& .right": {
      flex: 1,
      background: "url('/assets/home/section1_right.png')",
      padding: "5vw",
      backgroundSize: "100% 100%"
    }
  },
  section: {},
  section1: {
    "& .bg1": {
      position: "absolute",
      left: 0,
      height: "100%",
      width: "50%",
      backgroundRepeat: "no-repeat",
      background: "url('/assets/home/section1_left.png')"
    }
  },
  section2: {
    display: "flex",
    minHeight: "calc(100vh - 100px)",
    background: "white",
    boxSizing: "border-box",
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      minHeight: "50vh",
      height: "initial"
    },
    "& .imgTranslate": {
      [theme.breakpoints.up("sm")]: {
        transform: "translateY(200px)"
      }
    },
    "& .left": {
      flex: 1,

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundSize: "100% 100%",
      "& .img": {
        marginLeft: "auto",
        marginRight: "auto",
        width: "25vw",
        [theme.breakpoints.down("lg")]: {
          width: "70vw"
        }
      }
    },
    "& .right": {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    }
  },
  section3: {
    display: "flex",
    flexDirection: "row",
    minHeight: "calc(100vh - 100px)",
    background: "linear-gradient(to right, #DDE5E7,#FAFEFF)",
    boxSizing: "border-box",
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      minHeight: "50vh"
    },
    "& .left": {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .right": {
      flex: 1,
      background: "url('/assets/home/section3_bg.png')",
      //   padding: "5vw",
      backgroundSize: "100% 100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .img": {
        marginLeft: "auto",
        marginRight: "auto",
        width: "20vw",
        [theme.breakpoints.down("lg")]: {
          width: "70vw"
        }
        // transform: "translateY(200px)"
      }
    }
  },
  register: {
    minHeight: "50vh",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "50vw",
      padding: 40,
      [theme.breakpoints.down("lg")]: {
        width: "100vw",
        padding: 10
      }
    },

    "& .overlay": {
      backgroundColor: "#000000BB",
      position: "absolute",
      padding: 20,
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      color: "white !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}))

function H1(props: TypographyProps<"h1">) {
  return (
    <Typography
      fontSize={{
        xs: "1.75rem",
        md: "2rem"
      }}
      fontWeight={800}
      variant="h4"
      component="h1"
      {...props}
    />
  )
}

function P(props: TypographyProps<"p">) {
  return (
    <Typography
      fontSize={{
        xs: "1.25rem",
        md: "body1.fontSize"
      }}
      fontWeight={500}
      color="common.black"
      component="p"
      {...props}
    />
  )
}

function PGrey(props: TypographyProps<"p">) {
  return <P color="#484848" {...props} />
}

export const PublicHome = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {/* <NavBar /> */}
      <section className={classes.section1}>
        {/* <div className="bg1"></div> */}
        <div className={classes.content}>
          <div className="left">
            <H1>Największy portal w Polsce</H1>
            <PGrey>
              Skupiający usługi związane z organizacją przyjęć urodzinowych
            </PGrey>
            <PGrey>
              Dostęp do tysięcy rodziców szukających ciekawych ofert
            </PGrey>
            <Box marginY={4} display="flex">
              <RegisterButton />
            </Box>
          </div>
          <div className="right">
            <Box
              width={{ xs: "70vw", md: "100%" }}
              component="img"
              alt="Phone"
              src="/assets/home/phone1.png"
            />
          </div>
        </div>
      </section>

      <section className={classes.section2}>
        <div className="left">
          <img
            className="img imgTranslate"
            alt="Kids"
            src="/assets/home/section2_left.png"
          />
        </div>
        <div className="right">
          <Box p={4} id="dlaczego">
            <H1>Co możesz zrobić aby zachęcić klientów do rezerwacji</H1>
            <P>Załóż konto i..</P>
            <PGrey>
              <strong style={{ fontSize: "1.4em" }}>1. </strong>Wprowadź pełne i
              aktualne dane kontaktowe.
            </PGrey>
            <P>
              <strong style={{ fontSize: "1.4em" }}>2. </strong>Opisz swoje
              usługi zawierając odpowiedzi na najczęściej zadawane pytania
              (nasze formularze w tym pomogą).
            </P>
            <P>
              <strong style={{ fontSize: "1.4em" }}>3. </strong>Załaduj dobrej
              jakości zdjęcia, ukazujące Twoją ofertę (jeżeli potrzebujesz zdjęć
              napisz do nas)
            </P>
            <PGrey>... gotowe!</PGrey>
          </Box>
        </div>
      </section>

      <section className={classes.section3}>
        <div className="left">
          <Box p={4}>
            <H1>Sprawny kontakt z klientami</H1>
            <P>
              Odpisuj regularnie na zapytania klientów. Dzięki wygodnym
              formularzom, dostaniesz zapytanie z najważniejszymi danymi.
              Wystarczy domówić szczegóły i potwierdzić rezerwacje.
            </P>
          </Box>
        </div>
        <div className="right">
          <img className="img" alt="Kids" src="/assets/home/section3_img.png" />
        </div>
      </section>
      {/* section 4  */}
      <section className={classes.section2}>
        <div
          className="left"
          style={{
            backgroundImage: "url('/assets/home/section4_bg.png')"
          }}
        >
          <img className="img" alt="Kids" src="/assets/home/section4_img.png" />
        </div>
        <div className="right">
          <Box p={4}>
            <H1>Zarządzaj rezerwacjami</H1>
            <P>
              <strong style={{ fontSize: "1.4em" }}>1. </strong>Trzymaj
              zaktualizowany kalendarz
            </P>
            <P>
              <strong style={{ fontSize: "1.4em" }}>2. </strong>Dodawaj promocje
              na wolne terminy
            </P>
            <P>
              <strong style={{ fontSize: "1.4em" }}>3. </strong>Sprzedawaj
              oferty first i last minute
            </P>
          </Box>
        </div>
      </section>

      {/* section 5  */}
      <section className={classes.section3}>
        <div className="left">
          <Box p={4}>
            <H1>Dbamy o klientów</H1>

            <P>
              Nagradzamy opinie klientów i promujemy recenzje. W naszych
              raportach poinformujemy Cię o zmianach na rynku i nowych trendach.
            </P>
          </Box>
        </div>
        <div className="right">
          <img className="img" alt="Kids" src="/assets/home/section5_img.png" />
        </div>
      </section>

      {/* Section 6 */}
      <section className={classes.section2}>
        <div
          className="left"
          style={{
            backgroundImage: "url('/assets/home/section6_bg.png')"
          }}
        >
          <img className="img" alt="Kids" src="/assets/home/section6_img.png" />
        </div>
        <div className="right">
          <Box p={4}>
            <H1>Elastyczność i rozwój</H1>
            <P>
              Cały czas się rozwijamy aby jeszcze lepiej spełniać potrzeby
              zmieniającego się świata. Napisz nam co jeszcze możemy dla Ciebie
              zrobić.
            </P>
          </Box>
        </div>
      </section>

      <section className={classes.register} id="cennik">
        <img src="/assets/home/section7_bg.png" />
        <div className="overlay">
          <H1 color="common.white">Darmowe konto do końca roku!</H1>
          <P color="common.white">
            Oferta obowiązuje dla kont <br />
            założonych do 30 sierpnia 2021
          </P>
          <RegisterButton />
        </div>
      </section>
    </Box>
  )
}
