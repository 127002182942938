import { Box, Container, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useMerchantContext } from "src/contexts/merchant-context"
import { PlacesList } from "src/modules/places/PlacesList"

import { LoadingWidget } from "../../components/LoadingWidget"
import { MButton } from "../../components/MButton"
import { PageHeader } from "../../components/PageHeader"
import { useOwnerVenues } from "../../modules/places/api/use_places"

export default function CampsIndex() {
  const { venues, loading } = useOwnerVenues()
  const router = useHistory()
  const merchantContext = useMerchantContext()

  const camps = venues
    ? venues.filter((v: any) => {
        return v.categoryId === "CAMP"
      })
    : []
  return (
    <Container>
      <PageHeader
        title="Półkolonie"
        toolsComponents={() => {
          return (
            <>
              {merchantContext.merchants &&
                merchantContext.merchants.length > 0 && (
                  <MButton
                    variant="contained"
                    color="primary"
                    onClick={() => router.push("/camps/new")}
                  >
                    Dodaj Półkolonie
                  </MButton>
                )}
            </>
          )
        }}
      ></PageHeader>
      {loading ? (
        <Box
          textAlign="center"
          p={4}
          mt={2}
          display="flex"
          flexDirection="row"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingWidget />
        </Box>
      ) : (
        <>
          {camps && camps.length > 0 ? (
            <PlacesList places={camps} />
          ) : (
            <Box
              textAlign="center"
              p={4}
              mt={2}
              bgcolor="#f0f0f0"
              display="flex"
              flexDirection="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              <Typography fontWeight="medium">
                Nie masz jeszcze żadnych półkoloni
              </Typography>
            </Box>
          )}
        </>
      )}
    </Container>
  )
}
