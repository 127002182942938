import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import uuidv4 from 'uuid'

import { PhotoCropPopup } from './PhotoCropPopup'
import { usePhotoUploader } from './PhotoUploadHoc'

type UploadableMediaWidgetType = {
  onPhotoUploaded: Function
  children: Function
  crop?: boolean
}

export const UploadableMediaWidget = (props: UploadableMediaWidgetType) => {
  const [croppingPhoto, setCroppingPhoto] = useState<any>()
  const afterPhotoUploaded = (photo) => {
    console.log("afterPhotoUploaded", photo)
  }
  const { tmpPhoto, onPhotoSelected, reset } =
    usePhotoUploader(afterPhotoUploaded)

  const generateMediaId = () => {
    return uuidv4()
  }

  const handleDrop = async (event) => {
    console.log("handleDrop")
    console.log(event)
    if (event && event.length > 0) {
      const selectedPhoto = {
        ...event[0],
        file: event[0],
        sourceType: "upload",
        id: generateMediaId()
      }
      // setTmpPhoto(selectedPhoto)
      console.log("selectedPhoto", selectedPhoto)
      // const photos = [...this.state.photos, selectedPhoto]
      // this.setState({ photos })
      // this.props.onPhotosChanged(photos)
      // this.loadPhoto(selectedPhoto)
      // const res = await this.loadPhoto(photo, progressHandler)

      const res = await onPhotoSelected(selectedPhoto, (progress) => {
        console.log("Progress handler", progress)
      })
      console.log("Photo uploaded", res, props)
      if (res) {
        if (props.crop) {
          console.log("Set as crop photo")
          setCroppingPhoto(res)
          return
        }
        if (props.onPhotoUploaded) {
          var result = await props.onPhotoUploaded(res)
          if (result === true) {
            // cleanup
            reset()
          }
        }
        // else {
        //   startSavingPhoto(res)
        // }
      }
    }
  }

  const handleSaveCrop = async (cropData) => {
    console.log("handleSaveCrop", cropData)
    var res = {
      ...croppingPhoto,
      crop: cropData
    }
    console.log("res", res)
    var result = await props.onPhotoUploaded(res)
    if (result === true) {
      // cleanup
      reset()
      setCroppingPhoto(undefined)
    }
  }
  return (
    <>
      <Dropzone
        onDrop={async (acceptedFiles) => handleDrop(acceptedFiles)}

        // onDrop={this.handleDrop}
        // className="DropPhotosArea__dropzone"
      >
        {({ getRootProps, getInputProps }) => {
          const tmpPhotoSrc = tmpPhoto
            ? URL.createObjectURL(tmpPhoto.file)
            : undefined
          return (
            <div {...getRootProps()} style={{ width: "100%" }}>
              <input {...getInputProps()} />
              {/* <div>Inside dropzone</div> */}
              {props.children({ tmpPhoto, tmpPhotoSrc })}
            </div>
          )
        }}
      </Dropzone>
      <PhotoCropPopup
        open={croppingPhoto}
        onClose={(save) => {
          props.onPhotoUploaded(...croppingPhoto)
          setCroppingPhoto(undefined)
        }}
        onSave={handleSaveCrop}
        photo={croppingPhoto}
      />
    </>
  )
}
