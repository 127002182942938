import { Box, Button, Card } from '@mui/material'
import { useAuthContext } from 'src/auth/auth-context'
import { PageHeader } from 'src/components/PageHeader'
import { useMerchantContext } from 'src/contexts/merchant-context'
import { BuyLicenseForm } from 'src/modules/payments/BuyLicenseForm'

const SubscriptionPaymentBlock = (props) => {
  const authContext = useAuthContext()

  const PRZELEWY_REDIRECT_URL = "https://www.mojeurodziny.pl/przelew/accept"
  // const firma =
  var title = "Uzytkownik " + authContext.currentUser?.name
  const price = 49

  return (
    <Box p={2}>
      <Box my={2} fontWeight="700">
        Aktywuj płatność cykliczną
      </Box>
      <Box my={1}>
        Twoje kolejne płatności zostaną zrealizowane automatycznie.
      </Box>

      {/* <form
        method="get"
        action="https:sklep.przelewy24.pl/zakup.php"
        target="_blank"
      >
        <input type="hidden" name="z24_id_sprzedawcy" value={PRZELEWY_ID} />
        <input type="hidden" name="z24_crc" value={PRZELEWY_CRC} />
        <input
          type="hidden"
          name="z24_return_url"
          value={PRZELEWY_REDIRECT_URL}
        />
        <input type="hidden" name="z24_language" value="pl" />
        <table>
          <tr>
            <td align="right">Nazwa produktu:</td>
            <td>
              <input
                type="text"
                name="z24_nazwa"
                value="Abonament mojeurodziny.pl"
              />
            </td>
          </tr>
          <tr>
            <td align="right">Dodatkowy opis:</td>
            <td>
              <textarea name="z24_opis" style={{ width: 250 }}>
                {title}
              </textarea>
            </td>
          </tr>
          <tr>
            <td align="right">Do zapłaty:</td>
            <td>
              <input type="text" name="z24_kwota" value={price} />
            </td>
          </tr>
        </table>
        <input type="submit" value="Zapłać z przelewy24.pl" />
      </form> */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          // getPaymentLink({
          //   amount: 5500,
          //   description: "Test 55 pln"
          // })
        }}
      >
        Przejdź do płatności
      </Button>
    </Box>
  )
}
const ActivateSubscription = (props) => {
  console.log("Render ActivateSubscription ")

  return (
    <Card elevation={1}>
      <Box p={2}>
        <Button variant="contained" color="primary" onClick={() => {}}>
          Aktywuj
        </Button>
      </Box>

      <SubscriptionPaymentBlock />
    </Card>
  )
}

export const ComapnySectionSubscription = (props) => {
  const merchantContext = useMerchantContext()
  return (
    <Box>
      <PageHeader
        title="Abonament"
        subtitle="Wybierz pakiet i aktywuj abonament"
        toolsComponents={() => {
          return (
            <>
              {/* {!editedMerchant &&
                  !isCreating &&
                  merchants &&
                  merchants.length > 0 && (
                    <MButton primary onClick={() => setIsCreating(true)}>
                      Dodaj Firmę
                    </MButton>
                  )} */}
            </>
          )
        }}
      ></PageHeader>

      <Box my={2}>
        <BuyLicenseForm />
      </Box>
      <Box>
        {merchantContext.merchant?.license ? (
          <Box mb={2}>
            licencja: {merchantContext.merchant?.license.package_code} do{" "}
            {merchantContext.merchant?.license?.valid_till}
          </Box>
        ) : (
          <Box>Nie masz jeszcze licencji </Box>
        )}
      </Box>
    </Box>
  )
}
