import CheckIcon from "@mui/icons-material/Check"
import { InputAdornment, OutlinedInput } from "@mui/material"
import { forwardRef } from "react"

export interface MojeTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string
  placeholder?: any
  onBlur?: (...args: any[]) => any
  small?: boolean
  inputStyle?: any
  type?: any
  iconName?: any
  focus?: any
  disabled?: boolean
  onChange?: any
  onFocus?: any
  value?: any
  required?: any
  id?: any
  readOnly?: any
  maxLength?: any
  ref?: any
}

export const MojeTextInput: React.FC<any> = forwardRef((props, ref) => {
  const { onChange } = props

  const handleChange = (event: any) => {
    event.persist()
    if (onChange) {
      onChange(event)
    }
  }
  const extraProps: any = {}
  if (props.valid) {
    extraProps.endAdornment = (
      <InputAdornment position="end">
        <CheckIcon color="success" />
      </InputAdornment>
    )
  }
  console.log("MojeTextInput", props)
  // console.log("Moje Text input", props, extraProps)
  return (
    <OutlinedInput
      ref={ref}
      // ref={ref}
      onChange={props.onChange ?? handleChange}
      fullWidth
      {...props}
      {...extraProps}
    />
  )
})
