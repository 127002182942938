import { Box, Container } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import axios from "axios"
import { Formik } from "formik"
import React from "react"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"

import { MojeCard } from "../../components/card/MojeCard"
import { MButton } from "../../components/MButton"
import { MojeFormField } from "../../components/MojeFormField"
import { MojeTextArea } from "../../components/MojeTextArea"
import { MojeTextInput } from "../../components/MojeTextInput"
import { ToastrType, useToastr } from "../../components/MojeToastr"
import { PublishPlaceWidget } from "../../modules/places/components/PublishPlaceWidget"

const useStyles = makeStyles((theme: any) => ({
  root: {
    "& h1": {
      fontSize: "1.8vw",
      fontWeight: 800,
      color: "#000"
    }
  }
}))

const validatoinSchema: any = Yup.object({
  message: Yup.string().required("Wprowadź wiadomość"),
  email: Yup.string().required("Podaj Twój email"),
  phone: Yup.string()
})

export const ContactPage = (props) => {
  const classes = useStyles()

  const showToastr = useToastr()
  const router = useHistory()

  const doSubmit = async (values: any, { setSubmitting }) => {
    console.log(values, "doSubmit", setSubmitting)
    let meUrl = process.env.REACT_APP_API_URL + `/public/requestContact`
    try {
      setSubmitting(true)
      const data = {
        ...values
      }
      console.log("data", data)
      var result = await axios({
        method: "POST",
        url: meUrl,
        data
      })

      showToastr("Zapytanie wysłane, skontaktujemy się wkrótce", {
        type: ToastrType.INFO,
        onYes: () => {
          router.push("/")
        }
      })
    } catch (err) {
      setSubmitting(false)
      console.error(err)
      showToastr("Problem z wysłaniem wiadomości", {
        type: ToastrType.ERROR
      })
    }
    setSubmitting(false)
  }

  const initialValues = {}
  return (
    <Container>
      <Box className={classes.root}>
        <h1>Wyślij nam wiadomość - odpiszemy bardzo szybko!</h1>
        {/* <h4>Jakiś tekxt</h4> */}

        <MojeCard
          title="Zapytanie"
          toolboxComponent={
            <>
              {props.venue ? (
                <PublishPlaceWidget
                  onSaved={props.refetch}
                  venue={props.venue}
                />
              ) : (
                <span />
              )}
            </>
          }
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, submitProps) => {
              doSubmit(values, submitProps)
            }}
            enableReinitialize
            validationSchema={validatoinSchema}
          >
            {(formikProps: any) => {
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <MojeFormField
                    label="Twój e-mail *"
                    name="email"
                    formikProps={formikProps}
                    isEdited={true}
                    component={MojeTextInput}
                    roComponent={<div>{formikProps.values.merchant?.name}</div>}
                  ></MojeFormField>
                  <MojeFormField
                    label="Wiadomosc"
                    name="message"
                    formikProps={formikProps}
                    isEdited={true}
                    roComponent={<div>{formikProps.values.merchant?.name}</div>}
                  >
                    <MojeTextArea
                      value={formikProps.values.message}
                      onChange={(event) =>
                        formikProps.setFieldValue(
                          "message",
                          event.currentTarget.value
                        )
                      }
                      rows={5}
                    />
                  </MojeFormField>

                  <Box display="flex" justifyContent="flex-end">
                    <MButton
                      label="Wyślij Zapytanie"
                      type="submit"
                      submit
                      variant="contained"
                      color="primary"
                      isSaving={formikProps.isSubmitting}
                    />
                  </Box>
                </form>
              )
            }}
          </Formik>
        </MojeCard>
      </Box>
    </Container>
  )
}
export default ContactPage
