import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { FieldArray, Formik } from "formik"
import { useState } from "react"

import { MojeCard } from "../../../../components/card/MojeCard"
import { MButton } from "../../../../components/MButton"
import { MojeFormField } from "../../../../components/MojeFormField"
import { MojeTextArea } from "../../../../components/MojeTextArea"
import { MojeTextInput } from "../../../../components/MojeTextInput"
import { useToastr } from "../../../../components/MojeToastr"
import { usePlaceApi } from "../../api/use_place_api"

export const PlaceSectionFAQ = ({ venue, refetch }) => {
  const { runCommand } = usePlaceApi()
  const showToastr = useToastr()

  return (
    <Formik
      initialValues={{ faq: venue.faq ?? [] }}
      onSubmit={async (values) => {
        const venueData: any = { ...venue, faq: values.faq }
        const command = {
          type: "Venue.Update",
          aggregateId: venue.id,
          aggregateType: "Venue",
          payload: venueData
        }
        const res: any = await runCommand(command)
        if (res.success) {
          showToastr("Zapisano")
          refetch() // Sync submitted data
        } else {
          showToastr("Problem z zapisem")
          // TODO -show error toastr , res.message
        }
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FieldArray
            name="faq"
            render={(faqArrayHelpers) => (
              <MojeCard
                title="FAQ"
                toolboxComponent={
                  <span>
                    <MButton
                      primary
                      variant="contained"
                      label="Add faq"
                      onClick={() => faqArrayHelpers.push({ q: "", a: "" })}
                    />
                  </span>
                }
              >
                {formikProps.values.faq.map((faq, index) => (
                  <Box display="flex" marginBottom={4} key={index}>
                    <Box marginRight={2} marginTop={2}>
                      <Typography variant="h5" component="div">
                        {index + 1}
                      </Typography>
                    </Box>
                    <Box flexGrow={1}>
                      <Box
                        display="flex"
                        justifyContent="between"
                        alignItems="flex-end"
                      >
                        <Box flexGrow={1} marginRight={2}>
                          <MojeFormField
                            label="Q"
                            name={`faq[${index}].q`}
                            formikProps={formikProps}
                            isEdited
                            component={MojeTextInput}
                          />
                        </Box>
                        <MButton
                          secondary
                          variant="contained"
                          label="Delete"
                          onClick={() => faqArrayHelpers.remove(index)}
                        />
                      </Box>
                      <MojeFormField
                        label="A"
                        name={`faq[${index}].a`}
                        formikProps={formikProps}
                        isEdited
                      >
                        <MojeTextArea rows={2} />
                      </MojeFormField>
                    </Box>
                  </Box>
                ))}
                <Box display="flex" justifyContent="flex-end" my={1}>
                  <Box mx={1}>
                    <MButton
                      secondary
                      variant="outlined"
                      reset
                      label="Anuluj"
                      grouped
                      onClick={formikProps.handleReset}
                      disabled={!formikProps.dirty}
                    />
                  </Box>
                  <Box mx={1}>
                    <MButton
                      primary
                      variant="contained"
                      submit
                      label="Zapisz"
                      grouped
                      isSaving={formikProps.isSubmitting}
                      disabled={!formikProps.dirty}
                    />
                  </Box>
                </Box>
              </MojeCard>
            )}
          />
        </form>
      )}
    </Formik>
  )
}
