import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedDate, FormattedTime, useIntl } from 'react-intl'
import styled from 'styled-components'

const MessageItemStyled = styled.div`
  margin-bottom: 8px;
  .author {
    font-weight: 700;
    font-size: 12px;
    margin-right: 16px;
    display: flex;
    align-items: center;
  }
  .time {
    font-weight: 300;
    font-size: 10px;
    margin-left: 12px;
    display: flex;
  }
  .content {
    font-size: 12px;
  }
`

export const MessageItem = ({ message }) => {
  const intl = useIntl()
  var readAtTitle = message.readAt
    ? "Received " +
      intl.formatDate(message.readAt) +
      " " +
      intl.formatTime(message.readAt)
    : ""

  return (
    <MessageItemStyled>
      <div className="author">
        <div>
          {message.direction == "FROM_USER" &&
            (message.user ? message.user.name : "Missing user")}
          {message.direction == "TO_USER" &&
            (message.merchant ? message.merchant.name : "Missing merchant")}
        </div>
        <div className="time" title={readAtTitle} style={{ cursor: "pointer" }}>
          <FormattedDate value={message.sentAt} />{" "}
          <FormattedTime value={message.sentAt} />
          <div className="ml-2" style={{ marginLeft: 2 }}>
            <FontAwesomeIcon
              icon={faCheck}
              color={message.readAt ? "green" : "grey"}
              style={{ width: 12, height: 12 }}
            />
          </div>
        </div>
      </div>
      <div className="content">{message.contentText}</div>
    </MessageItemStyled>
  )
}
