import { Box, Button, Card, Dialog } from '@mui/material'
import { useState } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Link } from 'react-router-dom'

import { BnbLabeledRowField } from '../../components/BnbLabeledRowField'
import { AcceptRejectPopup, ACTION_ACCEPT, ACTION_REJECT } from './AcceptRejectPopup'
import { ReservationConditions } from './ReservationConditions'

export const ReservationDetails = ({
  reservation,
  refetch
}: {
  reservation: any
  refetch?: Function
}) => {
  const [action, setAction] = useState<any>(undefined)
  const [isPopup, setIsPopup] = useState(false)
  return (
    <Card elevation={1}>
      <Box bgcolor="white" p={1}>
        <div>
          <BnbLabeledRowField label="Rezerwacja" asPageTitle>
            {/* <MButton label="Drukuj" variant="contained" color="primary" /> */}
          </BnbLabeledRowField>

          {/* <BnbLabeledRowField label="Zapytanie" labelComponent={() => {}}>
        <MButton label="Drukuj " />
      </BnbLabeledRowField> */}

          <BnbLabeledRowField label="Rezerwacja numer">
            {reservation.id}
          </BnbLabeledRowField>

          <BnbLabeledRowField label="Klient">
            {reservation.user?.name}
          </BnbLabeledRowField>

          <BnbLabeledRowField
            labelComponent={
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, padding: 6 }}>
                  <Link to={`messages/user/${reservation.user?.id}`}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {}}
                    >
                      Wyślij Wiadomość
                    </Button>
                  </Link>
                </div>
                <div style={{ flex: 1, padding: 6 }}>
                  <Button variant="outlined" color="secondary">
                    Zadzwoń
                  </Button>
                </div>
              </div>
            }
          />

          <BnbLabeledRowField label="Termin">
            {reservation.reservationDate} {reservation.reservationTime}
          </BnbLabeledRowField>

          <BnbLabeledRowField label="Otrzymano">
            <FormattedDate value={reservation.createdAt} />{" "}
            <FormattedTime value={reservation.createdAt} />
          </BnbLabeledRowField>
          <BnbLabeledRowField label="Status">
            {reservation.status == "APPROVED" && (
              <>Rezerwacja zaakceptowana {reservation.acceptedAt}</>
            )}
            {reservation.status == "REJECTED" && (
              <>Rezerwacja odrzucona {reservation.rejectedAt}</>
            )}
            {reservation.status == "WAITING_FOR_CUSTOMER" && (
              <>Czeka na odpowiedź klienta</>
            )}
            {reservation.status == "WAITING_FOR_APPROVAL" && (
              <>Oczekuje na akceptacje</>
            )}
          </BnbLabeledRowField>

          <BnbLabeledRowField label="Sala">
            {reservation.venue?.name}
          </BnbLabeledRowField>
          <BnbLabeledRowField label="Scenariusz">
            {reservation.finalConditions?.product?.name}
          </BnbLabeledRowField>
          <BnbLabeledRowField label="Warunki rezerwacji" fullWidth>
            <Box>
              {reservation.finalConditions && (
                <ReservationConditions
                  reservation={reservation.finalConditions}
                />
              )}
            </Box>
          </BnbLabeledRowField>

          <BnbLabeledRowField
            labelComponent={
              <>
                <div style={{ display: "flex" }}>
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e: any) => {
                        setAction(ACTION_ACCEPT)
                        setIsPopup(true)
                      }}
                    >
                      Zaakceptuj
                    </Button>
                  </Box>{" "}
                  <Box ml={1} flex={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={(e: any) => {
                        setAction(ACTION_REJECT)
                        setIsPopup(true)
                      }}
                    >
                      Odrzuć rezerwację
                    </Button>
                  </Box>
                </div>
              </>
            }
          />
          {/* <BnbLabeledRowField
        labelComponent={
          <div style={{ height: 300 }}>
            {reservation.merchant && reservation.id && (
              <ChatWidget
                merchant={reservation.merchant}
                user={reservation.user}
              />
            )}
          </div>
        }
      ></BnbLabeledRowField> */}

          {isPopup && (
            <Dialog
              open={isPopup}
              fullWidth
              maxWidth="sm"
              onClose={(e: any) => {
                setIsPopup(false)
              }}
            >
              <AcceptRejectPopup
                reservation={reservation}
                action={action}
                onSaved={() => {
                  refetch()
                }}
                onClose={(e: any) => {
                  setIsPopup(false)
                }}
              />
            </Dialog>
          )}
          {/* <div>
        <MButton
          label="Zapisz"
          onClick={handleSave}
          primary
          isSaving={isSaving}
        />
      </div> */}
        </div>
      </Box>
    </Card>
  )
}
