import { PageContainer } from 'src/components/containers/PageConatiner'

import { ReservationsList } from '../../modules/reservations/ReservationsList'

export default function ReservationsIndex() {
  return (
    <PageContainer>
      <ReservationsList prefix={"/reservations"} />
    </PageContainer>
  )
}
