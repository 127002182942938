import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TextField } from "@mui/material"
import {
  bool,
  element,
  func,
  number,
  oneOfType,
  shape,
  string
} from "prop-types"

export const MojeTextArea = (props: any) => {
  const {
    meta: { valid, touched, error },
    modified,
    required,
    style,
    ...otherProps
  } = props
  return (
    <>
      <TextField
        multiline
        variant="outlined"
        required={required}
        fullWidth
        {...otherProps}
      />
      {modified && (
        <span className="MpsTextInput-modifiedMarker">
          <FontAwesomeIcon icon={faPencilAlt} />
        </span>
      )}

      {required ? <div className="MpsTextInput-required">*</div> : null}
      <span className="MpsTextInput-error">{error}</span>
    </>
  )
}

MojeTextArea.propTypes = {
  name: string,
  value: oneOfType([string, number]),
  meta: shape({
    valid: bool,
    touched: bool,
    error: string
  }),
  type: string,
  placeholder: oneOfType([element.isRequired, string.isRequired]),
  disabled: bool,
  onBlur: func,
  onFocus: func,
  onChange: func,
  readOnly: bool,
  required: bool,
  id: string,
  modified: bool,
  rows: number
}

const noop = () => null

MojeTextArea.defaultProps = {
  type: "text",
  disabled: false,
  meta: {
    valid: true,
    touched: false,
    error: ""
  },
  onBlur: noop,
  onChange: noop,
  onFocus: noop,
  readOnly: false,
  required: false,
  id: undefined,
  modified: false,
  rows: undefined
}
