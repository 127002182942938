type MojeCardProps = {
  title?: string
  subtitle?: string
  toolboxComponent?: any
  children?: any
  style?: any
  my?: any
}

export const MojeCard = (props: MojeCardProps) => {
  return (
    // <Card {...props} className={classes.root}>
    <div className="moje-card">
      <div className="break-inside-avoid-column">
        {props.title && (
          <MojeCardTitle
            title={props.title}
            toolboxComponent={props.toolboxComponent}
          />
        )}
        {props.subtitle && (
          <div className="mx-2">
            <MojeCardSubtitle subtitle={props.subtitle} />
          </div>
        )}
        <div>{props.children}</div>
      </div>
      {/* <CardContent>{props.children}</CardContent> */}
    </div>
    // </Card>
  )
}
export const MojeCardTitle = ({
  title,
  toolboxComponent
}: {
  title: string
  toolboxComponent?: any
}) => (
  <div className="flex flex-row mb-8">
    <div className="moje-card-title flex-1"> {title}</div>
    {toolboxComponent && <h2 className="ml-2">{toolboxComponent}</h2>}
    {/* <CardHeader title={title} action={toolboxComponent} /> */}
  </div>
)

export const MojeCardSubtitle = ({ subtitle }: any) => (
  <div className="font-md my-4 color-[#4b5154]">{subtitle}</div>
)
