import { PageContainer } from "src/components/containers/PageConatiner"

export const FinancesIndex = () => {
  return (
    <PageContainer title="Panel rozliczeń">
      <div className="mt-4">
        Rozliczenia będą dostępne po ukończeniu pierwszej rezerwacji.
      </div>
    </PageContainer>
  )
}
