import { Paper } from "@mui/material"
import { MojeCard } from "src/components/card/MojeCard"
import { usePageSettings } from "src/modules/common/usePageSettings"

export const HomeWelcomeWidget = (props) => {
  const { data, loading, refetch } = usePageSettings()

  const welcomeMessage = data
    ? data.find((set) => set.code === "adminDashboardWelcomeMessage")
    : undefined

  if (!welcomeMessage) {
    return <span />
  }
  var msg = welcomeMessage.value
    .split("\n")
    .map((part) => `<p>${part}</p>`)
    .join("")
  return (
    <MojeCard>
      <div style={{ padding: 8, textAlign: "center", fontWeight: 800 }}>
        <p>Witaj na stronie MojeUrodziny.pl</p>
        <div dangerouslySetInnerHTML={{ __html: msg }} />
      </div>
    </MojeCard>
  )
}
