import { LoadingWidget } from '../../../components/LoadingWidget'
import { ReservationDetails } from '../../reservations/ReservationDetails'
import { useOwnerReservations } from '../../reservations/useReservations'

export const MessagingRightWidget = ({ userId }: { userId: string }) => {
  const { reservations, loading } = useOwnerReservations({
    userId,
    pending: true
  })

  const recentReservation =
    reservations && reservations.length > 0 ? reservations[0] : undefined
  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {loading && <LoadingWidget />}
      {recentReservation && (
        <ReservationDetails reservation={recentReservation} />
      )}
    </div>
  )
}
