import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { useState } from "react"

import { PageHeader } from "../../components/PageHeader"
import { Table, Td, Th } from "../../components/Table"
import { EditCategoryForm } from "./CategoryForm"

// import { EditPageForm } from './EditPageForm'

export const CategoriesTable = ({
  items,
  refetch
}: {
  items: any
  refetch: any
}) => {
  const [edited, setEditedItem] = useState<any>()

  return (
    <div>
      <PageHeader
        title="Kategorie"
        toolsComponents={() => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={(e: any) => {
                  setEditedItem({})
                }}
              >
                {" "}
                Dodaj{" "}
              </Button>
            </>
          )
        }}
      ></PageHeader>

      <Table>
        <thead>
          <tr>
            <Th>Kod</Th>
            <Th>Nadrzędna</Th>
            <Th>Nazwa</Th>
            <Th>Typ</Th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item: any) => {
              return (
                <tr onClick={(a: any) => setEditedItem(item)}>
                  <Td>{item.code}</Td>
                  <Td>{item.parentCategory?.name}</Td>
                  <Td>{item.name}</Td>
                  <Td>
                    {item.type === "PLACE" && "Miejsce"}
                    {item.type === "SERVICE" && "Usługi"}
                    {item.type === "PROMO" && "Promocyjna"}
                  </Td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {edited && (
        <Dialog
          onClose={() => {
            setEditedItem(undefined)
          }}
          aria-labelledby="simple-dialog-title"
          open={edited}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="simple-dialog-title">Edytuj Kategorię</DialogTitle>

          <DialogContent style={{ width: "fit-content", minWidth: "500px" }}>
            <EditCategoryForm
              item={edited}
              onCancel={() => {
                setEditedItem(undefined)
              }}
              onSaved={() => {
                refetch()
                setEditedItem(undefined)
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
