import { Box, Typography } from "@mui/material"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import { useHistory } from "react-router-dom"
import React, { useState } from "react"
import * as Yup from "yup"

import { AuthContextType, useAuthContext } from "../../auth/auth-context"
import { MButton } from "../../components/MButton"
import { MojeTextInput } from "../../components/MojeTextInput"
import { useToastr } from "../../components/MojeToastr"
import { ConfirmationForm } from "./ConfirmationForm"

const STEP_REGISTER = "REGISTER"
const STEP_CONFIRM = "CONFIRM"

const formSchema = Yup.object({
  email: Yup.string().required("Wprowadź e-mail"),
  password: Yup.string()
    .required("Wprowadź nowe hasło")
    .min(8, "Hasło musi mieć minimum 8 znaków")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Hasło powinno mieć małe i duże litery oraz cyfry"
    )
})

export const LoginFormRegister = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentStep, setCurrentStep] = useState(STEP_REGISTER)
  const router = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const [registrationMode, setRegistrationMode] = useState("EMAIL")
  const showToastr = useToastr()
  const [confirmationCodeMedium, setConfirmationCodeMedium] = useState<string>()
  const [signupParams, setSignUpParams] = useState<any>(undefined)
  const authContext: AuthContextType = useAuthContext()
  const isValidPhone = () => {}

  const handleConfirm = async (values, { setSubmitting }) => {
    console.log("handleConfirm", values)
    try {
      const res = await Auth.confirmSignUp(values.username, values.confirmation)
      console.log("Confirmation result", res)
      if (res === "SUCCESS") {
        showToastr("Account confirmed!")
        //   onConfirmed()
      }
    } catch (error) {
      console.log("Confirmation error" + error)
      // setConfirmationResult(error)
    }
  }

  const isPasswordValid = async (values) => {}

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("handleSubmit", values)

    if (currentStep === STEP_CONFIRM) {
      return handleConfirm(values, { setSubmitting })
    }
    const _signUpParams = {
      username: values.email.trim(),
      password: values.password.trim(),
      attributes: {}
    }

    try {
      setSubmitting(true)
      setErrorMessage(undefined)
      const res = await Auth.signUp(_signUpParams)
      console.log("signput result", res)

      if (res.userSub && res.userConfirmed === false) {
        // we got user - redirect to confirmation page
        setSignUpParams(_signUpParams)
        setCurrentStep(STEP_CONFIRM)
        console.log("Login result")
        // setConfirmationCodeMedium(res.codeDeliveryDetails.DeliveryMedium)
      } else {
        // Already confirmed
        console.log("Log in using provided credentials, ", _signUpParams)
        const singInResult = await Auth.signIn(
          _signUpParams.username,
          _signUpParams.password
        )
        authContext.refetchCurrentUser()

        const confirmedUser = await Auth.currentAuthenticatedUser()
        console.log("confirmedUser", confirmedUser)
        showToastr("Konto utworzone!", {
          message: "Witamy w Moje Urodziny",
          onYes: () => {
            console.log("On yes")
            router.push("/profile?newuser")
          }
        })
      }
    } catch (err) {
      console.log("Error register", err)
      setSubmitting(false)
      if (err.code == "InvalidParameterException") {
        setErrorMessage(
          "Błędne hasło - nowe hasło musi mieć minimum 8 znaków w tym "
        )
      } else if (err.code === "UsernameExistsException") {
        setErrorMessage("To konto już istnieje")
      } else {
        setErrorMessage(err.message)
      }
    }
  }

  const loginData: any = {}

  return (
    <div>
      {/* Registration form: {registrationMode}
        ste: {currentStep} */}
      <div
        onChange={(event: any) => setRegistrationMode(event.target.value)}
        style={{ display: "flex", margin: "16px 0" }}
      ></div>
      {currentStep === STEP_CONFIRM && (
        <ConfirmationForm signupParams={signupParams} />
      )}
      {currentStep === STEP_REGISTER && (
        <Formik
          initialValues={loginData}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm
          }) => (
            <form
              onSubmit={handleSubmit}
              className="Form"
              onKeyDown={(e) => {
                console.log("onKeyDown")
                if (e.key === "Enter") {
                  submitForm()
                }
              }}
            >
              <Box style={{ margin: "10px 0" }}>
                <Typography variant="body2" fontWeight="medium">
                  Założenie konta nic nie kosztuje
                </Typography>
              </Box>
              <Box style={{ margin: "10px 0" }}>
                <MojeTextInput
                  type="text"
                  className="form-control mps-metro-input"
                  id="email"
                  name="email"
                  placeholder={"e-mail"}
                  required
                  onChange={handleChange}
                  value={values.email}
                />
              </Box>
              <Box style={{ margin: "10px 0" }}>
                {currentStep === STEP_REGISTER && (
                  <MojeTextInput
                    type="password"
                    placeholder="Hasło"
                    name="password"
                    className="form-control mps-metro-input"
                    id="password"
                    required
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                  />
                )}
              </Box>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 20,
                  fontSize: 10,
                  textAlign: "left",
                  color: "var(--moje-color-red)"
                }}
              >
                {errors && (
                  <span className="alert alert-danger help-block">
                    {errors.password}
                  </span>
                )}
                {/* errors : {JSON.stringify(errors)}
                touched: {JSON.stringify(touched)} */}
                <Box my={2}>
                  <MButton
                    onClick={async () => {
                      submitForm()
                    }}
                    style={{
                      width: "100%",
                      borderRadius: 4,
                      backgroundColor: "#ff8204",
                      color: "white"
                    }}
                    label={"Utwórz nowe konto"}
                    primary
                    disabled={!values.email || !values.password}
                    isSaving={isSubmitting}
                  />
                </Box>
                {/* {this.renderLoginWithFacebook()} */}
                {/* <div className="renderLoginWithFacebook" */}
                {/* <div className="col-md-6">
                        <MpsButton
                          type="button"
                          onClick={async () => {
                            await setFieldValue('mode', 'signup', false)
                            submitForm()
                          }}
                          className="btn btn-tool btn-primary"
                          tabIndex="50"
                          style={{
                            width: '100%',
                            borderRadius: 4,
                            backgroundColor: '#ff8204',
                            color: 'white'
                          }}
                          label="Sign Up"
                        />
                      </div> */}
              </div>
              {errorMessage && (
                <div
                  style={{
                    textAlign: "center",
                    padding: 10,
                    height: 30,
                    color: "red"
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}
