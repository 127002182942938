import { Route, Switch } from 'react-router-dom'

import { MerchantContextType, useMerchantContext } from './contexts/merchant-context'
import { FinancesIndex } from './modules/finances/FinancesIndex'
import { HomeDashboard } from './modules/home/HomeDashboard'
import { NavBar } from './modules/menu/NavBar'
import { OnboardingIndex } from './modules/onboarding/OnboardingIndex'
import { ReservationPage } from './modules/reservations/ReservationPage'
import { AdminIndex } from './pages/admin/AdminIndex'
import VenuePageIdx from './pages/admin/venues/[id]'
import CampsIndex from './pages/camps'
import CompanyIndex from './pages/company'
import ContactIndex from './pages/contact'
import NewMerchantPage from './pages/merchants/new'
import MessagesIndex from './pages/messages'
import ProfilePage from './pages/profile'
import { SignInScreen } from './pages/profile/SignInScreen'
import { SignUpScreen } from './pages/profile/SignUpScreen'
import ReservationsIndex from './pages/reservations'
import VenuesIndex from './pages/venues'
import NewVenuePage from './pages/venues/new'

export const Routes = () => {
  const merchantContext: MerchantContextType = useMerchantContext()
  console.log("Routes", merchantContext)

  return (
    <>
      <NavBar />
      <Switch>
        <Route path="/profile" component={ProfilePage} />
        <Route path="/admin" component={AdminIndex} />
        <Route path="/signin" component={SignInScreen} />
        <Route path="/signup" component={SignUpScreen} />
        <Route>
          {merchantContext.onboardingProgress.onboardingCompleted ? (
            <Switch>
              <Route path="/finances" component={FinancesIndex} />
              <Route path="/venues/new" component={NewVenuePage} />
              <Route path="/venues/:id" component={VenuePageIdx} />
              <Route path="/venues" component={VenuesIndex} />
              <Route path="/camps" component={CampsIndex} />
              <Route path="/firma" component={CompanyIndex} />
              <Route path="/contact" component={ContactIndex} />
              <Route path="/merchants/new" component={NewMerchantPage} />
              <Route path="/messages" component={MessagesIndex} />
              <Route path="/reservations/:id" component={ReservationPage} />
              <Route path="/reservations" component={ReservationsIndex} />

              <Route>
                <HomeDashboard />
              </Route>
            </Switch>
          ) : (
            <OnboardingIndex />
          )}
        </Route>
      </Switch>
    </>
  )
}
