import axios from "axios"
import { makeUseAxios } from "axios-hooks"
import { useMerchantContext } from "src/contexts/merchant-context"

const useAxios = makeUseAxios({ axios })
export const useAdminVenues = (filter: any = {}) => {
  let url = `${process.env.REACT_APP_API_URL}/admin/venues`
  if (filter?.merchantId) {
    url += "?merchantId=" + filter.merchantId
  }
  if (filter?.showDeleted) {
    url += "?showDeleted=" + true
  }
  const [{ data }, refetch] = useAxios(url, { useCache: false })

  const venues = data

  return { venues, loading: false }
}

export const useOwnerVenues = () => {
  const merchantContext = useMerchantContext()
  const url = `${process.env.REACT_APP_API_URL}/venues?merchantId=${merchantContext.merchant?.id}`

  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { venues: data ? data.items : [], loading, refetch }
}
