export class DocumentUtils {
  static documentTypeLabel(type) {
    if (type === "LICENSE") {
      return "Płatność abonamentu"
    }
    return type
  }

  static documentStatusLabel(status: string) {
    if (status === "PAID") {
      return "Opłacony"
    }
    if (status === "DRAFT") {
      return "Zamówienie"
    }
    return status
  }
}
