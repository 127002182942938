import { CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import axios from 'axios'
import { useEffect, useState } from 'react'

export type MerchantItem = {
  name: string
  id: string | null
}

export function AdminMerchantsDropdown({ value, ...props }: any) {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([])
  const [_value, setValue] = useState(null)

  console.log("merchantsOptions", options)

  useEffect(() => {
    var active = true
    console.log("Search: ", inputValue, value)
    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    const searchMerchantsUrl = `${process.env.REACT_APP_API_URL}/admin/merchants?search=${inputValue}`
    setLoading(true)
    axios(searchMerchantsUrl).then((res) => {
      setLoading(false)
      console.log("Search res", res, "active", active)
      if (res.status === 200 && res.data) {
        if (active) {
          console.log("Set options", res.data)
          setOptions(res.data.items)
        }
      }
    })
    return () => {
      console.log("Set active false")
      active = false
    }
  }, [inputValue, value])
  return (
    <Autocomplete
      {...props}
      fullWidth
      filterOptions={(x) => x}
      id="Merchant options"
      options={options}
      loading={loading}
      autoComplete
      value={_value}
      getOptionLabel={(option: MerchantItem) => option.name}
      onChange={(event, newValue) => {
        console.log("Admin merchants on change", event, newValue)
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        props.onChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        console.log("onInputChange", newInputValue)
        setInputValue(newInputValue)
      }}
      p={0}
      sx={{
        p: 0
      }}
      // value={value ?? merchantsOptions[0]}
      renderInput={(params) => (
        <TextField
          inputProps={{ "aria-labelledby": "AdminMerchantsDropdown-label" }}
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}
