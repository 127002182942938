import { Box } from '@mui/material'
import { format } from 'date-fns'
import { Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

import { MButton } from '../../../../components/MButton'
import { MojeFormField } from '../../../../components/MojeFormField'
import { MojeTextInput } from '../../../../components/MojeTextInput'
import { useToastr } from '../../../../components/MojeToastr'
import { IdUtils } from '../../../../utils/id-utils'
import { useSpecialDatesApi } from './useSpecialDatesApi'

const specialConditionTypes = [
  {
    value: "UNAVAILABLE",
    name: "Niedostępne"
  },
  {
    value: "SPECIAL_PRICE",
    name: "Specjalna Cena"
  }
]

const validationSchema: any = Yup.object({
  date: Yup.string().required("Data jest wymagana"),
  // isAllDay: Yup.boolean(),
  remarks: Yup.string()
})

export const SpecialConditionForm = (props) => {
  // const [isRunningCommand] =
  const { runCommand, isSaving } = useSpecialDatesApi()
  const [newId, setNextId] = useState(IdUtils.generateId("SpecialDate"))

  const showToastr = useToastr()

  const doSubmit = async (values: any, submitProps) => {
    console.log("doSubmit", values, submitProps, props)

    if (!values.date) {
      return
    }
    const specialDate = {
      ...values,
      date: values.date ? format(values.date, "yyyy-MM-dd") : undefined,
      type: "UNAVAILABLE",
      isAllDay: true
    }

    specialDate.merchant = {
      id: props.venue.merchant.id,
      name: props.venue.merchant.name
    }
    specialDate.merchantId = props.venue.merchant.id
    specialDate.venue = { id: props.venue.id, name: props.venue.name }
    specialDate.venueId = props.venue.id

    const command = {
      aggregateId: newId,
      type: "SpecialDate.Create",
      aggregateType: "SpecialDate",
      payload: {
        ...specialDate
      }
    }

    console.log("command", command)
    const res: any = await runCommand(command)
    if (res.success) {
      showToastr("Zapisano", {})
      props.onSaved()
    } else {
      showToastr("Problem z zapisem")
    }
  }
  const initialValues: any = {
    isAllDay: true
  }
  if (props.date) {
    initialValues.date = props.date // format(props.date, "yyyy-MM-dd")
  }
  console.log("Special conditions for", props, initialValues)
  var showDate = props.date === undefined
  return (
    <div>
      {/* // <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}> */}
      <div style={{ border: "1px solid #eee;", padding: 16 }}>
        <div>Oznacz dzień jako niedostępny</div>

        <Formik
          initialValues={initialValues}
          onSubmit={doSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {(formikProps: any) => (
            <form onSubmit={formikProps.handleSubmit}>
              {showDate && (
                <MojeFormField
                  label="Data (YYYY-MM-DD)"
                  name="date"
                  value={formikProps.values.date}
                  formikProps={formikProps}
                  isEdited
                >
                  {/* <KeyboardDatePicker
                    autoOk
                    disablePast
                    inputVariant="outlined"
                    disableToolbar
                    variant="inline"
                    format="yyyy-dd-MM"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data"
                    value={formikProps.values.date}
                    onChange={(date) => {
                      console.log("on change date", date)
                      props.setFieldValue("date", date)
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  /> */}
                  {/* <MojeTextInput
                name="date"
                value={formikProps.values.date}
                onChange={formikProps.handleChange}
                onBlur={formikProps.onBlur}
              /> */}
                </MojeFormField>
              )}
              {/* <MojeFormField
                label="Cały dzień"
                name="isAllDay"
                value={formikProps.values.isAllDay}
                formikProps={formikProps}
                isEdited
              >
                <Checkbox
                  name="isAllDay"
                  value={formikProps.values.isAllDay}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.onBlur}
                />
              </MojeFormField>

              {!props.values.isAllDay && (
                <>
                  <MojeFormField
                    label="Godzina Od (HH:MM)"
                    name="fromHour"
                    value={formikProps.values.fromHour}
                    formikProps={formikProps}
                    isEdited
                  >
                    <MojeTextInput
                      name="fromHour"
                      value={formikProps.values.fromHour}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.onBlur}
                    />
                  </MojeFormField>

                  <MojeFormField
                    label="Godzina Do (HH:MM)"
                    name="tillHour"
                    value={formikProps.values.tillHour}
                    formikProps={formikProps}
                    isEdited
                  >
                    <MojeTextInput
                      name="tillHour"
                      value={formikProps.values.tillHour}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.onBlur}
                    />
                  </MojeFormField>
                </>
              )} */}
              <MojeFormField
                label="Komentarz (nie widoczny dla użytkowników)"
                name="remarks"
                formikProps={formikProps}
                isEdited
              >
                <MojeTextInput />
              </MojeFormField>
              <Box display="flex">
                <MButton
                  grouped
                  secondary
                  label="Anuluj"
                  onClick={formikProps.onCancel}
                  variant="outlined"
                />
                <Box mx={2}>
                  <MButton
                    grouped
                    primary
                    label="Zapisz niedostępny termin"
                    submit
                    isSaving={isSaving}
                    variant="contained"
                  />
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
