const dowNames = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela"
]

export class DowUtils {
  static getDowName(i) {
    return dowNames[i - 1]
  }
}
