import { Box } from "@mui/material"
import { PageContainer } from "src/components/containers/PageConatiner"
import { useMerchantContext } from "src/contexts/merchant-context"

import { useAuthContext } from "../../auth/auth-context"
import { MessagesHomeWidget } from "../messages/components/MessagesHomeWidget"
import { HomeOnboardingWidget } from "../onboarding/HomeOnboardingWidget"
import { HomePromotionsWidget } from "./components/HomePromotionsWidget"
import { HomeWelcomeWidget } from "./components/HomeWelcomeWidget"

export const HomeDashboard = (props) => {
  const authContext = useAuthContext()
  const merchantContext = useMerchantContext()

  return (
    <PageContainer>
      <Box pt={4} width="100%">
        {authContext.currentUser && (
          <h6 className="text-3xl mb-5 mt-1">
            Witaj, {authContext.currentUser.name}
          </h6>
        )}
        {!merchantContext.onboardingProgress.onboardingCompleted && (
          <div className="mb-4">
            <HomeOnboardingWidget />
          </div>
        )}
        <HomeWelcomeWidget />
        {/* </Paper> */}

        {/* <Box
          display="flex"
          style={{
            marginTop: 32
          }}
        > */}
        <div className="mt-4 md:columns-2 gap-4 lg:columns-2">
          <MessagesHomeWidget />
          <div className="mt-4">
            <HomePromotionsWidget />
          </div>
        </div>
      </Box>
    </PageContainer>
  )
}
