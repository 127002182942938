import { onAuthUIStateChange } from "@aws-amplify/ui-components"
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { Auth } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { MojeCard } from "src/components/card/MojeCard"
import { PageContainer } from "src/components/containers/PageConatiner"

import { useAuthContext } from "../../auth/auth-context"
import { LoadingWidget } from "../../components/LoadingWidget"
import { MButton } from "../../components/MButton"
import { LoginScreen } from "../../modules/auth/LoginScreen"
import { MyProfileInfo } from "../../modules/profile/MyProfileInfo"

export function ProfilePage(props) {
  console.log("Render profile page", props)
  const router = useHistory()
  const authContext = useAuthContext()

  const [user, setUser] = useState(null)
  const [authState, setAuthState] = useState<any>(undefined)
  useEffect(() => {
    // Access the user session on the client
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("User: ", user)
        setUser(user)
      })
      .catch((err) => setUser(null))
  }, [])

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      console.log("Auth state changed", nextAuthState)
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])
  useEffect(() => {}, [])

  const handleProfileCreated = () => {
    console.log("handleProfileCreated")
    authContext.refetchCurrentUser()
  }

  const renderItem = (label, path, isSuper = false, subtitle = undefined) => {
    return (
      <Link to={path}>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "16px",
            margin: 8,
            cursor: "pointer"
          }}
        >
          <div>{label}</div>
          {subtitle && <div>{subtitle}</div>}
        </div>
      </Link>
    )
  }

  return (
    <>
      <PageContainer>
        {authContext.currentUser && (
          <div className="flex pt-4">
            <h6 className="text-3xl mb-5 mt-1">
              Witaj, {authContext.currentUser.name}
            </h6>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-end",
                display: "flex"
              }}
            >
              <MButton
                primary
                label="Wyloguj"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  authContext.logout()
                  router.push("/")
                }}
              />
            </div>
          </div>
        )}

        {/* Current User:
      {JSON.stringify(authContext.currentUser)} */}
        {/* <MButton
        label="Refresh my info"
        onClick={() => {
          authContext.refetchCurrentUser()
        }}
      /> */}
        {authContext.isLoadingProfile ? (
          <div>
            <div>Ładowanie profilu</div>
            <LoadingWidget />
          </div>
        ) : (
          <>
            {!authContext.cognitoUser ? (
              <LoginScreen />
            ) : (
              // <RegisterProfileForm onProfileCreated={handleProfileCreated} />
              <div className="mt-4">
                <MyProfileInfo />
              </div>
            )}
          </>
        )}

        {authContext.isSuperAdmin && (
          <div className="mt-4">
            <MojeCard title="Funkcje administratora Moje Urodziny">
              {renderItem(
                "Ustawienia strony MojeUrodziny.pl",
                "/admin/website"
              )}
              {renderItem("*Firmy", "/admin/merchants")}
              {renderItem("*Miejsca", "/admin/venues")}
              {renderItem("*Rezerwacje", "/admin/reservations")}
              {renderItem("*Blog", "/admin/articles")}
              {renderItem("*Miasta", "/admin/locations")}
              {renderItem("*Kategorie", "/admin/categories")}
              {renderItem("*Strony", "/admin/pages")}
              {renderItem("*Płatności", "/admin/payments")}
              {renderItem("Kupony", "/admin/coupons")}
            </MojeCard>
          </div>
        )}
      </PageContainer>
    </>
  )
}
export default ProfilePage
