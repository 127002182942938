import { Button } from "@mui/material"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useState } from "react"
import { MButton } from "../../components/MButton"
import { MojeTextInput } from "../../components/MojeTextInput"

import { LoginByEmailFormStyled } from "./LoginByEmailForm"

const STATUS_REQUEST_OK = "REQUEST_OK"
const STATUS_ENTER_EMAIL = "STATUS_ENTER_EMAIL"
const STATUS_ERROR = "STATUS_ERROR"
const STATUS_SUCCESS = "STATUS_SUCCESS"
const STATUS_NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
export const ForgotPasswordForm = (props) => {
  const [status, setStatus] = useState<string>(
    props.loginMode == "NEW_PASSWORD_REQUIRED"
      ? STATUS_NEW_PASSWORD_REQUIRED
      : STATUS_ENTER_EMAIL
  )

  const [message, setMessage] = useState<string>(undefined)

  const handleSubmit = async (values, props) => {
    console.log("handleSubmit", values, props)
    props.setSubmitting(true)
    if (status === STATUS_REQUEST_OK) {
      await doSetNewPassword(values)
    } else {
      var res = await Auth.forgotPassword(values.email)
      console.log("Forgot password res", res)
      setStatus(STATUS_REQUEST_OK)
    }
    props.setSubmitting(false)
  }

  const doSetNewPassword = async (values) => {
    console.log("doSetNewPassword")
    try {
      var res = await Auth.forgotPasswordSubmit(
        values.email,
        values.code,
        values.newPassword
      )
      console.log("Result", res)
      setStatus(STATUS_SUCCESS)
    } catch (err) {
      console.log("Err")
      setStatus(STATUS_ERROR)
      setErrorMessage(err.message)
    }
  }

  const setErrorMessage = (message) => {
    setMessage(message)
  }

  return (
    <LoginByEmailFormStyled>
      <div>Przypomnij hasło</div>

      <Formik initialValues={{} as any} onSubmit={handleSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit} className="Form">
            <div className="Field">
              <MojeTextInput
                type="text"
                className="form-control mps-metro-input"
                id="email"
                name="email"
                placeholder="E-mail"
                required
                onChange={handleChange}
                value={values.email}
              />
              {status === STATUS_REQUEST_OK && (
                <>
                  <div className="Field">
                    <MojeTextInput
                      type="password"
                      className="form-control mps-metro-input"
                      id="newPassword"
                      name="newPassword"
                      placeholder="Nowe hasło"
                      required
                      onChange={handleChange}
                      value={values.newPassword}
                    />
                  </div>
                  <div className="Field">
                    <div>Wprowadź kod wysłany na email</div>
                    <MojeTextInput
                      type="text"
                      className="form-control mps-metro-input"
                      id="code"
                      name="code"
                      placeholder="Code"
                      required
                      onChange={handleChange}
                      value={values.code}
                    />
                  </div>
                </>
              )}
              <div className="Field">
                <MButton
                  label={
                    status == STATUS_ENTER_EMAIL
                      ? "Przypomnij hasło"
                      : "Ustaw nowe hasło"
                  }
                  submit
                  variant="outlined"
                  color="primary"
                  isSaving={isSubmitting}
                />
              </div>
              {status == STATUS_ERROR && <>{message}</>}
              {status == STATUS_SUCCESS && (
                <>
                  <div>Hasło zostało zmienione!</div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onGotoLogin}
                  >
                    Zaloguj
                  </Button>
                  {/* <MButton
                    label="Zaloguj"
                    primary
                 
                  /> */}
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </LoginByEmailFormStyled>
  )
}
