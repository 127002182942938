import { Box } from "@mui/material"
import { FormattedDate } from "react-intl"
import { BusinessTable } from "src/components/business-table"

export const ArticlesList = ({ articles }: { articles: any }) => {
  const columns = [
    {
      accessor: "title",
      header: "Tytuł",
      Cell: (row) => {
        return <div>{row.title}</div>
      }
    },

    {
      header: "Data publikacji",
      Cell: (row) => {
        return (
          <Box textAlign="center">
            {row.publishedAt ? (
              <FormattedDate value={row.publishedAt} />
            ) : (
              <span>-</span>
            )}
          </Box>
        )
      }
    }
  ]
  return (
    <Box>
      {articles && (
        <BusinessTable
          data={articles}
          columns={columns}
          rowLinkFn={(article) => `/admin/articles/${article.id}`}
        />
      )}
    </Box>
  )
}
