import { Box, Typography, Container, Card, Grid } from "@mui/material"
import { Formik } from "formik"
import useAxios from "axios-hooks"
import axios from "axios"
import { MojeFormField } from "src/components/MojeFormField"
import Checkbox from "@mui/material/Checkbox"
import { MButton } from "src/components/MButton"
import { MojeTextInput } from "src/components/MojeTextInput"
import { ToastrType, useToastr } from "../../../components/MojeToastr"
import { MojeTextArea } from "src/components/MojeTextArea"

export const AdminWebsiteSetupPanel = () => {
  var url = `${process.env.REACT_APP_API_URL}/v1/page-settings`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  const showToastr = useToastr()

  const doSubmit = async (values: any, actions) => {
    console.log("Do save ", values)

    var updateUrl = `${process.env.REACT_APP_API_URL}/v1/super/page-settings`
    const asTable = Object.keys(values).reduce(
      (a, i) => [
        {
          code: i,
          value: values[i]
        }
      ],
      []
    )

    console.log("as talbe", asTable)

    const res = await axios.post(updateUrl, asTable)
    if (res.status === 200) {
      showToastr("Zapisano")
    }
  }
  console.log("Age settings: ", data)

  const initialValues = data
    ? data.reduce((a, i) => ({ ...a, [i.code]: i.value }), {})
    : {}
  console.log("initialValues", initialValues)
  return (
    <Container>
      <Box p={2}>
        <Box my={2}>
          <Typography variant="h4">
            Ustawienia strony głównej MojeUrodziny
          </Typography>
        </Box>
        <Card elevation={1}>
          <Box p={2}>
            <Formik
              initialValues={initialValues}
              onSubmit={doSubmit}
              enableReinitialize
            >
              {(formikProps: any) => {
                return (
                  <form onSubmit={formikProps.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MojeFormField
                          label="Ustaw specjalny czarno-biały tryb strony"
                          name="blackAndWhiteMode"
                          formikProps={formikProps}
                          isEdited
                          roComponent={() =>
                            formikProps.values.blackAndWhiteMode ? "Tak" : "Nie"
                          }
                          onChange={(event) => {
                            formikProps.setFieldValue(
                              "blackAndWhiteMode",
                              event.target.checked
                            )
                          }}
                        >
                          <Checkbox
                            checked={formikProps.values.disabledFriendly}
                          />
                        </MojeFormField>
                      </Grid>
                      <Grid item xs={12}>
                        <MojeFormField
                          label="Włącz czasową niedostępność"
                          name="temporarilyUnavailable"
                          formikProps={formikProps}
                          isEdited
                          roComponent={() =>
                            formikProps.values.temporarilyUnavailable
                              ? "Tak"
                              : "Nie"
                          }
                          onChange={(event) => {
                            formikProps.setFieldValue(
                              "temporarilyUnavailable",
                              event.target.checked
                            )
                          }}
                        >
                          <Checkbox
                            checked={formikProps.values.temporarilyUnavailable}
                          />
                        </MojeFormField>
                      </Grid>
                      <Grid item xs={12}>
                        <MojeFormField
                          label="Tytuł promo na stronie głównej"
                          name="homeTitle1"
                          formikProps={formikProps}
                          isEdited
                          component={MojeTextInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MojeFormField
                          label="Wiadomość powitalna w panelu administracyjnym"
                          name="adminDashboardWelcomeMessage"
                          formikProps={formikProps}
                          isEdited
                          component={MojeTextArea}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row">
                          <MButton
                            variant="contained"
                            color="primary"
                            submit
                            label="Zapisz"
                            grouped
                            isSaving={formikProps.isSubmitting}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )
              }}
            </Formik>
          </Box>
        </Card>
      </Box>
    </Container>
  )
}
