import 'draft-js/dist/Draft.css'

import Editor from '@draft-js-plugins/editor'
import createImagePlugin from '@draft-js-plugins/image'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import { Box, Button, FormControl, OutlinedInput } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { AtomicBlockUtils, DefaultDraftInlineStyle, EditorState, RichUtils } from 'draft-js'
import { forwardRef, useImperativeHandle, useRef } from 'react'

const useToggleButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: any) => ({
      color: props.active
        ? theme.palette.primary.main
        : theme.palette.grey[600],
      fontWeight: props.active ? 700 : null
    })
  })
)

function ToggleButton(props) {
  const active =
    (props.editorState &&
      props.editorState.getCurrentInlineStyle().has(props.type)) ||
    (props.editorState &&
      props.editorState
        .getCurrentContent()
        .getBlockForKey(props.editorState.getSelection().getStartKey())
        .getType() === props.type)
  const classes = useToggleButtonStyles({ ...props, active })
  return (
    <Button
      size="small"
      className={classes.root}
      onMouseDown={(e) => {
        e.preventDefault()
        props.onMouseDown(props.type)
      }}
    >
      {props.children}
    </Button>
  )
}

const CustomEditor = forwardRef((props, ref) => {
  const editor = useRef<HTMLInputElement>()
  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        if (editor.current) editor.current.focus()
      }
    }
  })
  return <Editor editorRef={editor} {...props} />
})

export const DraftEditor = (props) => {
  const editor = useRef<HTMLInputElement>()
  function handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (
      ["italic", "underline", "strikethrough", "code"].includes(command) || // https://github.com/facebook/draft-js/blob/master/src/model/constants/DraftEditorCommand.js
      !newState
    ) {
      return "not-handled"
    }
    props.onChange(newState)
    return "handled"
  }

  function handleAddImage(media) {
    console.log("handleAddImage")
    // const newState = Object.keys(DefaultDraftInlineStyle).includes(type)
    //   ? RichUtils.toggleInlineStyle(props.value, type)
    //   : RichUtils.toggleBlockType(props.value, type)
    if (!media?.url) {
      return
    }
    const contentState = props.value.getCurrentContent()
    // const url = "https://www.mojeurodziny.pl/assets/kids.jpg"
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: media.url, mediaId: media.id, ...media }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(props.value, {
      currentContent: contentStateWithEntity
    })
    var newState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      " "
    )
    props.onChange(newState)
  }

  function stylingClick(type: string) {
    const newState = Object.keys(DefaultDraftInlineStyle).includes(type)
      ? RichUtils.toggleInlineStyle(props.value, type)
      : RichUtils.toggleBlockType(props.value, type)
    props.onChange(newState)
  }
  const plugins: any[] = [createInlineToolbarPlugin()]

  if (props.images) {
    const imagePlugin = createImagePlugin()
    plugins.push(imagePlugin)
  }

  return (
    <div>
      <div>
        <ToggleButton
          editorState={props.value}
          onMouseDown={stylingClick}
          type="header-one"
        >
          H1
        </ToggleButton>
        <ToggleButton
          editorState={props.value}
          onMouseDown={stylingClick}
          type="header-two"
        >
          H2
        </ToggleButton>
        <ToggleButton
          editorState={props.value}
          onMouseDown={stylingClick}
          type="unordered-list-item"
        >
          • Lista
        </ToggleButton>
        <ToggleButton
          editorState={props.value}
          onMouseDown={stylingClick}
          type="ordered-list-item"
        >
          1. Lista
        </ToggleButton>
        <ToggleButton
          editorState={props.value}
          onMouseDown={stylingClick}
          type="BOLD"
        >
          Pogrubienie
        </ToggleButton>
        {props.images && (
          <ToggleButton
            editorState={props.value}
            // onMouseDown={handleAddImage}
            type="IMAGE"
          >
            Kliknij miniaturkę zdjęcia aby wstawić
          </ToggleButton>
        )}
        {props.media && (
          <Box display="flex">
            {props.media.map((m: any) => {
              return (
                <Box
                  height={36}
                  width={36}
                  mr={1}
                  onClick={(e: any) => {
                    handleAddImage(m)
                  }}
                  key={m.id}
                  sx={{
                    "& img": {
                      width: "100%",
                      height: "100%",
                      cursor: "pointer"
                    }
                  }}
                >
                  <img src={m.url} />
                </Box>
              )
            })}
          </Box>
        )}
      </div>
      <div className="cursor-text" onClick={() => editor.current.focus()}>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            multiline
            fullWidth
            value={props.value}
            inputComponent={CustomEditor as any}
            inputProps={{
              plugins: plugins,
              name: props.name,
              onBlur: props.onBlur,
              ref: editor as any,
              editorState: props.value,
              handleKeyCommand: handleKeyCommand,
              onChange: props.onChange,
              placeholder: props.placeholder,
              minRows: 4,
              rows: 4,
              minHeight: 100
            }}
            sx={{
              "& img": {
                width: "100%"
              },
              "& .DraftEditor-root": {
                position: "unset"
              }
            }}
          />
        </FormControl>
      </div>
    </div>
  )
}
