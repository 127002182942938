import { Box } from "@mui/material"
import React, { useState } from "react"

import { BusinessTable } from "../../../../components/business-table"
import { MojeCard } from "../../../../components/card/MojeCard"
import { HoursDropdown } from "../../../../components/HoursDropdown"
import { MButton } from "../../../../components/MButton"
import { MojeInfoBar } from "../../../../components/MojeInfoBar"
import { useToastr } from "../../../../components/MojeToastr"
import { usePlaceApi } from "../../api/use_place_api"

interface VenueSectionTimeSlotsProps {
  onRefetch?: Function
  venue?: any
  venueId?: string
  refetch?: Function
}

export const VenueSectionTimeSlots: React.FunctionComponent<
  VenueSectionTimeSlotsProps
> = (props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [timeSlotsData, setTimeSlotsData]: [any, Function] = useState(
    props.venue && props.venue.timeSlots ? [...props.venue.timeSlots] : []
  )
  const showToastr = useToastr()
  const { runCommand, isSaving } = usePlaceApi()
  console.log("VenueSectionTimeSlots render", props)

  const handleSaved = () => {
    setIsEditing(false)
    props.onRefetch()
  }

  const handleSave = async () => {
    console.log("handleSave", timeSlotsData)
    const mappedTimeSlots = timeSlotsData.map((slot: any) => {
      return {
        start: slot.start,
        end: slot.end
      }
    })

    // Validate
    for (var i = 0; i < mappedTimeSlots.length; i++) {
      if (!mappedTimeSlots[i].start || !mappedTimeSlots[i].end) {
        showToastr("Wybierz godzinę")
        return
      }
      var iStart = Number(mappedTimeSlots[i].start.split(":")[0])
      var iEnd = Number(mappedTimeSlots[i].end.split(":")[0])

      if (iStart > iEnd) {
        showToastr(
          "Godzina otwarcia nie może być późniejsza niż zamknięcia - błąd w wierszu " +
            (i + 1).toString()
        )
        return
      }
    }
    const command = {
      aggregateId: props.venue.id,
      type: "Venue.UpdateTimeSlots",
      payload: {
        timeSlots: mappedTimeSlots
      }
    }

    try {
      console.log("command", command)
      const res: any = await runCommand(command)
      console.log("Update reslt ", res)
      if (res && res.success) {
        showToastr("Zapisano")
      }
      // toastr.success("Opening Hours saved")
      setIsEditing(false)
    } catch (error) {
      // toastr.error("Can't save Opening Hours")
    }
  }

  const handleStartEdit = () => {
    setIsEditing(true)
  }

  const handleChange = (idx, delta) => {
    console.log("handleChange0 ,dow", idx, delta)
    // const newData: any = [...timeSlotsData]
    // newData[idx] = { ...newData[dow], ...delta }
    // setTimeSlotsData(newData)
  }
  const renderTimeSlots = () => {
    const showTitle =
      props.venue &&
      props.venue.openingHours &&
      props.venue.openingHours.length > 0

    const updateSlot = (idx, newData) => {
      console.log("updateSlot", idx, newData)
      const newTimeSlots = [...timeSlotsData]
      newTimeSlots[idx] = newData
      setTimeSlotsData(newTimeSlots)
    }

    const columns = [
      {
        header: "Początek",
        Cell: (row, index) => {
          if (isEditing) {
            return (
              <HoursDropdown
                name="start"
                value={row.start}
                onChange={(event) =>
                  updateSlot(index, {
                    ...row,
                    start: event.target.value
                  })
                }
              />
            )
          }
          return <div>{row.start}</div>
        }
      },
      {
        header: "Koniec",
        Cell: (row, index) => {
          if (isEditing) {
            return (
              <HoursDropdown
                name="end"
                value={row.end}
                onChange={(event) =>
                  updateSlot(index, {
                    ...row,
                    end: event.target.value
                  })
                }
              />
            )
          }
          return <div>{row.end}</div>
        }
      }
    ]

    return <BusinessTable columns={columns} data={timeSlotsData} />
  }

  console.log("SectionGeneralnformation.render", props)
  return (
    <>
      <MojeInfoBar title="Godziny to przedział czasu, w którym można wynająć dane miejsce/usługę.">
        <div style={{ textAlign: "center" }}>
          <p>
            Np. Imprezę w sali zabaw można zarezerwować w godzinach 10:00-12:00,
            12:30-14:30, 15:00-17:30 wtedy takie przedziały należy wprowadzić
            (każdy przedział osobno).
          </p>
          <p>
            Wszelkie odstępstwa od tej reguły, np. 4 godzinną specjalną
            rezerwacje będzie można ręcznie zablokować w kalendarzu.
          </p>
        </div>
      </MojeInfoBar>
      <MojeCard title="Godziny">
        {renderTimeSlots()}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!isEditing ? (
            <>
              <MButton
                label={
                  !timeSlotsData || timeSlotsData.length == 0
                    ? "Dodaj"
                    : "Edytuj"
                }
                primary
                onClick={() => {
                  setIsEditing(true)
                  if (!timeSlotsData || timeSlotsData.length == 0) {
                    setTimeSlotsData([...timeSlotsData, {}])
                  }
                }}
              />
            </>
          ) : (
            <>
              {isEditing && (
                <MButton
                  label="Dodaj"
                  variant="contained"
                  onClick={() => {
                    setTimeSlotsData([...timeSlotsData, {}])
                  }}
                />
              )}
              <div style={{ flex: 1 }} />
              <Box mx={2}>
                <MButton
                  l={1}
                  label="Anuluj"
                  grouped
                  variant="outlined"
                  onClick={() => {
                    setIsEditing(false)
                  }}
                  secondary
                />
              </Box>
              <Box mx={2}>
                <MButton
                  l={1}
                  label="Zapisz"
                  grouped
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  isSaving={isSaving}
                />
              </Box>
            </>
          )}
        </div>
      </MojeCard>
    </>
  )
}
