import { createTheme } from "@mui/material"
import GlobalStyles from "@mui/material/GlobalStyles"

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none"
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "white"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    // TextInput: {
    //   styleOverrides: {
    //     input: {
    //       padding: "8px 16px"
    //     }
    //   }
    // },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#ebedef",
          border: "1px solid #d5d9dc",
          fontWeight: 600
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderLeft: "none",
          borderRight: "none"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // padding: "8px 16px"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8px 16px",
          fontSize: 14,
          lineHeight: "24px",
          height: 40,
          boxSizing: "border-box"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "8px 16px"
        }
      }
    }
  },
  typography: {
    body1: {
      fontWeight: 500
    },
    subtitle2: {
      fontWeight: 600
    },
    h5: {
      fontSize: "22px",
      fontWeight: 500,
      color: "#323b43"
    },
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji"
    ].join(",")
  },
  palette: {
    text: {
      primary: "#343f45"
    },
    primary: { main: "#3f51b5" }, //  ,"#ECBC00"
    secondary: { main: "#f50057" }
    // primary: { main: "#e6007e" }, // magenta #e6007e
    // secondary: { main: "#fdc600" } // yellow #fdc600
  },
  breakpoints: {
    // https://tailwindcss.com/docs/breakpoints
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1128
    }
  }
})

export const GlobalCss = () => (
  <GlobalStyles
    styles={{
      a: {
        textDecoration: "inherit",
        color: "inherit"
      }
    }}
  />
)
// export const GlobalCss = withStyles({
//   // @global is handled by jss-plugin-global.
//   "@global": {
//     // You should target [class*="MuiButton-root"] instead if you nest themes.
//     ".MuiButton-root": {
//       //   fontSize: "1rem"
//       textTransform: "none !important",
//       boxShadow: "none !important"
//     },
//     ".MuiOutlinedInput-input": {
//       padding: "12px 16px"
//     },
//     ".MuiInputBase-root": {
//       backgroundColor: "#f5f5f5;",
//       borderRadius: 5
//     },
//     a: {
//       color: "inherit",
//       textDecoration: "inherit"
//     },
//     ".MuiTypography-h5": {
//       fontSize: 14
//     }
//   }
// })(() => null)
