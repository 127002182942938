import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import { useForm } from "react-hook-form"
import { MojeFormFieldLabel } from "src/components/MojeFormField"

import { AdminUserUtils } from "./AdminUserUtils"

export const AdminUserPopup = ({
  user,
  isNew,
  onClose,
  open
}: {
  user?: any
  isNew?: boolean
  onClose: any
  open: boolean
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    console.log("onSubmit", data)
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle id="simple-dialog-title">Konto firmy</DialogTitle>
      {/* <Box style={getModalStyle()}> */}
      {/* <Box>
        <Typography>Edytuj dzien</Typography>
      </Box> */}
      <DialogContent
        style={{ width: "fit-content", minWidth: "500px" }}
      ></DialogContent>
      <DialogActions>
        <Box display="flex" padding={1} width="100%" flexDirection="column">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <MojeFormFieldLabel>Email</MojeFormFieldLabel>
              <Box>{AdminUserUtils.getUserAttribute(user, "email")}</Box>
            </Box>
            <Box>
              <MojeFormFieldLabel>Kod</MojeFormFieldLabel>
              <input defaultValue="rodzaj" {...register("code")} />
            </Box>

            <Box>
              <Button type="submit" color="primary" variant="contained">
                Zapisz
              </Button>
            </Box>
          </form>
          {/* {document ? (
            <>
              <Box>Twoje zamówienie jest gotowe</Box>

              <Box my={1}>
                <strong>{document.number}</strong>
              </Box>

              <Box>
                <Button
                  onClick={() => {
                    startPayingDocument(document)
                  }}
                  variant="contained"
                  color="primary"
                >
                  Opłać w zewnętrznym systemie
                </Button>
              </Box>
            </>
          ) : (
            <>
              {props.product && (
                <LicenseBox product={props.product} mode={props.mode} />
              )}
              <Box flex={1} />
              <Box p={1}>
                {!isSaving && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBuy}
                  >
                    Zamów i opłać
                  </Button>
                )}

                {isSaving && (
                  <>
                    <Box my={1} textAlign="center">
                      <CircularProgress />
                      <Box>Generowanie zamówienia</Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )} */}
          <Button variant="contained" color="primary" onClick={onClose}>
            Zamknij
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
