import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

export const useAdminMerchants = (filter: any = {}) => {
  var filters = []
  if (filter?.showDeleted) {
    filters.push("showDeleted=true")
  }

  var url = `${process.env.REACT_APP_API_URL}/admin/merchants?${filters.join(
    "&"
  )}`
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })

  const merchants = data

  return { merchants, loading, refetch }
}
export const useOwnerMerchants = () => {
  console.log("useOwnerMerchants")
  const url = `${process.env.REACT_APP_API_URL}/owner/merchants`
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { merchants: data.items, loading, refetch }
}
