import useAxios from 'axios-hooks'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { LoadingWidget } from '../../../components/LoadingWidget'
import { LocationsList } from '../../../modules/locations/LocationsList'

export default function AdminArticlesIndex(props) {
  let url = `${process.env.REACT_APP_API_URL}/v1/admin/locations`
  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })
  return (
    <PageContainer>
      {loading && <LoadingWidget />}
      <LocationsList items={data} refetch={refetch} />
    </PageContainer>
  )
}
