import { Box } from '@mui/material'
import React, { createContext, useContext, useState } from 'react'
import styled from 'styled-components'

import { MButton } from './MButton'

const ClapToastrStyled = styled.div<any>`
  .Background {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .Wrapper {
  }

  .Toastr {
    min-width: 200px;
    min-height: 200px;
    max-width: 400px;
    margin: auto;
    background-color: #fefefe;
    top: 50%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 50px 100px 0 #e4e6e8;
    display: flex;
    flex-direction: column;

    .Title {
      padding: 16px 16px;
      font-weight: 800;
      text-align: center;
    }
    .Body {
      padding: 8px 16px;
      flex: 1;
      overflow-y: auto;
    }

    .Footer {
      padding: 16px;
      background-color: #fafafa;
      text-align: right;

      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`
type ToastrContextType = {
  isVisible: boolean
  onShowToastr: any
  toastr?: any
  dismissToastr?: any
}

export type ToastrOptions = {
  type?: ToastrType
  message?: string
  onYes?: Function
  onNo?: Function
  yesLabel?: string
  noLabel?: string
  component?: Function
}
export const ToastrContext = createContext({
  isVisible: false,
  onShowToastr: () => {}
})

export enum ToastrType {
  INFO,
  CONFIRM,
  WARNING,
  ERROR,
  SUCCESS
}
export type ShowToastrFunctionType = (
  label: string,
  props?: ToastrOptions
) => void

export const useToastr = (props = {}): ShowToastrFunctionType => {
  const toastrContext: ToastrContextType = useContext(ToastrContext)

  console.log("useToastr toastrContext", toastrContext)
  const showToastr = (label: string, props?: ToastrOptions): void => {
    console.log("showToastr", props)
    toastrContext.onShowToastr(label, props)
  }

  return showToastr
}

export const ToastrContextProvider = ({ children }: any) => {
  const [currentToastr, setCurrentToastr]: [any, any] = useState()

  const Provider = ToastrContext.Provider

  const onShowToastr: any = (label: string, props: any) => {
    console.log("onShowToastr called")

    const toastr = {
      ...props,
      label,
      type: props && props.type ? props.type : ToastrType.INFO,
      message: props ? props.message : undefined
    }
    setCurrentToastr(toastr)
  }
  const dismissToastr = () => {
    console.log("dismissToastr")
    setCurrentToastr(undefined)
  }
  const value: ToastrContextType = {
    isVisible: currentToastr !== undefined,
    onShowToastr,
    toastr: currentToastr,
    dismissToastr
  }
  return <Provider value={value}>{children}</Provider>
}

export const ToastRenderer = () => {
  const toastrContext: ToastrContextType = useContext(ToastrContext)

  console.log("ToastRenderer", toastrContext)
  const { toastr } = toastrContext

  const renderConfirmButtons = () => {
    const onYes = (event: any) => {
      console.log("onYes", toastr)
      event.stopPropagation()
      if (toastr.onYes) {
        toastr.onYes()
      }
      toastrContext.dismissToastr()
    }
    const onNo = (event: any) => {
      event.stopPropagation()
      if (toastr.onNo) {
        toastr.onNo()
      }
      toastrContext.dismissToastr()
    }

    return (
      <div className="Footer">
        {toastr.type === ToastrType.CONFIRM && (
          <Box display="flex">
            <MButton onClick={onNo} grouped color="secondary">
              Nie
            </MButton>
            <Box flex={1} />
            <MButton
              onClick={onYes}
              primary
              grouped
              variant="contained"
              color="primary"
            >
              {toastr.yesLabel ?? "Tak"}
            </MButton>
          </Box>
        )}

        {toastr.type !== ToastrType.CONFIRM && (
          <Box my={2}>
            <MButton onClick={onYes} variant="contained" color="primary">
              OK
            </MButton>
          </Box>
        )}
      </div>
    )
    return
  }
  const toastrRendererProps = {
    closePopup: () => {
      toastrContext.dismissToastr()
    }
  }
  return (
    <ClapToastrStyled>
      {toastr && (
        <div className="Background">
          <div className="Toastr">
            <div className="Title">{toastr.label}</div>
            {toastr.component ? (
              toastr.component(toastrRendererProps)
            ) : (
              <>
                {toastr.message && typeof toastr.message != "object" && (
                  <div className="Body">{toastr.message}</div>
                )}
                {renderConfirmButtons()}
              </>
            )}
          </div>
        </div>
      )}
    </ClapToastrStyled>
  )
}
