import { Container } from "@mui/material"
import axios from "axios"
import { makeUseAxios } from "axios-hooks"
import { useParams } from "react-router-dom"
import { useAuthContext } from "src/auth/auth-context"

import { VenuePage } from "../../../modules/places/VenuePage"

const useAxios = makeUseAxios({ axios })

export default function VenuePageIdx(props) {
  const match = useParams<any>()

  const authContext = useAuthContext()
  console.log("match", match)
  var venueId = match.id
  const url = process.env.REACT_APP_API_URL + "/admin/venues/" + venueId
  const [{ data, loading, error }, refetch] = useAxios(url)
  const venue = data

  // console.log("VenuePageIdx", props, authContext.isSuperAdmin)
  return (
    <Container>
      {venue && (
        <VenuePage
          venue={venue}
          refetch={refetch}
          adminMode={props.adminMode}
        />
      )}
      {loading && <>Loading</>}
    </Container>
  )
}
