import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import styled from 'styled-components'
import uuidv4 from 'uuid'

import { BusinessTable } from '../../../../components/business-table'
import { MojeFormField } from '../../../../components/MojeFormField'
import { MojeTextInput } from '../../../../components/MojeTextInput'
import { DowUtils } from '../../../../utils/dow-utils'
import { buildPriceRows, ProductForm } from './ProductForm'

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px"
  },
  media: {
    height: 0,
    paddingTop: "50%" // 16:9
  }
})

const ProductSectionStyled = styled.div`
  .name {
    margin: 8px 0;
    font-weight: bold;
  }

  .pricesTable {
  }
`

export const ProductCard = ({
  product,
  onRemove,
  venue,
  onSaved,
  onStartEditing,
  onCancelEditing,
  isEditing
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [nextProductId, setNextProductId] = React.useState(uuidv4())
  const classes = useStyles()

  const productMedia =
    venue && venue.media
      ? venue.media.filter((photo: any) => photo.productId == product.id)
      : []

  console.log("Render product item", product)
  var columns = [
    {
      accessor: "dow",
      header: "Dzien tygodnia",
      align: "center",
      Cell: (row) => {
        return <div>{DowUtils.getDowName(row.dow)}</div>
      }
    },
    {
      accessor: "price",
      header: "Cena",
      align: "center",
      Cell: (row) => <div>{row.price}</div>
    },
    {
      accessor: "additionalPersonPrice",
      header: "Dodatkowa osoba",
      align: "center",
      Cell: (row) => <div>{row.additionalPersonPrice}</div>
    }
  ]

  const rows = buildPriceRows(product)
  console.log("productMedia", productMedia)
  return (
    <Grid item xs={12} sm={isEditing ? 12 : 3}>
      <Card>
        {!isEditing && (
          <CardMedia
            className={classes.media}
            image={
              productMedia && productMedia.length > 0
                ? productMedia[0].url
                : `/static/images/cards/paella.jpg`
            }
            title="Paella dish"
          />
        )}

        {/* <CardHeader
        title={product.name}
        subtitle={`Cena podstawowa za ${product.guestsCount} osób`}
        // action={
        //   <IconButton
        //     onClick={() => {
        //       setIsExpanded(!isExpanded)
        //     }}
        //   >
        //     {isExpanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}

        //     <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        //   </IconButton>
        // }
      /> */}
        <CardContent>
          {!isEditing && (
            <>
              <Typography variant="h5" color="textPrimary" component="h2">
                {product.name}
              </Typography>
              <Box
                style={{
                  maxHeight: 150,
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {product.archivedAt ? (
                  <span style={{ color: "red" }}>
                    Ten produkt jest zarchiwizowany i nie jest widoczny dla
                    klientów
                  </span>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  ></Typography>
                )}
              </Box>
            </>
          )}
          <ProductSectionStyled>
            {isEditing && (
              <ProductForm
                nextProductId={nextProductId}
                setNextProductId={setNextProductId}
                onCancel={() => {
                  onCancelEditing()
                }}
                onSaved={() => {
                  onSaved()
                }}
                venue={venue}
                product={product}
                onRemove={onRemove}
              />
            )}
            {/* 
          {!isEditing && (
            <>
              <ProductGalleryEdit productId={product.id} small />
              <div
                style={{ border: "1px solid #eee", padding: 16 }}
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </>
          )} */}

            {!isEditing && isExpanded && (
              <>
                <MojeFormField label="Ilość osób" isEdited>
                  <MojeTextInput
                    name="guestsCount"
                    value={product.guestsCount}
                    placeholder="Ilość osób"
                    onChange={
                      (event) => {}
                      // onChange({
                      //   ...product,
                      //   guestsCount: event.currentTarget.value
                      // })
                    }
                  />
                </MojeFormField>
                <BusinessTable small data={rows} columns={columns} />
              </>
            )}
          </ProductSectionStyled>
        </CardContent>
        <CardActions>
          <div style={{ flex: 1 }} />
          {!isEditing && (
            <Button color="primary" size="small" onClick={onStartEditing}>
              Edytuj
            </Button>
          )}
        </CardActions>
      </Card>{" "}
    </Grid>
  )
}
