// Enables photo uplaod
import React, { PureComponent, useState } from "react"

export const usePhotoUploader = (afterPhotoUploaded) => {
  const [tmpPhoto, setTmpPhoto] = useState<any>(undefined)

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.photos &&
  //     JSON.stringify(nextProps.photos) != JSON.stringify(this.state.photos)
  //   ) {
  //     this.setState({ photos: [...nextProps.photos] })
  //   }
  // }

  // const setStateAsync = (newState) =>
  //   new Promise((resolve) => {
  //     this.setState(newState, resolve)
  //   })

  const uploadToCloudinary = async (file, progressHandler) => {
    return new Promise((resolve) => {
      console.log("uploadToCloudinary", file)
      const cloudName = "mojeurodziny" // "mapspire"
      const unsignedUploadPreset = "admin_panel_upload" // "mapspire_web_upload"
      var url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
      var xhr = new XMLHttpRequest()
      var fd = new FormData()
      xhr.open("POST", url, true)
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")

      // Reset the upload progress bar
      //document.getElementById('progress').style.width = 0;

      // Update progress (can be used to show progress indicator)
      xhr.upload.addEventListener("progress", function (e) {
        //var progress = Math.round((e.loaded * 100.0) / e.total);
        // document.getElementById('progress').style.width = progress + "%";
        console.log(`fileuploadprogress data.loaded: ${e.loaded},
  data.total: ${e.total}`)

        const percentLoaded = Math.round((100 * e.loaded) / e.total)
        console.log("Percent loaded", percentLoaded, progressHandler)
        if (progressHandler) {
          progressHandler(percentLoaded)
        }
        // upload
      })

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText)
          // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
          var url = response.secure_url
          // Create a thumbnail of the uploaded image, with 150px width
          console.log("Cloudinary response", response)
          var tokens = url.split("/")
          tokens.splice(-2, 0, "w_150,c_scale")
          const thumb150Url = tokens.join("/")
          // var img = new Image() // HTML5 Constructor
          // img.src = tokens.join('/')
          // img.alt = response.public_id
          // document.getElementById('gallery').appendChild(img)

          resolve({
            url: response.url,
            thumb150Url,
            width: response.width,
            height: response.height,
            providerData: {
              cloudinaryEtag: response.etag,
              cloudinarySignature: response.signature,
              cloudinaryPublicId: response.public_id
            }
          })
        }
      }

      fd.append("upload_preset", unsignedUploadPreset)
      fd.append("tags", "browser_upload") // Optional - add tag for image admin in Cloudinary
      fd.append("file", file)
      xhr.send(fd)
    })
  }

  const uploadAndStoreFile = async (photo, data, progressHandler) => {
    console.log("uploadAndStoreFile", photo, data)
    const fileUid = photo.uid
    const uploadedPhoto = await uploadToCloudinary(photo.file, progressHandler)
    // let uploadedPhoto = await PhotosApi.uploadToS3(photo.file, fileUid, data)
    console.log("uploadedPhoto to Cloudinary")
    console.log(uploadedPhoto)
    return uploadedPhoto
    // Save photo
    // await this.saveMpsPhoto(uploadedPhoto)
  }

  const loadPhoto = async (inphoto, pProgressHandler) => {
    console.log("loadPhoto", inphoto, tmpPhoto)

    const reader = new window.FileReader()

    return new Promise(async (resolve) => {
      reader.onload = async (event: any) => {
        // add to list
        console.log("onload", event, inphoto)

        //   const photoIdx = this.state.photos.findIndex(
        //     (p) => p.id === inphoto.id
        //   )

        /// TODO - handle load progress
        if (event.type === "load") {
          // console.log('photoIdx', photoIdx)

          console.log("loaded file")
          const data = event.target.result
          console.log("data", data, "current photo", tmpPhoto)
          let photo = {
            ...inphoto,
            loadStatus: "uploading",
            data
          }
          console.log("new photo 129", photo)
          if (pProgressHandler) {
            pProgressHandler(inphoto.photoId, { data })
          }
          // let photos = [...this.state.photos]
          // photos[photoIdx] = photo
          setTmpPhoto(photo)
          // Replace

          console.log("Start uploading")
          const progressHandler = (percentUploaded) => {
            console.log("progressHandler", percentUploaded)
            const photo = {
              ...inphoto,
              loadStatus: "uploading",
              data,
              percentUploaded
            }
            if (pProgressHandler) {
              pProgressHandler(inphoto.photoId, { progress: percentUploaded })
            }
            console.log("photo: ", photo)
            setTmpPhoto(photo)
            //   const photos = [...this.state.photos]
            //   photos[photoIdx] = photo
            //   this.setStateAsync({ photos })
          }
          const res: any = await uploadAndStoreFile(
            photo,
            data,
            progressHandler
          ) // reader.result)
          console.log("Uploaded")
          // photo = { ...photo, loadStatus: 'uploaded' }

          const pphoto: any = {
            id: photo.id,
            ...res,
            sourceUrl: res.url,
            sourceType: "cloudinary_url",
            loadStatus: "uploaded"
          }
          // const lphotos = [...self.state.photos]

          // photos = [...this.state.photos]
          // photos[photoIdx] = pphoto
          // await this.setStateAsync({ photos })

          // if (idx >= 0) {
          //   console.log('Photo updated', pphoto)
          //   lphotos[idx] = pphoto
          // }
          console.log("PhotoUploadHoc - photos changed")
          // if (this.props.onPhotosChanged) {
          //   this.props.onPhotosChanged(photos)
          // }
          resolve(pphoto)
        }
      }
      // Add to photos
      const photo = { ...inphoto, loadStatus: "loading" }
      console.log("add photo1 ", photo)
      // await
      // await setStateAsync({ photos: [...this.state.photos, photo] })
      reader.readAsArrayBuffer(inphoto.file)
    })
  }

  const handleNewPhotoSelected = async (photo, progressHandler) => {
    console.log("handleNewPhotoSelected", photo)
    setTmpPhoto(photo)
    const res = await loadPhoto(photo, progressHandler)
    return res
  }
  const handleReset = () => {
    setTmpPhoto(undefined)
  }
  // return (
  //   <WrappedComponent
  //     {...props}
  //     tmpPhoto={tmpPhoto}
  //     //   photos={this.state.photos}
  //     onNewPhotoSelected={handleNewPhotoSelected}
  //     onReset={handleReset}
  //   />
  // )
  return {
    tmpPhoto,
    onPhotoSelected: handleNewPhotoSelected,
    reset: handleReset
  }
}
