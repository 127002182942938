import { Box, Card, CardContent, Typography } from '@mui/material'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import styled from 'styled-components'

import { MButton } from '../../components/MButton'
import { ConfirmationForm } from './ConfirmationForm'
import { ForgotPasswordForm } from './ForgotPasswordForm'
import { LoginByEmailForm } from './LoginByEmailForm'
import { LoginFormRegister } from './LoginFormRegister'
import { ResetTemporaryPasswordForm } from './ResetTemporaryPasswordForm'

const LoginFormWrapper = styled.div`
  width: 360px;
  min-height: 360px;
  max-height: 450px;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0 0px 10px 0px rgba(50, 50, 60, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const LoginForm = (props) => {
  const [loginMode, setLoginMode] = useState(
    props.register ? "signup" : "signin"
  )
  const [isSaving, setIsSaving] = useState(false)
  const [tmpUser, setTmpUser] = useState()

  const handleSignInByEmail = async (values) => {
    console.log("handleSigninByEmailAWS")
    setIsSaving(true)
    try {
      const user = await Auth.signIn(values.email, values.password)
      console.log("user", user)
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("NEW_PASSWORD_REQUIRED")
        setLoginMode("NEW_PASSWORD_REQUIRED")
        setTmpUser(user)
      }
      setIsSaving(false)
      return user
    } catch (err) {
      console.error("Error signing in ", err)
      setIsSaving(false)
      throw err
    }
    setIsSaving(false)
  }
  const handleClickResetPassword = () => {
    setLoginMode("resetPassword")
  }
  const handleRegisterByEmail = async (values) => {
    console.log("Handleregister by email")
    setIsSaving(true)
    try {
      const res = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          preferred_username: values.email,
          email: values.email,
          name: values.email
        }
      })

      console.log("signup result", res)
      setIsSaving(false)

      if (res.userSub && res.userConfirmed === false) {
        // we got user - redirect to confirmation page
        setLoginMode("confirmation")
        // setConfirmationUsername(values.phone)
      } else if (res.userSub && res.userConfirmed) {
        console.log("Logged in")
      }
    } catch (err) {
      console.log("Register exception")
      setIsSaving(false)
    }
    // return user
  }
  return (
    <Card
      sx={{ boxShadow: "0 12px 48px rgb(26 33 52 / 11%)" }}
      className="rounded-lg"
    >
      <CardContent sx={{ padding: 4 }}>
        <Box mb={3}>
          <Typography
            gutterBottom
            variant="h5"
            component="h1"
            fontWeight="bold"
          >
            {loginMode === "signin" ? "Zaloguj się" : "Zarejestruj firmę"}
          </Typography>
        </Box>
        {loginMode === "resetPassword" && (
          <ForgotPasswordForm
            onGotoLogin={() => {
              setLoginMode("signin")
            }}
            loginMode={loginMode}
          />
        )}
        {loginMode == "NEW_PASSWORD_REQUIRED" && (
          <ResetTemporaryPasswordForm tmpUser={tmpUser} />
        )}
        {loginMode === "signup" && <LoginFormRegister />}
        {loginMode === "confirmation" && <div>Potwierdź e-mail</div>}
        {loginMode === "confirmation" && (
          <ConfirmationForm
            onConfirmed={() => {
              setLoginMode("signin")
            }}
          />
        )}
        {(loginMode === "singup" || loginMode == "signin") && (
          <LoginByEmailForm
            loginMode={loginMode}
            onSignInByEmail={handleSignInByEmail}
            onRegisterByEmail={handleRegisterByEmail}
            onClickResetPassword={handleClickResetPassword}
            isLoggingIn={isSaving}
          />
        )}
        <div style={{ textAlign: "center", marginTop: 10 }}>
          {loginMode == "signin" && (
            <Box style={{ marginTop: "10px 0" }}>
              <MButton
                text
                label="Zarejestruj nową firmę za DARMO"
                linkTo="/signup"
                // onClick={() => setLoginMode("signup")}
              />
            </Box>
          )}
        </div>
      </CardContent>
    </Card>
  )

  return (
    <LoginFormWrapper>
      <Box p={2} textAlign="center" color="white" bgcolor="#e30074">
        <Typography variant="body2">
          <strong>
            {loginMode === "signin" ? "Zaloguj się" : "Zarejestruj firmę"}
          </strong>
        </Typography>
      </Box>
      <Box p={2} flex={1}></Box>
    </LoginFormWrapper>
  )
}
