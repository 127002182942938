import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CheckIcon from "@mui/icons-material/Check"
import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { format } from "date-fns"
import { Formik } from "formik"
import { useState } from "react"
import AddressForm from "src/components/address-form"
import {
  MerchantContextType,
  useMerchantContext
} from "src/contexts/merchant-context"
import * as Yup from "yup"

import { useAuthContext } from "../../auth/auth-context"
import { MojeCard } from "../../components/card/MojeCard"
import { MButton } from "../../components/MButton"
import {
  MojeFormField,
  MojeFormFieldLabel,
  MojeFormSectionTitle
} from "../../components/MojeFormField"
import { MojeTextArea } from "../../components/MojeTextArea"
import { MojeTextInput } from "../../components/MojeTextInput"
import { ToastrType, useToastr } from "../../components/MojeToastr"
import { SingleImageSelector } from "../../components/SingleImageSelector"
import { IdUtils } from "../../utils/id-utils"
import { useMerchantApi } from "./api/use_merchant_api"
import { useRegonSearch } from "./api/useRegonSearch"
import { MerchantAdminTools } from "./MerchantAdminTools"
import { MerchantCardLicense } from "./MerchantCardLicense"
import { MerchanSavedDialog } from "./widgets/MerchantSavedDialog"

const isValidBankAcount = (num: string) => {
  return num && num.length === 26
}
export const MerchantForm = (props) => {
  const { runCommand, isSaving } = useMerchantApi()
  const [newId, setNextId] = useState(IdUtils.generateId("Merchant"))
  const [isEdited, setIsEdited] = useState(!!props.isEdited)
  const { doSearchNip, isSearchingNip } = useRegonSearch()
  const authContext = useAuthContext()
  const merchantContext: MerchantContextType = useMerchantContext()
  const showToastr = useToastr()

  const handleSaveLogo = async (photo, formProps) => {
    console.log("handleSaveLogo", props)

    if (!props.merchant) {
      console.log("Creating")
      formProps.setFieldValue("logoImage", photo)
      return
    }
    const command = {
      type: "Merchant.UpdateLogoImage",
      aggregateId: props.merchant ? props.merchant.id : newId,
      aggregateType: "Merchant",
      payload: {
        ...photo
      }
    }

    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr("Logo Zapisane")
      if (props.onSaved) {
        props.onSaved()
      }
      // TODO - show toastr success, redirect to /merchants/:id
    } else {
      showToastr("Problem")
    }

    return true
  }

  const handleLoadNip = async (nip) => {
    console.log("handleLoadNip", nip)
    try {
      const result = await doSearchNip(nip)
      if (result) {
        console.log(result)
      }
    } catch (err) {
      console.error("Error search nip")
    }
  }
  // [ name] : String, required, max 150
  // [ nip ] :  String, required  (nip) (this is special country id number)
  // [ address ] : String , let's keep all address in one field
  // [ city ]
  // [ postCode ]
  // [ phone ]
  // [ email ]
  // [ instagramUrl ]
  // [ facebookUrl ]
  // [ websiteUrl ]
  // etc - not required
  // shortDescription: String MARKDOWN (use some editor widget) - 500 chars max

  const merchantValidationSchema: any = Yup.object({
    name: Yup.string()
      .max(150, "Nazwa firmy może mieć max 150 znaków")
      .required("Nazwa firmy jest wymagana"),
    nip: Yup.string().matches(/^\d{10}/, "Błędny format nipu (bez kresek)"),
    address: Yup.string().required("Adres jest wymagany"),
    city: Yup.string().required("Miasto jest wymagane"),
    postCode: Yup.string().matches(/^\d{2}-\d{3}$/, "Błędny format kodu"),
    phone: Yup.string()
      .nullable()
      .matches(/^0?\d{9}$/),
    email: Yup.string().nullable().email(),
    instagramUrl: Yup.string().nullable(),
    facebookUrl: Yup.string().nullable(),
    websiteUrl: Yup.string().nullable(),
    shortDescription: Yup.string()
      .required("Opis firmy jest wymagany")
      .typeError("Opis firmy jest wymagany")
      .max(500, "Krótki opis może mieć max 500 znaków"),
    managerName: Yup.string()
      .typeError("Podaj dane właściciela / managera")
      .required("Podaj dane właściciela / managera"),
    managerPhone: Yup.string()
      .typeError("Podaj dane kontaktowe do właściciela / managera")
      .required("Podaj dane kontaktowe do właściciela / managera")
      .matches(/^0?\d{9}$/),
    managerEmail: Yup.string()
      .typeError("Podaj e-mail do właściciela / managera")
      .required("Podaj e-mail do właściciela / managera"),
    bankAccount: Yup.string()
      .nullable()
      .length(26, "Numer rachunku bankowego musi mieć 26 znaków bez kresek")
  })

  const handleVerify = async (val) => {
    console.log("handleVerify")
    const command = {
      type: val ? "Merchant.Validate" : "Merchant.Unlock",
      aggregateId: props.merchant.id,
      aggregateType: "Merchant",
      payload: {}
    }
    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr(val ? "Firma Zweryfikowana" : "Firma odblokowana", {})
      props.refetch()
    }
  }
  const doSubmit = async (values: any, formikProps) => {
    console.log(values, "doSubmit", formikProps)
    const command = {
      type: props.merchant ? "Merchant.Update" : "Merchant.Create",
      aggregateId: props.merchant ? props.merchant.id : newId,
      aggregateType: "Merchant",
      payload: values
    }
    if (!values.ownerUser) {
      command.payload.ownerUser = authContext.currentUser
    }

    try {
      const res: any = await runCommand(command)
      if (res && res.success) {
        setIsEdited(false)
        if (!props.onboardingMode) {
          showToastr("Firma Zapisana", {
            component: ({ closePopup }) => {
              console.log("Render component, ", closePopup)
              return <MerchanSavedDialog closePopup={closePopup} />
            }
          })
        }
        if (props.onSaved) {
          props.onSaved()
        }
        merchantContext.reloadMerchants()
      } else {
        showToastr("Problem")
      }
    } catch (err) {
      console.error("Error saving merchant")
    }
    // TODO - here do merchant form It should have Yup validation, Formik , nice
    // info about errors On top you see pattern how we will call API Use
    // components - ClapFormField
  }

  const handleValidateBankAccount = async (formikProps) => {
    const { values, setFieldValue } = formikProps
    console.log("handleValidateBankAccount", values)
    const dateNow = format(new Date(), "yyyy-MM-dd")
    const valid = isValidBankAcount(values.bankAccount)
    console.log("valid", valid)
    if (valid) {
      const url = `https://wl-api.mf.gov.pl/api/check/nip/${values.nip}/bank-account/${values.bankAccount}?date=${dateNow}`

      try {
        var res = await fetch(url)
        console.log("res", res)
        if (res.status === 400) {
          // Error invalid account
          console.log("Account valid")
          setFieldValue("bankAcountVerified", false)
          const data = await res.json()
          showToastr(
            "Nie udało się zweryfikować konta bankowego z białą listą",
            {
              type: ToastrType.ERROR,
              message: data ? data.message : undefined
            }
          )
          return
        } else if (res.status === 200) {
          const data = await res.json()
          console.log("Account valid", data)
          if (data?.result && data.result.accountAssigned === "TAK") {
            setFieldValue("bankAcountVerified", true)
            showToastr("Konto zweryfikowane poprawnie", {
              type: ToastrType.SUCCESS
            })
            return
          }
        }
      } catch (err) {
        console.error("Error validating ", err)
        if (err?.status === 400) {
          console.log("Status 400 ")
          setFieldValue("bankAcountVerified", true)
        }
      }
    }
  }
  const doRemove = async () => {
    const command = {
      type: "Merchant.Delete",
      aggregateId: props.merchant ? props.merchant.id : newId,
      aggregateType: "Merchant",
      payload: {}
    }
    const res: any = await runCommand(command)
    if (res.success) {
      showToastr("Firma Usunięta")
      // router.push("/venues")
      setIsEdited(false)
    } else {
      showToastr("Problem z zapisem")
    }
  }

  const handleSaved = () => {
    console.log("Merchant Form - handleSaved")
    props.refetch()
  }
  const handleDelete = (event) => {
    console.log("handleDelete")

    showToastr("Czy na pewno chcesz usunac tą firmę?", {
      onYes: doRemove,
      type: ToastrType.CONFIRM,
      yesLabel: "Usuń firmę"
    })
  }

  const initialValues = {
    name: "",
    nip: "",
    address: "",
    city: "",
    postCode: "",
    phone: "",
    email: "",
    instagramUrl: "",
    facebookUrl: "",
    websiteUrl: "",

    managerPhone: "",
    ...props.merchant,
    shortDescription: props.merchant?.shortDescription ?? "",
    managerName: props.merchant?.managerName ?? ""
  }
  if (props.ownerUser) {
    initialValues.ownerUser = props.ownerUser
  }
  return (
    <>
      <div className="mt-4">
        <MojeCard
          title="Formularz Firmy"
          subtitle={props.merchant ? "Edytuj firmę" : "Zarejestruj firmę"}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={doSubmit}
            enableReinitialize
            validationSchema={merchantValidationSchema}
          >
            {(formikProps) => {
              console.log("formik prps", formikProps, !formikProps.errors.nip)
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <MojeFormSectionTitle label="Dane podstawowe" />
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <MojeFormField
                        label="Nazwa*"
                        name="name"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Logo"
                        name="logoImage"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        roComponent={<span>Logo</span>}
                      >
                        <SingleImageSelector
                          onSaveLogo={(photo) => {
                            return handleSaveLogo(photo, props)
                          }}
                          media={formikProps.values.logoImage}
                          disabled={!isEdited}
                        />
                      </MojeFormField>
                    </Grid>
                    <Grid item xs={12}>
                      <MojeFormField
                        label="Krótki opis (500 znaków)*"
                        name="shortDescription"
                        formikProps={formikProps}
                        isEdited={isEdited}
                      >
                        <MojeTextArea
                          name="shortDescription"
                          placeholder={
                            "Wprowadz krotki opis (do 500 znakow)..."
                          }
                        />
                      </MojeFormField>
                    </Grid>
                    <Grid item xs={12}>
                      <MojeFormField
                        label="Strona WWW"
                        name="websiteUrl"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MojeFormField
                        label="Facebook URL"
                        name="facebookUrl"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MojeFormField
                        label="Instagram URL"
                        name="instagramUrl"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <MojeFormSectionTitle label="Dane rejestrowe" />
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" flexDirection="row" alignItems="end">
                        <Box>
                          {/* <MojeFormFieldLabel>NIP</MojeFormFieldLabel> */}
                          {/* <MojeTextInput / */}
                          <MojeFormField
                            label="NIP (10 cyfr bez kresek)"
                            name="nip"
                            formikProps={formikProps}
                            isEdited={isEdited}
                            component={MojeTextInput}
                            error={formikProps.errors.nip}
                            valid={!formikProps.errors.nip}
                          />
                        </Box>
                        <Box
                          flexDirection="row"
                          justifyContent="flex-end"
                          pb={3}
                          pl={2}
                        >
                          <MButton
                            isSaving={isSearchingNip}
                            label="Pobierz z bazy NIP"
                            onClick={() =>
                              handleLoadNip(formikProps.values.nip)
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <AddressForm
                        values={formikProps.values}
                        onMarkerMoved={(point) => {
                          console.log("onMarkerMoved", point)
                          if (point && point.lat && point.lng) {
                            formikProps.setFieldValue("lat", point.lat)
                            formikProps.setFieldValue("lng", point.lng)
                          }
                        }}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        setFieldValue={formikProps.setFieldValue}
                        isEdited={isEdited}
                        showMap={false}
                      />
                    </Grid>
                    {/* <Grid item xs={6}>
                    <MojeFormField
                      label="Adres"
                      name="address"
                      formikProps={formikProps}
                      isEdited={isEdited}
                      component={MojeTextInput}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MojeFormField
                      label="Miasto"
                      name="city"
                      formikProps={formikProps}
                      component={MojeTextInput}
                      isEdited={isEdited}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MojeFormField
                      label="Kod Pocztowy"
                      type="postcode"
                      name="postCode"
                      formikProps={formikProps}
                      isEdited={isEdited}
                      component={MojeTextInput}
                    />
                  </Grid> */}
                    <Grid item xs={12} px={2}>
                      <MojeFormSectionTitle label="Dane kontaktowe do właściciela / managera " />
                      <Box>
                        * Z Tą osobą może kontaktować się opiekun firmy w celu
                        uzupełniania dodatkowych informacji.
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Imie i nazwisko*"
                        name="managerName"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Stanowisko"
                        name="managerPosition"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Telefon*"
                        type="tel"
                        name="managerPhone"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Email*"
                        type="email"
                        name="managerEmail"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <MojeFormSectionTitle label="Dane kontaktowe dla klientów" />
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Telefon"
                        type="tel"
                        name="phone"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MojeFormField
                        label="Email"
                        type="email"
                        name="email"
                        formikProps={formikProps}
                        isEdited={isEdited}
                        component={MojeTextInput}
                      />
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <MojeFormSectionTitle label="Dane do płatności" />
                    </Grid>

                    {formikProps.values.nip && !formikProps.errors.nip ? (
                      <>
                        <Grid item xs={5}>
                          <MojeFormField
                            label="Numer rachunku bankowego (26cyfr)"
                            type="text"
                            name="bankAccount"
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              )
                              formikProps.setFieldValue("bankAccount", value)
                              formikProps.setFieldValue(
                                "bankAcountVerified",
                                false
                              )
                            }}
                            formikProps={formikProps}
                            isEdited={isEdited}
                            component={MojeTextInput}
                            valid={
                              formikProps.values.bankAccount &&
                              !formikProps.errors.bankAccount
                            }
                          />
                          {/* {formikProps.values.bankAcountVerified && (
                        <Box p={1} bgcolor="grey" borderRadius={1}>
                          Konto bankowe zweryfikowne z białą listą
                        </Box>
                      )} */}
                        </Grid>
                        <Grid item xs={4}>
                          <MojeFormField
                            label="Nazwa banku"
                            type="text"
                            name="bankName"
                            formikProps={formikProps}
                            isEdited={isEdited}
                            component={MojeTextInput}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MojeFormFieldLabel>&nbsp;</MojeFormFieldLabel>
                          {formikProps.values.bankAcountVerified ? (
                            <Box display="flex" flexDirection="row">
                              <CheckIcon color="success" />
                              <Box>Zweryfikowano z białą listą</Box>
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={(e: any) =>
                                handleValidateBankAccount(formikProps)
                              }
                              disabled={!!formikProps.errors.bankAccount}
                            >
                              Weryfikuj z białą listą
                            </Button>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <MojeFormField
                            label="Komentarz dla klienta"
                            type="text"
                            name="paymentsComment"
                            formikProps={formikProps}
                            isEdited={isEdited}
                            component={MojeTextInput}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Box>
                          <Typography color="error">
                            Przed wprowadzeniem danych bankowych wymagane jest
                            uzupełnienie nipu.
                          </Typography>
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Box display="flex" mt={1}>
                        {isEdited ? (
                          <>
                            {authContext.isSuperAdmin && (
                              <Box my={1} display="flex">
                                <IconButton
                                  onClick={handleDelete}
                                  title="Usun miejsce"
                                  size="large"
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{ width: 16, height: 16 }}
                                  />
                                </IconButton>
                              </Box>
                            )}

                            <Box flex={1} />
                            <Box mx={1}>
                              <MButton
                                secondary
                                label="Anuluj"
                                variant="outlined"
                                color="secondary"
                                grouped
                                onClick={() => {
                                  setIsEdited(false)
                                  if (props.onCancel) {
                                    props.onCancel()
                                  }
                                }}
                              />
                            </Box>
                            <Box ml={1}>
                              <MButton
                                submit
                                variant="contained"
                                color="primary"
                                label="Zapisz"
                                grouped
                                isSaving={formikProps.isSubmitting}
                              />
                            </Box>
                          </>
                        ) : (
                          <>
                            {!isEdited && props.adminMode && (
                              <>
                                <Box display="flex">
                                  {props.merchant?.id &&
                                    (!formikProps.values.validatedAt ||
                                      !formikProps.values.locked) && (
                                      <MButton
                                        secondary
                                        label="Zweryfikuj (admin)"
                                        variant="outlined"
                                        color="secondary"
                                        grouped
                                        onClick={(e: any) => handleVerify(true)}
                                      />
                                    )}
                                  {props.merchant?.id &&
                                    formikProps.values.locked && (
                                      <Box mx={1}>
                                        <MButton
                                          secondary
                                          label="Odblokuj (admin)"
                                          variant="outlined"
                                          color="secondary"
                                          grouped
                                          onClick={(e: any) =>
                                            handleVerify(false)
                                          }
                                        />
                                      </Box>
                                    )}
                                </Box>
                                <Box flex={1} />
                              </>
                            )}

                            <MButton
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setIsEdited(true)
                              }}
                              label="Edytuj Firmę"
                              grouped
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {!formikProps.isValid && (
                        <div style={{ color: "var(--moje-color-red)" }}>
                          <div>Formularz zawiera błędy</div>
                          <div>
                            {formikProps.errors && (
                              <ul>
                                {Object.values(formikProps.errors).map(
                                  (err) => {
                                    return <li>{err}</li>
                                  }
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </MojeCard>
      </div>
      {props.adminMode && (
        <div className="mt-4">
          <MerchantAdminTools merchant={props.merchant} onSaved={handleSaved} />
        </div>
      )}
      <div className="mt-4">
        <MerchantCardLicense merchant={props.merchant} onSaved={handleSaved} />
      </div>
    </>
  )
}
