import axios from "axios"
import { makeUseAxios } from "axios-hooks"
import { useEffect, useState } from "react"

export const useOwnerMessages = (params) => {
  let url = process.env.REACT_APP_API_URL + "/owner/messages"
  if (params && params.userId) {
    url += "?userId=" + params.userId
  }
  const [chatData, setChatData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const { runCommand } = useMessagesApi()

  const readMessage = async (message) => {
    console.log("readMessage", message)
    if (!message.readAt) {
      const command = {
        aggregateId: message.id,
        type: "Message.Read"
      }
      try {
        const res: any = await runCommand(command)
        console.log("Read message res", res)
        if (res && res.aggregate && chatData?.messages) {
          const updatedMessages = chatData.messages.map((m) => {
            if (m.id === message.id) {
              m.readAt = res.aggregate.readAt
            }
            return m
          })
          console.log("Update chat data", updatedMessages)
          setChatData({ ...chatData, messages: updatedMessages })
        }
      } catch (err) {
        console.error("Can't mark message read")
      }
    }
  }

  useEffect(() => {
    refreshChat()
  }, [])

  const refreshChat = async () => {
    console.log("refreshChat")
    setIsLoading(true)
    const res = await axios.get(url)
    if (res && res.data) {
      console.log("Chat data0", res.data)
      setChatData({ messages: res.data })
      setIsLoading(false)
    }
  }

  return {
    data: chatData,
    loading: isLoading,
    refetch: refreshChat,
    readMessage
  }
}

export const useOwnerChats = (params) => {
  let url = process.env.REACT_APP_API_URL + "/owner/chats"
  if (params && params.userId) {
    url += "?userId=" + params.userId
  }
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { data, loading, refetch }
}

// export const useOwnerMessages = (id) => {
//   const [isSaving, setIsSaving] = useState(false)
//   const url = process.env.REACT_APP_API_URL + "/owner/reservations/" + id
//   // const [{ data, loading, error }, refetch] = useAxios(url)
//   const useAxios = makeUseAxios({ axios })
//   const [{ data, loading, error }, refetch] = useAxios(
//     {
//       url
//     },
//     {
//       useCache: false
//     }
//   )

//   return { data, loading, refetch }
// }
export const useMessagesApi = () => {
  const [isSaving, setIsSaving] = useState(false)
  const runCommand = async (command) => {
    console.log("Runing command", command)

    let commandUrl =
      process.env.REACT_APP_API_URL + `/owner/messages/${command.aggregateId}`

    return new Promise(async (resolve, reject) => {
      setIsSaving(true)

      var result = await axios({
        method: "POST",
        url: commandUrl,
        data: {
          ...command
        }
      })

      console.log("result", result)
      setIsSaving(false)
      if (result && result.status == 200) {
        resolve(result.data)
      } else {
        reject(result.data)
      }
    })
  }

  return { runCommand, isSaving }
}
