import { Box, Checkbox, FormControlLabel } from "@mui/material"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { PageContainer } from "src/components/containers/PageConatiner"

import { LoadingWidget } from "../../../components/LoadingWidget"
import { MButton } from "../../../components/MButton"
import { useAdminMerchants } from "../../../modules/merchants/api/use_merchants"
import { MerchantsList } from "../../../modules/merchants/MerchantsList"

interface IMerchantProps {
  merchants?: any
}

const MerchantsIndex = (props) => {
  const [showDeleted, setShowDeleted] = useState<any>(false)

  const merchantsFilter: any = {}
  if (showDeleted) {
    merchantsFilter.showDeleted = true
  }
  const { merchants, loading } = useAdminMerchants(merchantsFilter)
  const router = useHistory()
  return (
    <PageContainer
      title="Firmy"
      subtitle="Tryb administratora"
      toolsComponents={() => {
        return (
          <Box textAlign="right">
            <MButton
              primary
              onClick={() => router.push("/admin/merchants/new")}
            >
              Dodaj Firmę
            </MButton>{" "}
            <Box my={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={showDeleted}
                    onChange={(event) => {
                      setShowDeleted(event.target.checked)
                    }}
                  />
                }
                label="Pokaż tylko usunięte firmy"
              />
            </Box>
          </Box>
        )
      }}
    >
      {loading && <LoadingWidget />}
      <MerchantsList merchants={merchants} />
    </PageContainer>
  )
}

export default MerchantsIndex
