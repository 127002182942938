import styled from 'styled-components'

const BnbLabeledRowFieldStyled = styled.div<any>`
  .content {
    max-width: 704px;
    margin-left: auto;
    margin-right: auto;
    width: 100% !importat;
  }

  .border {
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: #ebebeb;
  }

  .inner {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    > .label {
      flex: 1;
    }

    > .value {
    }
  }

  ${(props) =>
    props.asPageTitle &&
    `
    .label {
        font-size: 32px;
        font-weight: 800;
    }
  `}
`

export const BnbLabeledRowField = ({
  label,
  children,
  labelComponent,
  asPageTitle,
  fullWidth
}: {
  label?: string
  children?: any
  labelComponent?: any
  asPageTitle?: any
  fullWidth?: any
}) => {
  return (
    <BnbLabeledRowFieldStyled asPageTitle={asPageTitle}>
      <div className="content">
        <div className="border" />
        <div
          className="inner"
          style={{ flexDirection: fullWidth ? "column" : "row" }}
        >
          <div className="label">{labelComponent ? labelComponent : label}</div>
          <div className="value">{children}</div>
        </div>
      </div>
    </BnbLabeledRowFieldStyled>
  )
}
