import { Box } from "@mui/material"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React from "react"
import * as Yup from "yup"

import { useAuthContext } from "../../auth/auth-context"
import { MojeCard } from "../../components/card/MojeCard"
import { MButton } from "../../components/MButton"
import { MojeFormField } from "../../components/MojeFormField"
import { MojeTextInput } from "../../components/MojeTextInput"
import { useToastr } from "../../components/MojeToastr"
import { useProfileApi } from "./useProfileApi"

export const RegisterProfileForm = (props: any) => {
  const showToastr = useToastr()
  const { runCommand, isSaving } = useProfileApi()
  const authContext = useAuthContext()

  const doSubmit = async (values: any) => {
    console.log(values, "doSubmit")
    let user = await Auth.currentAuthenticatedUser()
    console.log("user", user)
    const command = {
      type: "User.Register",
      aggregateType: "User",
      aggregateId: user.attributes.sub,
      payload: values
    }

    const res: any = await runCommand(command)
    if (res.success) {
      showToastr("Profil utworzony")
      props.onProfileCreated()
      //   router.push("/places/" + command.aggregateId)
      // TODO - show toastr success, redirect to /place/:id
    } else {
      showToastr("Problem z zapisem")
      // TODO -show error toastr , res.message
    }
  }

  const validatoinSchema: any = Yup.object({
    name: Yup.string()
  })
  const initialValues: any = {}
  if (authContext.currentUser) {
    initialValues.name = authContext.currentUser.name
  }
  return (
    <Box my={2}>
      <MojeCard
        title={
          props.isEditing ? "Zaktualizuj profil" : "Dokończ rejestrację profilu"
        }
        subtitle={
          props.isEditing
            ? undefined
            : "Ostatnie pytanie - podaj nam Twoje imię i nazwisko. Dane te będą wykorzystywane w korespondencji z klientami"
        }
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values: any) => {
            doSubmit(values)
          }}
          enableReinitialize
          validationSchema={validatoinSchema}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <MojeFormField
                label="Nazwa Użytkownika"
                name="name"
                values={formikProps.values.name}
                formikProps={formikProps}
                isEdited
              >
                <MojeTextInput name="name" value={formikProps.values.name} />
              </MojeFormField>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <MButton
                  primary
                  color="secondary"
                  variant="outlined"
                  label="Wyloguj"
                  onClick={() => {
                    authContext.logout()
                  }}
                  grouped
                />
                <div style={{ flex: 1 }} />
                <MButton
                  submit
                  label={props.isEditing ? "Zaktualizuj" : "Zarejestruj"}
                  variant="contained"
                  color="primary"
                  isSaving={formikProps.isSubmitting}
                  grouped
                />
              </div>
            </form>
          )}
        </Formik>
      </MojeCard>
    </Box>
  )
}
