import { Grid } from "@mui/material"
import { ProductCard } from "./pricing-form/ProductCard"
export const VenueProductsList = ({
  venue,
  products,
  onRemoveProduct,
  onProductSaved,
  onStartEditing,
  onCancelEditing,
  editedProductId
}: {
  venue: any
  products: any
  onRemoveProduct: Function
  onProductSaved: Function
  onStartEditing: Function
  onCancelEditing: Function
  editedProductId: string
}) => {
  const showTitle = venue && venue.products && venue.products.length > 0

  return (
    <div>
      <Grid container spacing={3}>
        {Array.isArray(products) &&
          products.map((product: any) => {
            return (
              <ProductCard
                product={product}
                venue={venue}
                onRemove={onRemoveProduct}
                onSaved={onProductSaved}
                onStartEditing={() => onStartEditing(product)}
                onCancelEditing={onCancelEditing}
                isEditing={product.id && editedProductId == product.id}
              />
            )
          })}
      </Grid>
    </div>
  )
}
