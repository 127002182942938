import React from 'react'

export const ErrorBoundary = (props) => {
  const [hasError, setHasError] = React.useState(false)

  const componentDidCatch = (error, info) => {
    // Display fallback UI
    setHasError(true)
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
  }
  if (hasError) {
    // You can render any custom fallback UI
    return <h1>Something went wrong.</h1>
  }
  try {
    return props.children
  } catch (err) {
    console.log("Error cought")
    setHasError(true)
    return <span>Error</span>
  }
}
