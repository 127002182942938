import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import makeStyles from "@mui/styles/makeStyles"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AdminMerchantsDropdown } from "src/components/admin/AdminMerchantsDropdown"
import { BusinessPaginatedTable } from "src/components/BusinessPaginatedTable"
import { MojeFormFieldLabel } from "src/components/MojeFormField"

import { transform } from "../../utils/cloudinary"

const PlacesSearchFilters = (props: any) => {
  const [filters, setFilters] = useState<any>({})
  const handleMerchantChanged = (ev) => {
    console.log("handleMerchantChanged", ev)
    const newFilters = { ...filters }
    if (ev) {
      newFilters.merchantId = ev.id
    } else {
      delete newFilters.merchantId
    }
    setFilters(newFilters)
  }
  useEffect(() => {
    console.log("Filters changed", filters)
    if (props.onChange) {
      props.onChange(filters)
    }
  }, [filters])
  return (
    <Box display="flex" alignContent="center" justifyItems="center">
      {/* <Box mr={1}>
        <MojeFormFieldLabel>Firma:</MojeFormFieldLabel>
      </Box> */}
      <Box flex={1} mr={1} ml={1}>
        <Box>
          <MojeFormFieldLabel>Firma</MojeFormFieldLabel>
        </Box>
        <AdminMerchantsDropdown onChange={handleMerchantChanged} />
      </Box>

      <Box>
        <FormControlLabel
          control={
            <Checkbox
              value={filters.showDeleted}
              onChange={(event) => {
                console.log("Ch", event.target.checked)
                setFilters({
                  ...filters,
                  showDeleted: event.target.checked
                })
                // setShowDeleted(event.target.checked)
              }}
            />
          }
          label="Pokaż tylko usunięte miejsca"
        />
      </Box>
    </Box>
  )
}
export const VenuesTable = ({ queryParams }: { queryParams?: any }) => {
  const [filters, setFilters] = useState<any>({})
  const columns = [
    {
      accessor: "name",
      header: "Nazwa",
      Cell: (row) => {
        return <div>{row.name}</div>
      }
    },

    {
      header: "Kategoria",
      Cell: (row) => {
        return <div>{row.category?.name}</div>
      }
    },
    {
      accessor: "city",
      header: "Miasto",
      Cell: (row) => {
        return (
          <div>
            <div title="Miasto z adresu"> {row.city}</div>
            <div>
              <strong title="Miasto z katalogu">{row.location?.name}</strong>
            </div>
          </div>
        )
      }
    },
    {
      header: "Weryfikacja",
      accessor: "verification_count",
      Cell: (row) => {
        return <Box>{row.verification_count ?? 0}</Box>
      }
    },
    {
      accessor: "publishedAt",
      header: "Widoczne",
      Cell: (row) => {
        return (
          <Box
            style={{ color: row.publishedAt ? "green" : "grey" }}
            textAlign="center"
          >
            <FontAwesomeIcon icon={row.publishedAt ? faCheck : faTimes} />
            {/* {row.publishedAt ? "Tak" : "Nie"} */}
          </Box>
        )
      }
    }
  ]

  const apiUrl = `/admin/venues`
  var _queryParams = queryParams ? { ...queryParams } : {}
  if (filters) {
    _queryParams = { ..._queryParams, ...filters }
  }
  console.log("_queryParams", _queryParams)
  return (
    <BusinessPaginatedTable
      apiUrl={apiUrl}
      columns={columns}
      rowLinkFn={(place) => `/admin/venues/${place.id}`}
      queryParams={_queryParams}
      filtersComponent={<PlacesSearchFilters onChange={setFilters} />}
    />
  )
}
