import { Box, Button } from "@mui/material"
import React, { useEffect, useState } from "react"

import { BusinessTable } from "../../../../components/business-table"
import { MojeCard } from "../../../../components/card/MojeCard"
import { HoursDropdown } from "../../../../components/HoursDropdown"
import { MButton } from "../../../../components/MButton"
import { MojeInfoBar } from "../../../../components/MojeInfoBar"
import { useToastr } from "../../../../components/MojeToastr"
import { DowUtils } from "../../../../utils/dow-utils"
import { usePlaceApi } from "../../api/use_place_api"

interface OpeningHoursDetailsProps {
  onRefetch?: Function
  venue?: any
  venueId?: string
  categories?: any
  destinations?: any
  runCommand?: Function
  refetch: Function
}

export const PlaceSectionOpeningHours: React.FunctionComponent<
  OpeningHoursDetailsProps
> = (props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [openingHoursData, setOpeningHoursData]: [any, Function] = useState([])

  const { runCommand, isSaving } = usePlaceApi()
  const showToastr = useToastr()

  console.log("OpeningHoursDetailsProps render", props)
  const buildHours = (venue) => {
    console.log("build hours", venue.buildHours)

    const data: Array<any> = []
    for (let i = 0; i < 7; i++) {
      const day = {
        dow: i,
        open: "09:00",
        close: "18:00",
        isOpen: true
      }

      if (venue.openingHours && venue.openingHours.length > i) {
        console.log("Copy from opening hour", venue.openingHours)
        day.open = venue.openingHours[i].open
        day.close = venue.openingHours[i].close
      }
      console.log("Day0, da", day)
      data.push(day)
    }
    return data
  }
  useEffect(() => {
    const data: any = buildHours(props.venue)
    setOpeningHoursData(data)
  }, [props.venue])
  const handleSaved = () => {
    setIsEditing(false)
    props.onRefetch()
  }
  const handleSave = async () => {
    console.log("handleSave", openingHoursData, props)

    const mappedOpeningHours = openingHoursData.map((oh: any) => {
      return {
        dayOfWeek: oh.dow,
        open: oh.open,
        close: oh.close,
        isOpen: oh.isOpen
      }
    })
    // Validate
    for (var i = 0; i < mappedOpeningHours.length; i++) {
      if (mappedOpeningHours.isOpen) {
        if (!mappedOpeningHours[i].open) {
          showToastr("Wybierz godzinę otwarcia w wierszu " + (i + 1).toString())
          return
        }
        if (!mappedOpeningHours[i].close) {
          showToastr(
            "Wybierz godzinę zamkniecia w wierszu " + (i + 1).toString()
          )
          return
        }

        var iStart = Number(mappedOpeningHours[i].open.split(":")[0])
        var iEnd = Number(mappedOpeningHours[i].close.split(":")[0])

        if (iStart > iEnd) {
          showToastr(
            "Godzina otwarcia nie może być późniejsza niż zamknięcia - błąd w wierszu " +
              (i + 1).toString()
          )
          return
        }
      }
    }
    const command = {
      aggregateId: props.venue.id,
      type: "Venue.UpdateOpeningHours",
      payload: {
        openingHours: mappedOpeningHours
      }
    }

    console.log("command", command)
    try {
      const res: any = await runCommand(command)
      console.log("Update reslt ", res)
      // toastr.success("Opening Hours saved")
      setIsEditing(false)
      if (res && res.success) {
        showToastr("Zapisano")
        props.refetch()
      } else {
        showToastr("Problem z zapisaniem godzin otwarcia")
      }
    } catch (error) {
      // toastr.error("Can't save Opening Hours")
    }
  }

  const handleStartEdit = () => {
    setIsEditing(true)
  }
  const handleChange = (dow, delta) => {
    console.log("handleChange0 ,dow", dow, delta)
    const newData: any = [...openingHoursData]
    newData[dow] = { ...newData[dow], ...delta }
    setOpeningHoursData(newData)
  }
  const renderOpeningHours = (openingHours) => {
    if (!openingHours) {
      return <span />
    }

    var openingHoursData = []
    for (let i = 0; i < 7; i++) {
      var existing = props.venue.openingHours.find(
        (oh: any) => oh.dayOfWeek === i
      )
      openingHoursData.push(existing ?? { dayOfWeek: i, isOpen: false })
      // const day = {
      //   dow: i,
      //   open: "09:00",
      //   close: "18:00",
      //   isOpen: true
      // }
    }
    const showTitle =
      props.venue &&
      props.venue.openingHours &&
      props.venue.openingHours.length > 0

    const columns = [
      {
        header: "Dzień Tygodnia",
        Cell: (row) => {
          return <div> {DowUtils.getDowName(row.dayOfWeek + 1)}</div>
        }
      },
      {
        header: "Otwarcie",
        Cell: (row) => {
          return row.open
        }
      },
      {
        header: "Zamknięcie",
        Cell: (row) => {
          return row.close
        }
      }
    ]
    return (
      <>
        <BusinessTable small data={openingHoursData} columns={columns} />
      </>
    )
  }
  const handleCopyAllDays = () => {
    console.log("handleCopyAllDays", openingHoursData)
    if (!openingHoursData) {
      return
    }
    const newOpeningHoursData =
      openingHoursData && Array.isArray(openingHoursData)
        ? [...openingHoursData]
        : []

    for (var i = 1; i < 7; i++) {
      if (!newOpeningHoursData[i]) {
        newOpeningHoursData[i] = { dow: i + 1 }
      }
      newOpeningHoursData[i].dow = i + 1
      newOpeningHoursData[i].close = openingHoursData[0].close
      newOpeningHoursData[i].open = openingHoursData[0].open
      newOpeningHoursData[i].isOpen = openingHoursData[0].isOpen
    }
    console.log("newOpeningHoursData", newOpeningHoursData)
    setOpeningHoursData(newOpeningHoursData)
  }

  const renderEditOpeningHours = () => {
    const days: any = []
    console.log("Render Edit opening hours", openingHoursData)
    for (let i = 0; i < 7; i++) {
      var isOpen =
        openingHoursData[i].open ||
        openingHoursData[i].isOpen ||
        openingHoursData[i].close

      days.push(
        <tr style={{ marginBottom: 4 }}>
          <td>{DowUtils.getDowName(i + 1)}</td>
          {isOpen && (
            <React.Fragment>
              <td style={{ padding: 4 }}>
                <HoursDropdown
                  onChange={(event: any) => {
                    console.log("onChange", event.target)
                    handleChange(i, {
                      open: event.target.value,
                      isOpen: true
                    })
                  }}
                  value={openingHoursData[i].open}
                />
              </td>
              <td style={{ padding: 4 }}>
                <HoursDropdown
                  onChange={(event: any) => {
                    console.log("onChange", event.target)
                    handleChange(i, {
                      close: event.target.value,
                      isOpen: true
                    })
                  }}
                  value={openingHoursData[i].close}
                />
              </td>
              <td style={{ padding: 4 }}>
                {openingHoursData[i].open && (
                  <span
                    onClick={() => {
                      handleChange(i, {
                        close: undefined,
                        open: undefined,
                        isOpen: false
                      })
                    }}
                  >
                    <MButton
                      small
                      label="Zamknij"
                      onClick={() => {
                        handleChange(i, {
                          close: undefined,
                          open: undefined,
                          isOpen: false
                        })
                      }}
                    />
                  </span>
                )}
              </td>
              <td>
                {" "}
                {i == 0 ? (
                  <div>
                    <Button onClick={handleCopyAllDays}> Kopiuj </Button>
                  </div>
                ) : (
                  <div />
                )}
              </td>
            </React.Fragment>
          )}
          {!isOpen && (
            <React.Fragment>
              <td colSpan={3} style={{ cursor: "pointer", padding: 4 }}>
                <MButton
                  small
                  label="Zamknięte - kliknij aby otworzyć i dodać godziny"
                  onClick={() => {
                    handleChange(i, {
                      open: "9:00",
                      close: "23:00",
                      isOpen: true
                    })
                  }}
                />
              </td>
            </React.Fragment>
          )}
        </tr>
      )
    }
    console.log("DAYS", days)
    return (
      <div>
        <table>
          <tr>
            <th style={{ width: 150 }}>Dzień tygodnia</th>
            <th style={{ width: 200 }}>Otwarte</th>
            <th style={{ width: 200 }}>Zamknięte</th>
            <th style={{ width: 200 }}></th>
          </tr>

          {days}
        </table>
        <Box display="flex" justifyContent="flex-end" my={1}>
          <Box mx={2}>
            <MButton
              label="Anuluj"
              color="secondary"
              variant="outlined"
              onClick={() => {
                setIsEditing(false)
              }}
            />
          </Box>
          <Box mx={2}>
            <MButton
              label="Zapisz"
              color="primary"
              variant="contained"
              onClick={handleSave}
              isSaving={isSaving}
            />
          </Box>
        </Box>
      </div>
    )
  }
  const renderNoHours = (openingHours) => {
    const noHours = !openingHours || openingHours.length === 0
    console.log("render no renderNoHours ", openingHours)
    return (
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        {(!openingHours || openingHours.length === 0) && (
          <MojeInfoBar
            title="Brak godzin otwarcia dla tego miejsca"
            subtitle="Kliknij Edytuj godziny otwarcia aby wprowadzić"
          ></MojeInfoBar>
        )}
        <div style={{ flex: 1 }} />
      </div>
    )
  }

  const { venue } = props
  console.log("SectionGeneralnformation.render", props)
  const isNew = props.venueId === "new"
  const { openingHours } = venue
  const noHours = !openingHours || openingHours.length === 0
  return (
    <MojeCard
      title="Godziny Otwarcia"
      toolboxComponent={
        <span>
          <MButton
            primary={noHours}
            secondary={!noHours}
            variant="contained"
            color={noHours ? "primary" : "secondary"}
            label="Edytuj godziny otwarcia"
            onClick={handleStartEdit}
          />
        </span>
      }
    >
      {!isEditing && renderOpeningHours(openingHours)}
      {!isEditing && renderNoHours(openingHours)}
      {isEditing && renderEditOpeningHours()}
    </MojeCard>
  )
}
