import { Checkbox, FormControlLabel, Box } from "@mui/material"
import { FormattedDate, FormattedTime } from "react-intl"

export const VerificationCheckBox = ({
  code,
  label,
  isEditing,
  formikProps
}) => {
  const handleVeryficationChanged = (event: any) => {
    console.log("handleVeryficationChanged", code, event)
    const newVerification = formikProps.values.verification
      ? { ...formikProps.values.verification }
      : {}
    if (event.target.checked) {
      newVerification[code] = new Date().toISOString()
    } else {
      delete newVerification[code]
    }
    console.log("newVerification", newVerification)
    formikProps.setFieldValue("verification", newVerification)
  }
  return (
    <>
      <FormControlLabel
        disabled={!isEditing}
        label={label}
        control={
          <Checkbox
            onChange={handleVeryficationChanged}
            checked={!!formikProps.values.verification[code]}
          />
        }
      />
      {formikProps.values.verification[code] && (
        <Box fontSize={10}>
          <FormattedDate value={formikProps.values.verification[code]} />{" "}
          <FormattedTime value={formikProps.values.verification[code]} />
        </Box>
      )}
    </>
  )
}
