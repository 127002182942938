import { useState } from 'react'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { AdminUserPopup } from './AdminUserPopup'
import { AdminUsersTable } from './AdminUsersTable'

export const AdminUsersIndex = () => {
  const [item, setItem] = useState<any>()
  return (
    <PageContainer title="Uzytkownicy firm">
      <AdminUsersTable onRowClick={setItem} />
      <AdminUserPopup
        open={item}
        onClose={() => {
          setItem(undefined)
        }}
        user={item}
      />
    </PageContainer>
  )
}
