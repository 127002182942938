import { useParams } from 'react-router'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { LoadingWidget } from '../../components/LoadingWidget'
import { useToastr } from '../../components/MojeToastr'
import { ReservationDetails } from './ReservationDetails'
import { useReservation, useReservationApi } from './useReservationsApi'

export const ReservationPage = (props) => {
  const params = useParams<{ id: string }>()
  const { data, loading, refetch } = useReservation(params.id)

  const { runCommand, isSaving } = useReservationApi()
  const reservation = data

  const showToastr = useToastr()

  console.log("ReservationPage", params)

  const handleSendSuggestion = async (newConditions) => {
    console.log("handleSendSuggestion", newConditions)
    var command = {
      type: "Reservation.SuggestChange",
      aggregateId: reservation.id,
      payload: {
        ...newConditions
      }
    }
    try {
      const res: any = await runCommand(command)
      if (res && res.success) {
        showToastr("Zapisano")
        refetch()
      }
    } catch (err) {
      console.log("Error saving res")
    }
  }

  console.log("Reservation page", reservation)
  return (
    <PageContainer>
      {loading && <LoadingWidget />}
      {reservation && (
        <ReservationDetails reservation={reservation} refetch={refetch} />
      )}
    </PageContainer>
  )
}
