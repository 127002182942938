import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMerchantContext } from "src/contexts/merchant-context"

const Step = ({ label, completed }: { label: string; completed?: boolean }) => {
  return (
    <div className="border-b border-slate-400 px-2 py-4 hover:bg-lightblue font-medium flex ">
      <div className="flex-1">{label}</div>
      <div className="px-2">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={completed ? "text-green-600" : "text-grey-600"}
        />
      </div>
    </div>
  )
}
export const OnboardingTasksDropdown = ({ small }: { small?: boolean }) => {
  const merchantContext = useMerchantContext()
  return (
    <div className="py-4">
      <div className="text-center font-medium py-2 border-b">
        Instrukcja konfiguracji
      </div>
      <div className="p-3 font-bold text-lg">Tylko kilka kroków i gotowe!</div>
      {merchantContext.onboardingSteps.map((step, index) => (
        <Step label={step.label} completed={step.completed} key={index} />
      ))}
    </div>
  )
}
