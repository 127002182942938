import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, Typography } from '@mui/material'
import { MButton } from 'src/components/MButton'

export const LicenseBox = ({
  product,
  onClick,
  mode
}: {
  product: any
  onClick?: Function
  mode: string
}) => {
  var price = mode === "MONTH" ? product.monthlyPrice : product.yearlyPrice
  return (
    <Card elevation={2} style={{ cursor: "pointer" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={2} mb={1}>
          <Typography variant="h5">{product.name}</Typography>
        </Box>
        <Box my={2}>
          <Typography variant="h6">
            {price && (
              <Box fontWeight={900} textAlign="center">
                {price / 100} zł
              </Box>
            )}
          </Typography>
          <Box fontSize={12}>
            {mode === "MONTH" ? "Miesięcznie" : "Rocznie"}
          </Box>
        </Box>
        <Box my={2}>
          {onClick && (
            <MButton
              label="Wykup pakiet"
              variant="contained"
              color="secondary"
              onClick={() => onClick(product)}
            />
          )}
        </Box>
        <Box
          py={1}
          height={360}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {product.options &&
            product.options.map((op) => (
              <Box display="flex" flexDirection="row" mb={1}>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                <Box ml={1}>{op}</Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Card>
  )
}
