import React from 'react'

import { SectionMedia } from '../../../../components/media/SectionMedia'
import { usePlacePageContext } from '../../PlacePageContext'

export const ProductGalleryEdit = ({ productId, ...props }) => {
  const venuePageContext = usePlacePageContext()

  var media = venuePageContext.venue?.media
    ? venuePageContext.venue?.media.filter((m) => m.productId == productId)
    : []

  const handleAddMedia = async (newPhoto) => {
    console.log("handleAddMedia", newPhoto)
    return venuePageContext.onAddMedia({ ...newPhoto, productId: productId })
  }
  const handleRemoveMedia = async (newPhoto) => {
    console.log("handleAddMedia", newPhoto)
    return venuePageContext.onRemoveMedia(newPhoto)
  }
  return (
    <SectionMedia
      {...props}
      productId={productId}
      mode="product"
      media={media}
      onAddMedia={handleAddMedia}
      onRemoveMedia={handleRemoveMedia}
    />
    // </div>
  )
}
