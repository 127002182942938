import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const BusinessTableWrapperStyled = styled.div<any>`
  background-color: white;
  border-radius: 10px;
  padding: 32px;

  ${({ small }) => `
    tbody {
        font-size: 10px;
    }
    thead tr th,
    tbody tr td,
    tr,
    td {
        padding: 6px;
        color: #394450;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
        border-right: 1px solid white;
    }
  `}
  ${({ fullHeight }) =>
    fullHeight &&
    `
  height: 100%;
  // position: relative;
  position: absolute;

  table,
  tbody {
    // display: block;
  }
  thead {
    tr {
      // display: block;
      width: 100%;
      display: inline-table;
      table-layout: fixed;
    }
  }
  tbody {
    // overflow-y: auto;
    // height: 100%;
    display: block;
    tr {
      width: 100%;
      width: 100%;
      display: inline-table;
      table-layout: fixed;
    }
  }
  table {
    height: 100%;
  }
  tfoot {
    height: 30px;
    // display: block;
  }
    `}
`

export const BusinessTableStyled = styled.table<any>`
  border: 0px solid #ccc;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  color: #3a4650;

  .centered {
    text-align: center;
  }
  &__Wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 16px;
  }
  tr,
  td,
  th {
    padding: 24px 24px;
  }

  tr {
    border-bottom: 1px solid #eee;
  }
  thead tr th {
    padding-top: 16px;
    padding-bottom: 16px;
    color: #394450;
    letter-spacing: 1px;
    font-size: 14px;
    border-right: 2px solid white;
  }
  tr:nth-child(even) {
    background: #fbfbfb;
  }
  thead tr {
    border-bottom: 0px;
  }
  thead,
  th {
  }
  tr {
    &:hover {
      background-color: #f9fbfc;
      cursor: pointer;
    }
  }

  tbody {
    font-size: 14px;
  }
  &__CellLight {
  }

  &__CellMedium {
    font-weight: 600;
  }

  &__CellSmall {
    font-weight: 400;
    font-size: 12px;
  }

  &__CellStrong {
    font-weight: 800;
    font-size: 16px;
  }

  &--small {
    tbody {
      font-size: 10px;
    }
    thead tr th,
    tbody tr td,
    tr,
    td {
      padding: 6px;
      color: #394450;
      letter-spacing: 1px;
      font-size: 10px;
      border-right: 1px solid white;
    }
    tr,
    td {
    }
  }
`

export type SortParams = {
  field: string
  order: string
}

export const BusinessTable = (props) => {
  const [sort, _setSort] = useState<SortParams | undefined>()

  const setSort = (field) => {
    if (!field.accessor) {
      console.log("No field accessor")
    }
    var newSort = { ...sort }
    if (sort?.field === field.accessor) {
      newSort =
        newSort.order === "desc"
          ? undefined
          : { ...newSort, order: newSort.order === "desc" ? "asc" : "desc" }
    } else {
      newSort = { field: field.accessor, order: "asc" }
    }
    _setSort(newSort)
    if (props.onSortChanged) {
      props.onSortChanged(newSort)
    }
  }

  const renderBody = () => {
    const { data, columns } = props

    return data.map((row, index) => {
      const link = props.rowLinkFn ? props.rowLinkFn(row) : undefined
      const inner = (
        <tr
          id={row.id}
          onClick={() => (props.onRowClick ? props.onRowClick(row) : undefined)}
        >
          {columns.map((col) => {
            const val = row[col.accessor]
            var v = col.Cell ? col.Cell(row, index) : val
            if (!v) {
              v = <div style={{ width: "100%" }}>&nbsp;</div>
            }
            var classes = ""
            if (col.align === "center") {
              classes += "centered"
            }
            return link ? (
              <td className={classes}>
                <Link
                  to={link}
                  style={{
                    textDecoration: "none",
                    color: "initial",
                    display: "block",
                    width: "100%"
                  }}
                >
                  {v}
                </Link>
              </td>
            ) : (
              <td className={classes}>{v}</td>
            )
          })}
        </tr>
      )

      return inner
    })
  }

  if (!props.data || !props.columns) {
    return <div> Brak danych </div>
  }
  const { columns } = props

  return (
    <BusinessTableWrapperStyled fullHeight={props.fullHeight}>
      {/* <BusinessTableStyled small={props.small}> */}
      <table className="table-auto moje-table">
        <thead>
          <tr>
            {columns.map((col) => {
              return (
                <th
                  onClick={(e: any) => {
                    setSort(col)
                  }}
                >
                  <Box display="flex" style={{ cursor: "pointer" }}>
                    <Box>{col.header}</Box>
                    <Box>
                      {col.accessor && sort?.field === col.accessor && (
                        <Box ml={1}>
                          <FontAwesomeIcon
                            icon={
                              sort?.order === "asc" ? faArrowUp : faArrowDown
                            }
                            size="xs"
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
        {/* <tfoot>Table footer</tfoot> */}
      </table>
    </BusinessTableWrapperStyled>
  )
}
