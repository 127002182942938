import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'

import { useMerchantContext } from '../../contexts/merchant-context'
import { usePaymentContext } from './api/PaymentContext'
import { LicenseBox } from './components/LicenseBox'

export const BuyPackagePopup = (props: any) => {
  const merchantContext = useMerchantContext()
  const [errorMessage, setErrorMessage] = useState<any>()
  const [document, setDocument] = useState<any>()
  const { startPayingDocument, step } = usePaymentContext()
  const [isSaving, setIsSaving] = useState(false)

  const loadUnpaidDocs = async () => {
    console.log("loadUnpaidDocs")
  }
  useEffect(() => {
    loadUnpaidDocs()
  }, [])

  const handleBuy = async () => {
    console.log("handleBuy", props)
    if (!merchantContext.merchant) {
      console.error("No Merchant")
      return
    }
    const command = {
      type: "License.Purchase",
      aggregateId: merchantContext.merchant.id,
      payload: {
        package_code: props.product.code,
        period_type: props.mode,
        merchant_id: merchantContext.merchant.id
      }
    }

    const url = `${process.env.REACT_APP_API_URL}/v1/license`
    try {
      setIsSaving(true)
      const res = await axios(url, {
        method: "POST",
        data: { command }
      })

      console.log("res", res)
      if (res.status === 200 && res.data && res.data.document) {
        console.log("Otrzymano dokument", res.data.document)
        // setDocument(res.data.document)
        var payment = await startPayingDocument(res.data.document)
        if (payment?.link) {
          console.log("Open link (buy pack): ", payment.link)
          // const w = window.open(payment.link, "_blank")
          // console.log("Open:", w)
          // if (w) {
          //   w.focus()
          // }
          props.onClose()
        }
      }
    } catch (err) {
      console.error("Error buut")
      setIsSaving(false)
      setErrorMessage(err.message)
    }
  }

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">
        Wykup pakiet MojeUrodziny
      </DialogTitle>
      {/* <Box style={getModalStyle()}> */}
      {/* <Box>
        <Typography>Edytuj dzien</Typography>
      </Box> */}
      <DialogContent
        style={{ width: "fit-content", minWidth: "500px" }}
      ></DialogContent>
      <DialogActions>
        <Box display="flex" padding={1} width="100%" flexDirection="column">
          {document ? (
            <>
              <Box>Twoje zamówienie jest gotowe</Box>

              <Box my={1}>
                <strong>{document.number}</strong>
              </Box>

              <Box>
                <Button
                  onClick={() => {
                    startPayingDocument(document)
                  }}
                  variant="contained"
                  color="primary"
                >
                  Opłać w zewnętrznym systemie
                </Button>
              </Box>
            </>
          ) : (
            <>
              {props.product && (
                <LicenseBox product={props.product} mode={props.mode} />
              )}
              <Box flex={1} />
              <Box p={1}>
                {!isSaving && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBuy}
                  >
                    Zamów i opłać
                  </Button>
                )}

                {isSaving && (
                  <>
                    <Box my={1} textAlign="center">
                      <CircularProgress />
                      <Box>Generowanie zamówienia</Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          {/* <Button variant="contained" color="primary" onClick={props.onClose}>
            Zamknij
          </Button> */}
        </Box>
      </DialogActions>
    </Dialog>
  )
}
