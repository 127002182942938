import { Container, Typography } from '@mui/material'
import { PaymentsTable } from 'src/modules/payments/PaymentsTable'

export const AdminPaymentsIndex = (props) => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h5">Płatności (admin)</Typography>
      <PaymentsTable view="admin" />
    </Container>
  )
}
