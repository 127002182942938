import "@draft-js-plugins/image/lib/plugin.css"
import "@draft-js-plugins/inline-toolbar/lib/plugin.css"
import Checkbox from "@mui/material/Checkbox"

import { Box, Button, Grid } from "@mui/material"
import axios from "axios"
import { Formik } from "formik"
import { MojeTextArea } from "src/components/MojeTextArea"

import { MojeDropdown } from "../../components/MojeDropdown"
import { MojeFormField } from "../../components/MojeFormField"
import { MojeTextInput } from "../../components/MojeTextInput"

const categoryTypeOptions = [
  {
    name: "Miejsce",
    value: "PLACE"
  },
  {
    name: "Usługi",
    value: "SERVICE"
  },
  {
    name: "Promo",
    value: "PROMO"
  }
]

export const EditCategoryForm = ({
  item,
  onSaved,
  onCancel
}: {
  item?: any
  onSaved: any
  onCancel: any
}) => {
  console.log("EditCategoryForm", item)

  const doSubmit = async (values: any, actions) => {
    console.log("doSubmit", values, actions)

    try {
      actions.setSubmitting(true)
      var res = await save(values)
      console.log("Saved", res)
      onSaved()
    } catch (err) {
      console.error("Error saving article", err)
    }
    actions.setSubmitting(false)
  }

  const save = async (item) => {
    console.log("location", item)
    let url = `${process.env.REACT_APP_API_URL}/v1/admin/categories/${
      item.id ?? "new"
    }`
    const res = await axios(url, {
      method: "POST",
      data: item
    })
    console.log("Saved", item)
    return res.data
  }

  const initialValues = {
    ...item
  }
  return (
    <Box p={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={doSubmit}
        enableReinitialize
      >
        {(formikProps: any) => {
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>Kod kategorii</Box>
                  <MojeTextInput
                    name="code"
                    id="code"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.code}
                  ></MojeTextInput>
                </Grid>
                <Grid item xs={12}>
                  <Box>Nazwa</Box>
                  <MojeTextInput
                    name="name"
                    id="name"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.name}
                  ></MojeTextInput>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <MojeFormField
                      label="Rodzaj"
                      name="type"
                      formikProps={formikProps}
                      isEdited={true}
                      roComponent={<div>{formikProps.values.type}</div>}
                    >
                      <MojeDropdown options={categoryTypeOptions} />
                    </MojeFormField>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <MojeFormField
                    label="Kategoria Promocyjna"
                    name="isPromo"
                    formikProps={formikProps}
                    isEdited={true}
                    roComponent={() =>
                      formikProps.values.formikProps ? "Tak" : "Nie"
                    }
                    onChange={(event) => {
                      formikProps.setFieldValue(
                        "formikProps",
                        event.target.checked
                      )
                    }}
                  >
                    <Checkbox checked={formikProps.values.isPromo} />
                  </MojeFormField>
                </Grid>

                <Grid item xs={4}>
                  <MojeFormField
                    label="Aktywna"
                    name="isActive"
                    formikProps={formikProps}
                    isEdited={true}
                    roComponent={() =>
                      formikProps.values.formikProps ? "Tak" : "Nie"
                    }
                    onChange={(event) => {
                      formikProps.setFieldValue(
                        "formikProps",
                        event.target.checked
                      )
                    }}
                  >
                    <Checkbox checked={formikProps.values.isActive} />
                  </MojeFormField>
                </Grid>

                <Grid item xs={12}>
                  <Box my={2} display="flex" alignItems="flex-end">
                    <Box>
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={onCancel}
                      >
                        Anuluj
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="contained" color="primary" type="submit">
                        Zapisz
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
