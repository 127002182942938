import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Card, Container, Typography } from '@mui/material'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useAuthContext } from 'src/auth/auth-context'
import { PageContainer } from 'src/components/containers/PageConatiner'
import { MerchantContextType, useMerchantContext } from 'src/contexts/merchant-context'

import { MerchantForm } from '../merchants/MerchantForm'
import { BuyLicensePopup } from '../payments/BuyLicensePopup'
import { PlaceForm } from '../places/PlaceForm'

const OnboardingStep = ({
  title,
  subtitle,
  isCompleted,
  isCurrent,
  onClick,
  enabled,
  children
}: {
  title: string
  subtitle: string
  isCompleted: boolean
  isCurrent: boolean
  onClick: any
  enabled: boolean
  children?: any
}) => {
  return (
    <Box my={2} style={{ opacity: enabled ? 1 : 0.5 }}>
      <Card elevation={2}>
        <Box p={4}>
          <Box my={2} display="flex" flexDirection="row">
            <Box flex={1}>
              <Typography variant="h6">
                <strong>{title}</strong>
              </Typography>
              {!isCompleted && <Typography>{subtitle}</Typography>}
              {isCurrent && (
                <Box my={2}>
                  <Button variant="contained" color="primary" onClick={onClick}>
                    {title}
                  </Button>
                </Box>
              )}
              <Box my={4}>{children}</Box>
            </Box>
            <Box p={4} display="flex" flexDirection="column">
              <Box style={{ borderRadius: 100, background: "#f0f0f0" }}>
                <FontAwesomeIcon
                  size="6x"
                  icon={faCheckCircle}
                  style={{
                    color: isCompleted ? "green" : "white"
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export const OnboardingHome = ({
  onEditCompany,
  onAddPlace,
  merchantContext
}) => {
  const history = useHistory()
  const [showLicense, setShowLicense] = useState(false)
  return (
    <PageContainer>
      <Box>
        <Box mt={4}>
          <Typography variant="h5">
            Witaj w MojeUrodziny. <br />
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="h6">
            Jeszcze tylko kilka minut i możemy zaczynać!
          </Typography>
        </Box>
        <OnboardingStep
          title="Wprowadź dane firmy"
          subtitle="Adres, numer telefonu - czyli standardowe sprawy"
          isCurrent={!merchantContext.onboardingProgress.companyCompleted}
          isCompleted={merchantContext.onboardingProgress.companyCompleted}
          enabled
          onClick={() => {
            onEditCompany()
          }}
        >
          {merchantContext.merchant && (
            <Box>
              <Typography variant="subtitle2">
                <strong>{merchantContext.merchant.name}</strong>
              </Typography>
              <Typography variant="subtitle1">
                <strong>
                  {merchantContext.merchant.address},{" "}
                  {merchantContext.merchant.city}
                </strong>
              </Typography>
            </Box>
          )}
        </OnboardingStep>
        <OnboardingStep
          title="Dodaj miejsce"
          subtitle="Wprowadź informacje o Twojej ofercie - salę zabaw lub usługi"
          isCurrent={
            merchantContext.onboardingProgress.companyCompleted &&
            !merchantContext.onboardingProgress.venueCreated
          }
          isCompleted={merchantContext.onboardingProgress.venueCreated}
          enabled={merchantContext.onboardingProgress.companyCompleted}
          onClick={() => {
            onAddPlace()
          }}
        >
          {merchantContext.venues && merchantContext.venues.length > 0 && (
            <Box>
              <Box>Miejsce dodane:</Box>
              <Typography variant="subtitle2">
                <strong>{merchantContext.venues[0].name}</strong>
              </Typography>
              <Typography variant="subtitle1">
                {/* <strong>
                  {merchantContext.merchant.address},{" "}
                  {merchantContext.merchant.city}
                </strong> */}
              </Typography>
            </Box>
          )}
        </OnboardingStep>

        <OnboardingStep
          title="Aktywuj licencję"
          subtitle="Rozpocznij okres DEMO i zacznij przyjmować gości już dziś"
          isCurrent={
            merchantContext.onboardingProgress.companyCompleted &&
            merchantContext.onboardingProgress.venueCreated
          }
          isCompleted={merchantContext.onboardingProgress.licenseActivated}
          enabled={
            merchantContext.onboardingProgress.companyCompleted &&
            merchantContext.onboardingProgress.venueCreated
          }
          onClick={() => {
            setShowLicense(true)
          }}
        />
        {/* <OnboardingStep
          title="Wprowadź dane firmy"
          merchantContext={merchantContext}
        /> */}
      </Box>

      <BuyLicensePopup
        open={showLicense}
        onClose={() => {
          setShowLicense(false)
        }}
      />
    </PageContainer>
  )
}

export const OnboardingIndex = (props) => {
  const [isEditingCompany, setIsEditingCompany] = useState(false)
  const [isAddingPlace, setIsAddingPlace] = useState(false)
  const authContext = useAuthContext()
  const merchantContext: MerchantContextType = useMerchantContext()
  return (
    <>
      {/* <Route path="/merchants/new" component={NewMerchantPage} /> */}
      {isEditingCompany && (
        <Container>
          <Box my={4}>
            <MerchantForm
              onboardingMode
              isEdited
              adminMode={true}
              onCancel={() => {
                setIsEditingCompany(false)
              }}
              onSaved={(value) => {
                console.log("OnboaringIndex.On merchant saved")
                console.log(value)
                setIsEditingCompany(false)
                //   router.push("/admin/merchants")
              }}
            />
          </Box>
        </Container>
      )}
      {isAddingPlace && (
        <Container>
          <Box my={4}>
            <PlaceForm
              isEdited={true}
              ownerUser={authContext.currentUser}
              onCancel={() => setIsAddingPlace(false)}
              onSaved={() => {
                merchantContext.reloadVenues()
              }}
            />
          </Box>
        </Container>
      )}
      {!isEditingCompany && !isAddingPlace && (
        <OnboardingHome
          merchantContext={merchantContext}
          onEditCompany={() => {
            setIsEditingCompany(true)
          }}
          onAddPlace={() => {
            setIsAddingPlace(true)
          }}
        />
      )}
    </>
  )
}
