import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { useState } from "react"

import { PageHeader } from "../../components/PageHeader"
import { Table, Td, Th } from "../../components/Table"
import { EditPageForm } from "./EditPageForm"

export const Pageslist = ({ items, refetch }: { items: any; refetch: any }) => {
  const [edited, setEditedItem] = useState<any>()

  return (
    <div>
      <PageHeader
        title="Strony specjalne"
        toolsComponents={() => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={(e: any) => {
                  setEditedItem({})
                }}
              >
                {" "}
                Dodaj{" "}
              </Button>
            </>
          )
        }}
      ></PageHeader>

      <Table>
        <thead>
          <tr>
            <Th>Url</Th>
            <Th>Nazwa</Th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item: any) => {
              return (
                <tr key={item.id} onClick={(a: any) => setEditedItem(item)}>
                  <Td>{item.url}</Td>
                  <Td>{item.name}</Td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {edited && (
        <Dialog
          onClose={() => {
            setEditedItem(undefined)
          }}
          aria-labelledby="simple-dialog-title"
          open={edited}
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle id="simple-dialog-title">
            Edytuj stronę specjalna
          </DialogTitle>

          <DialogContent>
            <EditPageForm
              item={edited}
              onSaved={() => {
                refetch()
                setEditedItem(undefined)
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
