import useAxios from "axios-hooks"

export const useLocations = () => {
  var url = `${process.env.REACT_APP_API_URL}/v1/locations`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  console.log("UseLocations", data)
  return {
    locations: data
  }
}
