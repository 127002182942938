import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

import { LoadingPhoto, Photo } from './Photo'
import { UploadableMediaWidget } from './UploadableMediaWidget'

export const SectionMediaStyled = styled.section<any>`
  min-height: 200px;
  background-color: #fafafa;
  padding: 16px;
  cursor: pointer;

  ${({ small }) =>
    small &&
    `
    min-height: 100px !important;
    margin: 12px !important;
    padding: 8px !important;
    .photoWrapper {
      width: 100px !important;
      height: 100px !important;
    }
  `}

  .photoWrapper {
    width: 200px;
    height: 200px;
    margin: 0 8px;
    display: inline-block;
  }

  .photo {
    position: relative;
  }

  .actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const SectionMedia = (props) => {
  const startSavingPhoto = async (newPhoto) => {
    console.log("startSavingPhoto", newPhoto)
    await props.onAddMedia(newPhoto)
    return true
  }

  const doRemovePhoto = async (photo) => {
    console.log("doRemovePhoto", photo)

    props.onRemoveMedia(photo)
  }

  const { media } = props

  return (
    <div>
      <UploadableMediaWidget crop={true} onPhotoUploaded={startSavingPhoto}>
        {({ tmpPhoto, tmpPhotoSrc }) => {
          return (
            <SectionMediaStyled style={{ width: "100%" }} small={props.small}>
              {media &&
                media
                  .filter(
                    (photo: any) =>
                      props.mode != "product" ||
                      photo.productId == props.productId
                  )
                  .map((photo) => {
                    return (
                      <div key={photo.id} className="photoWrapper">
                        <Photo
                          className="photo"
                          photo={photo}
                          width={800}
                          fullHeight
                          actionsRenderer={() => {
                            return (
                              <div className="actionsxx">
                                <div
                                  id={photo.id}
                                  onClick={async (event) => {
                                    console.log("on click remove")
                                    event.stopPropagation()
                                    event.nativeEvent.stopImmediatePropagation()
                                    doRemovePhoto(photo.id)
                                  }}
                                >
                                  <div style={{ width: 24, height: 24 }}>
                                    <FontAwesomeIcon icon={faTrash} size="1x" />
                                  </div>
                                </div>
                              </div>
                            )
                          }}
                        />
                      </div>
                    )
                  })}

              {!media ||
                (media.length === 0 && (
                  <Box
                    display="flex"
                    p={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography fontWeight="medium">
                      To miejsce nie ma jeszcze zdjęć. Kliknij lub upuść aby
                      dodać zdjęcie.
                    </Typography>
                  </Box>
                ))}

              {tmpPhotoSrc && (
                <div className="photoWrapper">
                  <LoadingPhoto
                    loadingProgress={tmpPhoto.percentUploaded}
                    src={tmpPhotoSrc}
                    width={80}
                    fullHeight
                  />
                </div>
              )}
            </SectionMediaStyled>
          )
        }}
      </UploadableMediaWidget>
    </div>
  )
}
