import { Route, Switch } from 'react-router-dom'
import { PageContainer } from 'src/components/containers/PageConatiner'
import { AdminWebsiteSetupPanel } from 'src/modules/admin/website/AdminWebsiteSetupPanel'
import { EditArticlePage } from 'src/modules/articles/EditArticlePage'

import Articles from './articles'
import ArticlePage from './articles/[id]'
import Categories from './categories'
import CouponsIndex from './coupons/CouponsIndex'
import Locations from './locations'
import Merchants from './merchants'
import MerchantIdx from './merchants/[id]'
import NewMerchantPage from './merchants/NewMerchantPage'
import Pages from './pages'
import { AdminPaymentsIndex } from './payments/AdminPaymentsIndex'
import Reservations from './reservations'
import { AdminUsersIndex } from './users/AdminUsersIndex'
import Venues from './venues'
import VenuePageIdx from './venues/[id]'
import AdminNewVenuePage from './venues/AdminNewVenuePage'

export const AdminIndex = (props) => {
  console.log("Admin index")
  return (
    <Switch>
      <Route path="/admin/website" component={AdminWebsiteSetupPanel} />
      <Route path="/admin/articles/new">
        <PageContainer title="Dodaj artykuł">
          <EditArticlePage />
        </PageContainer>
      </Route>
      <Route path="/admin/articles/:id" component={ArticlePage} />
      <Route path="/admin/articles" component={Articles} />
      <Route path="/admin/categories" component={Categories} />
      <Route path="/admin/locations" component={Locations} />
      <Route path="/admin/merchants/new" component={NewMerchantPage} />
      <Route path="/admin/merchants/:id" component={MerchantIdx} />
      <Route path="/admin/merchants" component={Merchants} />
      <Route path="/admin/pages" component={Pages} />
      <Route path="/admin/reservations" component={Reservations} />
      <Route path="/admin/venues/new" component={AdminNewVenuePage} />
      <Route path="/admin/venues/:id">
        <VenuePageIdx adminMode />
      </Route>
      <Route path="/admin/venues" component={Venues} />
      <Route path="/admin/payments" component={AdminPaymentsIndex} />
      <Route path="/admin/users" component={AdminUsersIndex} />
      <Route path="/admin/coupons" component={CouponsIndex} />
      <Route>other</Route>
    </Switch>
  )
}
