import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Card
} from "@mui/material"
import { useState } from "react"
import { MButton } from "src/components/MButton"
import { BuyLicenseForm } from "./BuyLicenseForm"

export const BuyLicensePopup = (props) => {
  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      //   className={classes.root}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">
        Wykup licencje MojeUrodziny.pl
      </DialogTitle>

      <DialogContent style={{ width: "100%" }}>
        <BuyLicenseForm />
      </DialogContent>
      <DialogActions>
        <Box display="flex" padding={2} width="100%">
          <Button variant="contained" color="primary" onClick={props.onClose}>
            Zamknij
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
