import { Auth } from 'aws-amplify'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useMerchantContext } from '../contexts/merchant-context'

export interface WsContextType {
  isConnected: boolean
  reconnect: Function
}

export const WsContext = createContext<WsContextType>({
  isConnected: false,
  reconnect: () => {}
})

export const WsContextProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false)
  const [ws, setWs] = useState<undefined | WebSocket>()
  const merchantContext = useMerchantContext()

  const reconnect = useCallback(async () => {
    console.log("WsContext.reconnect", merchantContext.merchant)
    if (!process.env.REACT_APP_WS_URL) {
      console.error("no REACT_APP_WS_URL")
      return
    }
    console.log("MerchantContext", merchantContext.merchant)
    if (!merchantContext.merchant) {
      console.error("No current merchant")
      return
    }
    if (ws) {
      ws.close()
    }
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()
      console.log("token", token)
      var url = process.env.REACT_APP_WS_URL
      // url = url.replace("://", "://" + token + "@")
      // console.log("url", url)
      const _ws = new WebSocket(url)
      _ws.onopen = (event) => {
        console.log("ws on open", event)
        console.log("send, ", _ws)
        setIsConnected(true)
        _ws.send(
          JSON.stringify({
            messageType: "command",
            command: {
              type: "Authenticate",
              payload: {
                token,
                clientType: "admin",
                merchant: merchantContext.merchant
              }
            }
          })
        )
      }
      _ws.onerror = (event) => {
        console.log("ws on onerror", event)
        setIsConnected(false)
      }
      _ws.onmessage = (event) => {
        console.log("ws on message", event)
        if (event.data) {
          const body = JSON.parse(event.data)
          console.log("WS Message, ", body)
        }
      }
      _ws.onclose = () => {
        console.log("Ws on close")
        setIsConnected(false)
      }
      setWs(_ws)
    } catch (err) {
      console.error("Not authenticated")
    }
  }, [ws, merchantContext.merchant])

  // useEffect(() => {
  //   reconnect()
  // }, [reconnect])

  useEffect(() => {
    console.log("WsContext, useEffect merchant, ", merchantContext.merchant?.id)
    reconnect()
  }, [merchantContext.merchant?.id])

  const value = {
    isConnected,
    reconnect
  }
  // console.log("WsContext", value, merchantContext.merchant)
  return <WsContext.Provider value={value}>{children}</WsContext.Provider>
}

export const useWsContext = (): WsContextType =>
  useContext(WsContext) as WsContextType
