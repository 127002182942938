import React from 'react'

import { CalendarWidget } from './CalendarWidget'

export const PlaceSectionCalendar = (props) => {
  return (
    <div>
      <CalendarWidget venue={props.venue} refetch={props.refetch} />
    </div>
  )
}
