import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

import { LoadingPhoto, Photo } from "./media/Photo"
import { UploadableMediaWidget } from "./media/UploadableMediaWidget"

const SingleImageSelectorStyled = styled.div`
  width: 100px;
  height: 100px;
  .PhotoWrapper {
    width: 100px;
    height: 100px;
  }
`
export const SingleImageSelector = ({ media, disabled, onSaveLogo }) => {
  return (
    <SingleImageSelectorStyled>
      <UploadableMediaWidget onPhotoUploaded={onSaveLogo}>
        {({ tmpPhoto, tmpPhotoSrc }) => {
          return (
            <div className="PhotoWrapper">
              {media && (
                <Photo
                  className="photo"
                  photo={media}
                  width={100}
                  fullHeight
                  actionsRenderer={() => {
                    return (
                      <div className="actions">
                        <div
                          id={media.id}
                          onClick={async (event) => {
                            console.log("on click remove")
                            event.stopPropagation()
                            event.nativeEvent.stopImmediatePropagation()
                            //   doRemovePhoto(photo.id)
                          }}
                        >
                          <div style={{ width: 24, height: 24 }}>
                            <FontAwesomeIcon icon={faTrash} size="1x" />
                          </div>
                        </div>
                      </div>
                    )
                  }}
                />
              )}
              {!media && <>Kliknij aby dodać logo</>}
              {tmpPhotoSrc && (
                <div>
                  <LoadingPhoto
                    loadingProgress={tmpPhoto.percentUploaded}
                    src={tmpPhotoSrc}
                    width={80}
                    fullHeight
                  />
                </div>
              )}
            </div>
          )
        }}
      </UploadableMediaWidget>
    </SingleImageSelectorStyled>
  )
}
