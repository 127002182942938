import { FormattedDate, FormattedTime } from 'react-intl'
import { BusinessPaginatedTable } from 'src/components/BusinessPaginatedTable'
import { useMerchantContext } from 'src/contexts/merchant-context'

export const ReservationsTable = ({
  adminMode,
  prefix
}: {
  adminMode?: boolean
  prefix?: string
}) => {
  const merchantContext = useMerchantContext()

  const columns = [
    {
      header: "Status",
      accessor: "status",
      Cell: (row) => <div>{row.status_label}</div>
    },
    {
      header: "Klient",
      accessor: "user",
      Cell: (row) => <div>{row.user?.name}</div>
    },
    {
      header: "Data",
      accessor: "reservation_date",
      Cell: (row) => <div>{row.reservationDate}</div>
    },
    {
      header: "Otrzymano",
      Cell: (row) => (
        <div>
          {" "}
          <>
            <FormattedDate value={row.createdAt} />{" "}
            <FormattedTime value={row.createdAt} />
          </>
        </div>
      )
    },
    {
      header: "Wartość",
      Cell: (row) => <div>{row.finalConditions?.totalPrice}</div>
    }
  ]
  const apiUrl = `/v1/reservations`

  if (!adminMode && !merchantContext.merchant) {
    return <div>Wybierz firmę</div>
  }
  const queryParams = adminMode
    ? {}
    : {
        merchant_id: merchantContext.merchant?.id
      }
  return (
    <BusinessPaginatedTable
      columns={columns}
      apiUrl={apiUrl}
      rowLinkFn={(row) => `/reservations/${row.id}`}
      queryParams={queryParams}
    />

    // <Td>
    //   <a href={`${prefix}/${r.id}`} target="_blank">
    //     <Button variant="outlined" color="primary">
    //       Szczegóły
    //     </Button>
    //   </a>
    // </Td>
  )
}
