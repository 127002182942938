import { Box } from '@mui/material'
import { FormattedDate, FormattedTime } from 'react-intl'
import { BusinessPaginatedTable } from 'src/components/BusinessPaginatedTable'

import { AdminUserUtils } from './AdminUserUtils'

// import { CouponUtils } from "./CouponUtils"

export const AdminUsersTable = (props) => {
  const columns = [
    {
      accessor: "email",
      header: "Email",
      Cell: (row) => {
        const emailConfirmed =
          AdminUserUtils.getUserAttribute(row, "email_verified") === "true"
        return (
          <div
            style={{
              color: emailConfirmed ? "green" : "black"
            }}
          >
            {AdminUserUtils.getUserAttribute(row, "email")}
          </div>
        )
      }
    },
    {
      header: "Dodany",
      accessor: "UserCreateDate",
      Cell: (row) => {
        return (
          <Box textAlign="left">
            <FormattedDate value={row.UserCreateDate} />{" "}
            <FormattedTime value={row.UserCreateDate} />
          </Box>
        )
      }
    },
    {
      header: "Kod",
      accessor: "code",
      Cell: (row) => {
        return <Box textAlign="left">{row.code}</Box>
      }
    },
    {
      header: "Status",
      accessor: "status",
      Cell: (row) => {
        return (
          <Box>u</Box>
          //   <Box textAlign="right">{CouponUtils.statusLabel(row.status)}</Box>
        )
      }
    }
  ]

  return (
    <Box>
      <BusinessPaginatedTable
        columns={columns}
        apiUrl={`/v1/super/users`}
        onRowClick={props.onRowClick}
        //   rowLinkFn={(article) => `/admin/articles/${article.id}`}
      />
    </Box>
  )
}
