import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

export interface PlacePageContextType {
  onAddMedia: Function
  onRemoveMedia: Function
  venue?: any
  isSaving?: boolean
}

export const PlacePageContext = createContext<PlacePageContextType>({
  onAddMedia: () => {},
  onRemoveMedia: () => {},
  isSaving: false
})

export const PlacePageContextProvider = ({
  children,
  onAddMedia,
  onRemoveMedia,
  venue,
  isSaving
}) => {
  var contextValue = {
    onAddMedia,
    onRemoveMedia,
    venue,
    isSaving
  }
  return (
    <PlacePageContext.Provider value={contextValue}>
      {children}
    </PlacePageContext.Provider>
  )
}

export const usePlacePageContext = (): PlacePageContextType =>
  useContext(PlacePageContext) as PlacePageContextType
