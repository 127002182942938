export class SuperAdminUtils {
  static listRoutes = () => {
    return [
      {
        label: "Ustawienia strony",
        path: "/admin/website"
      },
      {
        label: "Firmy",
        path: "/admin/merchants"
      },
      {
        label: "Miejsca",
        path: "/admin/venues"
      },
      {
        label: "Rezerwacje",
        path: "/admin/reservations"
      },

      {
        label: "Blog",
        path: "/admin/articles"
      },
      {
        label: "Miasta",
        path: "/admin/locations"
      },
      {
        label: "Kategorie",
        path: "/admin/categories"
      },
      {
        label: "Strony",
        path: "/admin/pages"
      },
      {
        label: "Płatności",
        path: "/admin/payments"
      },
      {
        label: "Uzytkownicy",
        path: "/admin/users"
      },
      {
        label: "Kupony",
        path: "/admin/coupons"
      }
    ]
  }
}
