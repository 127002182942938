import { Box } from "@mui/material"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { PageContainer } from "src/components/containers/PageConatiner"
import { VenuesTable } from "src/modules/places/VenuesTable"

import { MButton } from "../../../components/MButton"

const AdminPlacesIndex = (props) => {
  const [showDeleted, setShowDeleted] = useState<any>(false)

  const queryParams: any = {}

  if (showDeleted) {
    queryParams.showDeleted = true
  }
  console.log("admin places ", queryParams)

  // const { venues, loading } = useAdminVenues(  if ()
  const router = useHistory()
  return (
    <PageContainer
      title="Wszystkie Miejsca"
      toolsComponents={() => {
        return (
          <Box display="flex" flexDirection="column">
            <Box textAlign="right">
              <MButton
                variant="contained"
                color="primary"
                primary
                onClick={() => router.push("/admin/venues/new")}
              >
                Dodaj Miejsce
              </MButton>
            </Box>
          </Box>
        )
      }}
    >
      <VenuesTable queryParams={queryParams} />
    </PageContainer>
  )
}

export default AdminPlacesIndex
