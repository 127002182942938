import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import { MojeCard } from "src/components/card/MojeCard"
import { MButton } from "src/components/MButton"
import { MojeFormField } from "src/components/MojeFormField"
import { ToastrType, useToastr } from "src/components/MojeToastr"

import { useMerchantApi } from "./api/use_merchant_api"
import { LicenseUtils } from "./LicenseUtils"

export const MerchantCardLicense = ({
  merchant,
  onSaved
}: {
  onSaved: any
  merchant: any
}) => {
  const showToastr = useToastr()
  const { runCommand, isSaving } = useMerchantApi()

  const handleActivate = () => {
    const onYes = async () => {
      const command = {
        type: "Merchant.ActivateLicense",
        aggregateId: merchant.id,
        aggregateType: "Merchant",
        payload: {}
      }
      const res: any = await runCommand(command)
      if (res && res.success) {
        showToastr("Licencja zostala aktywowana")
        onSaved()
        // TODO - show toastr success, redirect to /merchants/:id
      } else {
        showToastr("Problem")
      }
    }
    console.log("handleActivate")
    showToastr("Aktywuj licencje!", {
      message: "Czy na pewno chcesz aktywowac licencje?",
      onYes: onYes,
      type: ToastrType.CONFIRM
    })
  }

  const handleSuspend = () => {
    const onYes = async () => {
      const command = {
        type: "Merchant.SuspendLicense",
        aggregateId: merchant.id,
        aggregateType: "Merchant",
        payload: {}
      }
      const res: any = await runCommand(command)
      if (res && res.success) {
        showToastr("Licencja została zawieszona")
        onSaved()
        // TODO - show toastr success, redirect to /merchants/:id
      } else {
        showToastr("Problem")
      }
    }
    console.log("handleSuspend")
    showToastr("Zawiesić licencję!", {
      message: "Czy na pewno chcesz zawiesić licencję?",
      onYes: onYes,
      type: ToastrType.CONFIRM
    })
  }
  console.log("Render Merchant Card License", merchant)
  return (
    <Box>
      <MojeCard title="Licencja">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MojeFormField label="Pakiet">
              {LicenseUtils.licensePackageName(
                merchant?.licensePackageCode ?? "-"
              )}
            </MojeFormField>
          </Grid>
          <Grid item xs={4}>
            <MojeFormField label="Status">
              {LicenseUtils.licenseStatusLabel(merchant?.licenseStatus ?? "-")}
            </MojeFormField>
          </Grid>
          <Grid item xs={4}>
            <MojeFormField label="Licencja ważna do">
              {merchant?.licenseValidTill ?? "-"}
            </MojeFormField>
          </Grid>
          <Grid item xs={3}>
            {merchant?.license !== "ACTIVE" && (
              <MButton
                variant="contained"
                color="primary"
                onClick={handleActivate}
                label="Aktywuj Licencje (Admin)"
                isSaving={isSaving}
                grouped
              />
            )}

            {merchant?.licenseStatus === "ACTIVE" && (
              <MButton
                variant="contained"
                color="primary"
                onClick={handleSuspend}
                label="Zawieś licencję (Admin)"
                isSaving={isSaving}
                grouped
              />
            )}
          </Grid>
        </Grid>
      </MojeCard>
    </Box>
  )
}
