import React, { useEffect, useState } from "react"
import styled from "styled-components"
import uuidv4 from "uuid"

import { MButton } from "../../../components/MButton"
import { MojeTextArea } from "../../../components/MojeTextArea"
import { useToastr } from "../../../components/MojeToastr"
import { useMessagesApi, useOwnerMessages } from "../useMessagesApi"
import { MessageItem } from "./MessageItem"

const ChatWindowStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #ddd;

  .header {
    padding: 16px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
  }
  .messages {
    flex: 1;
    padding: 8px;
    overflow-y: auto;
  }
  .actionsBar {
    display: flex;
    border-top: 1px solid #fefefe;
    background-color: #f0f0f0;
    border-radius: 6px;
    padding: 8px;
  }
`

export const ChatWidget = ({
  merchant,
  user,
  reservationId
}: {
  merchant: any
  user: any
  reservationId?: string
}) => {
  const [nextMessage, setNextMessage] = useState(undefined)
  const [nextMessageId, setNextMessgeId] = useState(uuidv4())
  const { data, loading, refetch, readMessage } = useOwnerMessages({
    userId: user?.id
  })

  const showToastr = useToastr()
  const { runCommand, isSaving } = useMessagesApi()

  useEffect(() => {
    console.log("markMessagesAsRead", data)
    if (data && data.messages) {
      for (var i = 0; i < data.messages.length; i++) {
        console.log("direction", data.messages[i].direction === "FROM_USER")
        if (
          !data.messages[i].readAt &&
          data.messages[i].direction === "FROM_USER"
        ) {
          readMessage(data.messages[i])
        }
      }
    }
  }, [data])

  const handleSendMessage = async () => {
    console.log("hanel send")
    const command = {
      type: "Message.Send",
      aggregateId: nextMessageId,
      payload: {
        contentText: nextMessage,
        merchant,
        user,
        direction: "TO_USER",
        reservationId
      }
    }
    try {
      var res: any = await runCommand(command)
      if (res && res.success) {
        showToastr("Wiadomość wysłana")
        setNextMessgeId(uuidv4())
        setNextMessage(undefined)
        refetch()
      }
    } catch (er) {
      showToastr("Nie udało się wysłać wiadomości")
    }
  }
  return (
    <ChatWindowStyled>
      <div className="header">Wiadomości z {user?.name}</div>
      <div className="messages">
        {data &&
          data.messages &&
          data.messages.map((message) => {
            return <MessageItem message={message} key={message.id} />
          })}
      </div>

      <div className="actionsBar">
        <div style={{ flex: 1, marginRight: 8 }}>
          <MojeTextArea
            value={nextMessage}
            onChange={(event) => setNextMessage(event.currentTarget.value)}
          />
        </div>
        <div>
          <MButton
            primary
            small
            label="Wyślij"
            onClick={handleSendMessage}
            isSaving={isSaving}
          />
        </div>
      </div>
    </ChatWindowStyled>
  )
}
