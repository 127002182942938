import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

// import LoadingButton from '@mui/lab/LoadingButton'
// const StyledButton = styled.button<any>`
//   border-radius: 4px;
//   transition: background 120ms ease-in, opacity 120ms ease-in;
//   font-weight: 600;
//   text-align: center;
//   vertical-align: middle;
//   touch-action: manipulation;
//   cursor: pointer;
//   background-image: none;
//   border: 1px solid #dadce0;
//   white-space: nowrap;
//   font-size: 14px;
//   opacity: 1;
//   padding: 9px 14px 10px 12px;
//   letter-spacing: 0.5px;
//   background: none;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   position: relative;

//   transition: background 150ms ease-in-out;
//   transition: background-color 150ms ease-in;
//   user-select: none;

//   :focus {
//     outline: none;
//   }

//   &:hover {
//     background: #dadce080;
//   }

//   .LoadingWrapper {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   ${({ primary, submit }) =>
//     (primary || submit) &&
//     `
//     background: linear-gradient(to left, #fc4c33, #fc792a);
//     color: white;
//     border: none;
//     &:hover {
//         background: linear-gradient(to left, #c61b03, #bd4903);
//       }
//     `}

//   ${({ secondary }) =>
//     secondary &&
//     `
//     border: 0px;
//     color: #667;

//     :hover {
//       background: #ddd;
//     }
//     `}

//     ${({ small }) =>
//     small &&
//     `
//     font-size: 0.8em;
//     font-weight: bold;
//     padding: 4px 8px;
//     line-height: 1em;
//   `}

//     ${({ large }) =>
//     large &&
//     `
//       font-size: 1.4em;
//       font-weight: bold;
//       padding: 12px 24px;
//       line-height: 1.4em;
//     `}

//     ${({ grouped }) =>
//     grouped &&
//     `
//       margin-left: 6px;
//       margin-right: 6px;
//     `}

//   ${({ textButton }) =>
//     textButton &&
//     `
//     border: 0;
//     background: initial;
//     color: #333;
//     font-weight: 600;
//     transition: background 150ms ease-in;

//     &:hover {
//       background: rgba(50, 50, 100, 0.1);
//     }
//     `}

//     ${({ disabled }) =>
//     disabled &&
//     `
//      opacity: 0.8;
//   `}
//   ${({ fullWidth }) =>
//     fullWidth &&
//     `
//   width: 100% !important;`}
// `

// type ButtonProps = {
//   primary?: boolean
//   secondary?: boolean
//   label?: string
//   onClick: Function
//   isSaving?: boolean
//   submit?: boolean
//   width?: any
//   style?: any
//   icon?: any
//   grouped?: boolean
//   disabled?: boolean
//   small?: boolean
//   iconButton?: boolean
//   className?: string
//   linkTo?: string
//   fullWidth?: boolean
// }

// const StyledMaterialButton = withStyles((theme) => ({
//   root: {
//     boxShadow: "none",
//     textTransform: "none",
//     fontSize: 16,
//     padding: "6px 12px",
//     border: "1px solid",
//     lineHeight: 1.5,
//     // backgroundColor: "#0063cc",
//     background: "linear-gradient(to left, #fc4c33, #fc792a)",
//     // borderColor: "#0063cc",
//     color: "white",
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"'
//     ].join(","),
//     "&:hover": {
//       backgroundColor: "#0069d9",
//       borderColor: "#0062cc",
//       boxShadow: "none"
//     },
//     "&:active": {
//       boxShadow: "none",
//       backgroundColor: "#0062cc",
//       borderColor: "#005cbf"
//     },
//     "&:focus": {
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
//     }
//   }
// }))(Button)

export const MButton: React.FunctionComponent<any> = (props) => {
  const inner = (
    <Button
      {...props}
      // width={props.width}
      type={
        props.submit || props.type === "submit"
          ? "submit"
          : props.reset
          ? "reset"
          : "button"
      }
      style={props.style}
      // variant={props.primary ? "contained" : props.text ? "text" : "outlined"}
      color={
        props.color ? props.color : props.primary ? "primary" : "secondary"
      }
      // loading={props.isSaving}
      boxShadow="none"
    >
      {props.icon && (
        <FontAwesomeIcon
          icon={props.icon}
          size="2x"
          style={{ marginRight: 5, height: 20 }}
        />
      )}
      {props.children ? props.children : ""}
      {props.label ? (
        <span
          style={{
            opacity: props.isSaving ? 0 : 1
          }}
        >
          {props.label}
        </span>
      ) : (
        ""
      )}
      {/* {props.isSaving && (
        <Box height={24} width={24}>
          <FontAwesomeIcon
            style={{ height: "80%" }}
            icon={faSpinner}
            spin
            size="1x"
            className="fa-spin"
          />
        </Box>
      )} */}
    </Button>
  )

  return props.linkTo ? <Link to={props.linkTo}>{inner}</Link> : inner
}
