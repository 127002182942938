import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, MenuItem, Select } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useReducer, useState } from 'react'

import { MButton } from '../MButton'
import { MojeFormFieldLabel } from '../MojeFormField'
import { MojeTextInput } from '../MojeTextInput'
import AddressMap from './address-map'

type AddressFormState = {
  isCheckingPostCode: boolean
  postCodeSearchResults?: Array<any>
  citiesReduced?: Array<any>
}

const reducer = (state: AddressFormState, action) => {
  console.log("reducer", state, action)
  switch (action.type) {
    case "postCodeSearchStarted":
      return {
        ...state,
        isCheckingPostCode: true
      }
    case "postCodeSearchResult":
      return {
        ...state,
        isCheckingPostCode: false,
        searchedPostCode: action.payload.postCode,
        postCodeSearchResults: action.payload.results,
        citiesReduced: action.payload.results
          ? Object.keys(
              action.payload.results.reduce(
                (a, i) => ({ ...a, [i.city]: i.city }),
                {}
              )
            )
          : []
      }
    case "postCodeSearchError": {
      return {
        ...state,
        isCheckingPostCode: false,
        citiesReduced: []
      }
    }
    default:
      throw new Error()
  }
}
function AddressForm({
  values,
  onChange,
  onBlur,
  isEdited,
  onMarkerMoved,
  setFieldValue,
  showMap
}) {
  const [isShowingCoordinates, setIsShowingCoordinates] = useState(false)
  const [postCode, setpostCode] = useState(values.postCode)
  const [state, dispatch] = useReducer(reducer, {
    isCheckingPostCode: false
  })
  const [isGeocoding, setIsGeocoding] = useState(false)

  const isPostCodeValid = postCode && /\d{2}-\d{3}/.test(postCode)
  function handleGeocode() {
    var w: any = window
    if (w.google && w.google.maps && w.google.maps.Geocoder) {
      const geocoder = new w.google.maps.Geocoder()
      let address = values.city
      if (values.address) {
        address += ", " + values.address
      }
      setIsGeocoding(true)
      geocoder.geocode({ address }, (results, status) => {
        setIsGeocoding(false)
        if (status === "OK") {
          if (results && results.length > 0) {
            if (results[0].geometry && results[0].geometry.location) {
              setFieldValue("lat", results[0].geometry.location.lat())
              setFieldValue("lng", results[0].geometry.location.lng())
            }
          }
        }
      })
    }
  }

  function handleFieldsBlur(e) {
    if (values.city && values.address) {
      handleGeocode()
    } else {
      setFieldValue("lat", null)
      setFieldValue("lng", null)
    }
    if (onBlur) onBlur(e)
  }

  const setPostCodeDebounced = debounce((val) => {
    console.log("setPostCodeDebounced", val)
    setpostCode(val)
  }, 500)

  const loadPostCodeOptions = useCallback(async () => {
    console.log("loadPostCodeOptions", postCode)
    const url = `${process.env.REACT_APP_API_URL}/v1/postcode?postcode=${postCode}`
    dispatch({ type: "postCodeSearchStarted", payload: { postCode } })
    var res = await axios.get(url)
    if (res.status === 200) {
      console.log("Got postcode", res.data)
      dispatch({
        type: "postCodeSearchResult",
        payload: { postCode, results: res.data }
      })
    } else {
      console.error("Error checking post code")
      dispatch({ type: "postCodeSearchError", payload: { postCode } })
    }
  }, [postCode])

  useEffect(() => {
    console.log("post code changed", postCode)
    if (/\d{2}-\d{3}/.test(postCode)) {
      loadPostCodeOptions()
    }
  }, [postCode, loadPostCodeOptions])

  const onPostCodeChanged = async (event) => {
    setPostCodeDebounced(event.target.value)
    onChange(event)
  }

  const localizeDebounced = debounce(() => {
    console.log("localizeDebounced")
    handleGeocode()
  }, 500)

  useEffect(() => {
    localizeDebounced()
  }, [values.address, values.city])

  useEffect(() => {
    console.log("citiesReduced changed", state.citiesReduced)
    if (state.citiesReduced && state.citiesReduced.length === 1) {
      console.log("changing city to", state.citiesReduced.length)
      if (values.city !== state.citiesReduced[0]) {
        setFieldValue("city", state.citiesReduced[0])
      }
    } else if (state.citiesReduced && state.citiesReduced.length > 1) {
      // Reset city - force selection
      setFieldValue("city", "")
    }
  }, [state.citiesReduced])

  // console.log("AddressInput, state", state)
  // console.log("citiesReduced", state.citiesReduced)
  // console.log("isPostCodeValid", isPostCodeValid)
  return (
    <Grid container spacing={2}>
      {isEdited ? (
        <>
          <Grid item xs={2}>
            <Box display="flex" height="100%" alignItems="center">
              <Box flex={1} mr={1}>
                <MojeFormFieldLabel>Kod Pocztowy</MojeFormFieldLabel>
                <MojeTextInput
                  placeholder="Kod pocztowy"
                  name="postCode"
                  onChange={onPostCodeChanged}
                  required
                  error={!isPostCodeValid}
                  valid={isPostCodeValid}
                />
              </Box>
              <Box visibility={state.isCheckingPostCode ? "visible" : "hidden"}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <MojeFormFieldLabel>Miasto</MojeFormFieldLabel>
            <Select
              name="city"
              id="city"
              onChange={onChange}
              value={values.city}
              fullWidth
            >
              <MenuItem value="Wybierz miasto" key={""}></MenuItem>
              {state.citiesReduced &&
                state.citiesReduced.map((pc: any) => (
                  <MenuItem value={pc} key={pc}>
                    {pc}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          {/* <MojeTextInput
              placeholder="Miasto"
              name="city"
              value={values.city}
              onChange={onChange}
              onBlur={handleFieldsBlur}
            /> */}
          {/* <div style={{ marginRight: 16, flex: 1 }}>
            <MojeTextInput
              placeholder="Miasto"
              name="city"
              value={values.city}
              onChange={onChange}
              onBlur={handleFieldsBlur}
            />
          </div> */}
          <Grid item xs={6}>
            <MojeFormFieldLabel>Ulica i numer</MojeFormFieldLabel>
            <MojeTextInput
              placeholder="Ulica"
              name="address"
              value={values.address}
              onChange={onChange}
              onBlur={handleFieldsBlur}
            />
          </Grid>
          {showMap && (
            <Grid item xs={4}>
              {isGeocoding ? (
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyItems="center"
                >
                  <Box mr={1}>Sprawdzam lokalizację...</Box>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </Box>
              ) : (
                <MButton label="Lokalizuj" onClick={handleGeocode} />
              )}
            </Grid>
          )}
          {/* {!isShowingCoordinates && (
            <div>
              <MButton
                secondary
                onClick={() => setIsShowingCoordinates((value) => !value)}
                label="Pokaż współrzędne"
              />
            </div>
          )}*/}
        </>
      ) : (
        <>
          <div>
            {values.city}
            {","} {values.address}
          </div>
        </>
      )}
      {isShowingCoordinates && (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 8 }}>
          <div style={{ marginRight: 16 }}>
            <MojeTextInput
              placeholder="Lat"
              name="lat"
              value={values.lat}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
          <div style={{ marginRight: 16 }}>
            <MojeTextInput
              placeholder="Lng"
              name="lng"
              value={values.lng}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        </div>
      )}
      {showMap && (
        <>
          <Grid item xs={12}>
            Przesuń pinezkę na mapie aby wskazać dokładnie Twoją lokalizację
          </Grid>
          <Grid item xs={12}>
            <AddressMap
              location={{
                lat: values.lat,
                lng: values.lng
              }}
              name={values.name}
              onMarkerMoved={onMarkerMoved}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default AddressForm
