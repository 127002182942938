import { Button } from "@mui/material"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"

import { MojeCard } from "../../../../components/card/MojeCard"
import { MButton } from "../../../../components/MButton"
import { MojeFormField } from "../../../../components/MojeFormField"
import { MojeInfoBar } from "../../../../components/MojeInfoBar"
import { MojeTextInput } from "../../../../components/MojeTextInput"
import { ToastrType, useToastr } from "../../../../components/MojeToastr"
import { usePlaceApi } from "../../api/use_place_api"
import { VenueProductsList } from "../VenueProductsList"
import { ProductCard } from "./ProductCard"

interface PricingFormProps {
  onRefetch?: Function
  venue: any
  venueId?: string
  refetch?: Function
}

export const PlaceSectionProducts: React.FC<PricingFormProps> = (props) => {
  const [isEditingAdOn, setIsEditingAdOn] = useState(false)
  const [isEditingProducts, setIsEditingProducts] = useState(false)

  const [tmpProducts, setTmpProducts] = useState(
    props.venue.products && Array.isArray(props.venue.products)
      ? [...props.venue.products]
      : []
  )
  const showToastr = useToastr()
  const { runCommand, isSaving } = usePlaceApi()
  const [editedProductId, setEditedProductId] = React.useState(undefined)
  const [isAdding, setIsAdding] = React.useState(false)
  // const [productPriceData, setProductPriceData]: [any, Function] = useState([])

  const [productList, setProducts]: [any, Function] = useState([])

  console.log("PricingFormProps render", props)
  React.useEffect(() => {
    console.log("Props venue changed")
    setTmpProducts(
      props.venue.products && Array.isArray(props.venue.products)
        ? [...props.venue.products]
        : []
    )
  }, [props.venue])
  const setSavedPrice = (savedPrice) => {
    console.log("savedPrice", savedPrice)
    const day = { dow: 1, dayTitle: "Monday", price: 0, adOnPrice: 0 }
    const data: Array<any> = []
    for (let i = 0; i < 7; i++) {
      if (venue.products && venue.products.length > i) {
        day.dow = venue.products[i].dow
        day.price = venue.products[i].price
        day.adOnPrice = venue.products[i].adOnPrice
        console.log("Day0, da", day)
      }
      data.push(day)
    }
    return data
  }
  useEffect(() => {
    const data: any = setSavedPrice(props.venue.products)
    // setProductPriceData(data)
  }, [props.venue])

  const handleSaved = () => {
    // setIsEditingProductProduct(false)
    props.onRefetch()
  }
  const handleSave = async () => {
    console.log("handleSave", tmpProducts)
    // const productData = productPriceData.map((oh: any) => {
    //   return {
    //     dayOfWeek: oh.dow,
    //     price: oh.price,
    //     adOnPrice: oh.adOnPrice,
    //     isOpen: oh.isOpen
    //   }
    // })
    const command = {
      aggregateId: props.venue.id,
      type: "Venue.UpdateProducts",
      payload: {
        products: tmpProducts
      }
    }
    try {
      const res: any = await runCommand(command)
      console.log("Update reslt ", res)
      if (res && res.success) {
        showToastr("Scenariusze i cennik zostały zapisane", {
          message: "Super!"
        })
        setIsEditingProducts(false)
        props.refetch()
      }
    } catch (error) {
      showToastr("Problem z zapisaniem produktow", { type: ToastrType.ERROR })
      // toastr.error("Can't save Opening Hours")
    }
  }

  const handleStartProductEdit = () => {
    setIsEditingProducts(true)
    setTmpProducts(
      props.venue.products && Array.isArray(props.venue.products)
        ? [...props.venue.products]
        : []
    )

    setIsEditingAdOn(false)
  }
  const handleStartAdonEdit = () => {
    setIsEditingAdOn(true)
    setIsEditingProducts(false)
  }
  const handleProductSaved = () => {
    console.log("handleProductSaved")
    props.refetch()
    setEditedProductId(undefined)
    setIsAdding(false)
  }

  const handleRemoveProduct = (product) => {
    console.log("handleRemoveProduct", product)
    showToastr(
      "Produkt zostanie zarchiwizowany i nie będzie widoczny dla klientów. Czy na pewno?",
      {
        onYes: async () => {
          const command = {
            aggregateId: props.venue.id,
            type: "Venue.ArchiveProduct",
            payload: {
              id: product.id
            }
          }
          try {
            const res: any = await runCommand(command)
            console.log("Update reslt ", res)
            if (res && res.success) {
              showToastr("Produkt zarchiwizowany", {
                message: "Super!"
              })
              setIsEditingProducts(false)
              setEditedProductId(undefined)
              props.refetch()
            }
          } catch (error) {
            showToastr("Problem z zapisaniem produktu", {
              type: ToastrType.ERROR
            })
          }
        },
        type: ToastrType.CONFIRM
      }
    )
  }
  const handleChange = (dow, delta) => {
    console.log("handleChange0 ,dow", dow, delta)
    // const newData: any = [...productPriceData]
    // newData[dow] = { ...newData[dow], ...delta }
    // setProductPriceData(newData)
  }

  const handleUpdateProduct = (idx, data) => {
    console.log("handleUpdateProduct", idx, data)
    const newProducts = [...tmpProducts]
    newProducts[idx] = data
    setTmpProducts(newProducts)
  }

  // const handleRemoveProduct = (idx) => {
  //   const newProducts = [...tmpProducts]
  //   newProducts.splice(idx, 1)
  //   setTmpProducts(newProducts)
  // }
  const renderEditProducts = () => {
    const days: any = []

    console.log("Render edit products", tmpProducts)
    return (
      <>
        {tmpProducts && Array.isArray(tmpProducts) ? (
          tmpProducts.map((product: any, idx) => {
            return (
              <div className="row" style={{ marginBottom: 8 }}>
                <ProductCard
                  product={product}
                  venue={venue}
                  onRemove={handleRemoveProduct}
                  onSaved={handleProductSaved}
                  onStartEditing={() => {
                    setEditedProductId(product.id)
                  }}
                  onCancelEditing={() => {
                    setEditedProductId(undefined)
                  }}
                  isEditing={product.id && editedProductId == product.id}
                />
              </div>
            )
          })
        ) : (
          <div> No products </div>
        )}

        <div
          style={{
            margin: "16px 0",
            textAlign: "right",
            padding: "0 8px",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div style={{ flex: 1 }} />
          <Button
            title="Anuluj"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setIsEditingProducts(false)
            }}
          />
          <MButton
            label="Zapisz"
            primary
            grouped
            onClick={handleSave}
            isSaving={isSaving}
          />
        </div>
      </>
    )
  }

  const renderNoProduct = () => {
    const noProducts = !products || products.length === 0
    return (
      <div>
        {/* <div style={{ margin: "20px 0" }}>
          {!products ||
            (products.length === 0 && <div>No AdOn pricings are defined</div>)}
          <MButton
            primary={noProducts}
            secondary={!noProducts}
            label="Set AdOn Price"
            onClick={handleStartAdonEdit}
          />
        </div> */}
      </div>
    )
  }

  // const productForm = () => {
  //   const validatoinSchema: any = Yup.object({
  //     price: Yup.number().positive("Please enter valid number").required(),
  //     adOnPrice: Yup.number().positive("Please enter valid number").required(),
  //   })

  //   const weekDays: any = [
  //     { value: 1, name: "Monday" },
  //     { value: 2, name: "Tuesday" },
  //     { value: 3, name: "Wednesday" },
  //     { value: 4, name: "Thursday" },
  //     { value: 5, name: "Friday" },
  //     { value: 6, name: "Saturday" },
  //     { value: 7, name: "Sunday" },
  //   ]

  //   const addProductToList = ({dow, price, adOnPrice}) => {
  //     const newList = [...productList, { dow, price, adOnPrice }];
  //     console.log(newList);
  //     setProducts(prevState => ([...prevState, {dow, price, adOnPrice}]));
  //   }

  //   return (
  //     <MojeCard title="Add Product" subtitle="Set Pricing">
  //       {productList.length < 7 && (
  //         <Formik initialValues={{ price: 0, adOnPrice: 0 }} onSubmit={(values: any) => {  addProductToList(values) }}
  //           validationSchema={validatoinSchema}
  //         >
  //           {(props: any) => (
  //             <form onSubmit={props.handleSubmit}>
  //               <div className="row">
  //                 <div className="col-md-6">
  //                   <MojeFormField
  //                     label="Week Day"
  //                     name="dow"
  //                     values={props.values.dow}
  //                     {...props}
  //                   >
  //                     <MojeDropdown
  //                       name="dow"
  //                       options={weekDays}
  //                       onChange={value => props.setFieldValue("dow", parseInt(value))}
  //                     />
  //                   </MojeFormField>
  //                 </div>
  //                 <div className="col-md-3">
  //                   <MojeFormField
  //                     label="Price"
  //                     name="price"
  //                     values={props.values.price}
  //                     {...props}
  //                   >
  //                   <MojeTextInput name="price" onChange={props.handleChange} />
  //                 </MojeFormField>
  //                 </div>
  //                 <div className="col-md-3">
  //                   <MojeFormField
  //                     label="Ad On"
  //                     name="adOnPrice"
  //                     values={props.values.price}
  //                     {...props}
  //                     >
  //                     <MojeTextInput name="adOnPrice" onChange={props.handleChange} />
  //                   </MojeFormField>
  //                 </div>
  //               </div>
  //               <div className="row">
  //                 <MButton primary submit> Add To List </MButton>
  //               </div>
  //             </form>
  //             )}
  //           </Formik>
  //       )}
  //         <div className="row">
  //           <table>
  //             <tr>
  //               <th style={{ width: 150 }}>Day of Week</th>
  //               <th style={{ width: 200 }}>Price</th>
  //               <th style={{ width: 200 }}>AdOn Price</th>
  //               <th style={{ width: 200 }}></th>
  //             </tr>
  //           {productList.map((product)=>{
  //             <tr>
  //               <td> {dowNames[product.dow]} </td>
  //               <td> {dowNames[product.price]} </td>
  //               <td> {dowNames[product.adOnPrice]} </td>
  //             </tr>

  //           })}
  //           </table>
  //         </div>
  //       </MojeCard>
  //   )
  // }

  const renderAdOn = () => {
    const validatoinSchema: any = Yup.object({
      name: Yup.string().required(),
      price: Yup.number().positive("Please enter valid number").required()
    })
    return (
      <MojeCard title="Ad On">
        <Formik
          initialValues={{ price: 0, adOnPrice: 0 }}
          onSubmit={(values: any) => {
            console.log(values)
          }}
          validationSchema={validatoinSchema}
        >
          {(props: any) => (
            <form onSubmit={props.handleSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <MojeFormField
                    label="Name"
                    name="name"
                    values={props.values.name}
                    {...props}
                  >
                    <MojeTextInput name="name" onChange={props.handleChange} />
                  </MojeFormField>
                </div>
                <div className="col-md-3">
                  <MojeFormField
                    label="Price"
                    name="price"
                    values={props.values.price}
                    {...props}
                  >
                    <MojeTextInput name="price" onChange={props.handleChange} />
                  </MojeFormField>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    margin: "20 0",
                    textAlign: "right",
                    padding: "0 10px"
                  }}
                >
                  <MButton
                    label="Cancel"
                    secondary
                    onClick={() => {
                      setIsEditingAdOn(false)
                    }}
                  />
                  <MButton
                    label="Update Price"
                    primary
                    grouped
                    onClick={handleSave}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </MojeCard>
    )
  }

  const { venue } = props
  const isNew = props.venueId === "new"
  const { products } = venue
  return (
    <MojeCard
      title="Scenariusze i dodatki"
      style={{
        boxShadow: "none"
      }}
      toolboxComponent={
        <>
          {!isAdding && (
            <Button
              title="Dodaj"
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Add product", tmpProducts)
                setTmpProducts([...tmpProducts, {}])
                setIsAdding(true)
              }}
            >
              Dodaj
            </Button>
          )}

          {/* <MButton
            primary
            label="Edytuj scenariusze"
            onClick={handleStartProductEdit}
          /> */}
        </>
      }
    >
      {!isAdding && (
        <MojeInfoBar
          title="Scenariusz imprezy to konkretna oferta dla klienta"
          subtitle={
            <>
              <p>
                Np. 2h zabawy w sali zabaw (400z/10 osób) lub 2h zabawy plus
                tort i dekoracje (600zł/10 osób).
              </p>
              <p>
                Scenariusze to oferty różniące się zakresem oferowanych usług i
                ceną.
              </p>
            </>
          }
        />
      )}

      {isAdding && (
        <ProductCard
          product={{}}
          venue={venue}
          onRemove={() => {
            setIsAdding(false)
          }}
          onSaved={handleProductSaved}
          onStartEditing={() => {
            // setEditedProductId(product.id)
          }}
          onCancelEditing={() => {
            setIsAdding(false)
          }}
          isEditing={true}
        />
      )}
      {!isAdding && !isEditingProducts && (
        <VenueProductsList
          products={tmpProducts}
          venue={props.venue}
          onRemoveProduct={handleRemoveProduct}
          onProductSaved={handleProductSaved}
          onStartEditing={(product) => {
            setEditedProductId(product.id)
          }}
          onCancelEditing={() => {
            setEditedProductId(undefined)
          }}
          editedProductId={editedProductId}
        />
      )}
      {!isAdding && isEditingProducts && renderEditProducts()}
      {!isAdding && isEditingAdOn && renderAdOn()}
      {!isAdding && !isEditingProducts && (
        <div style={{ margin: "20px 0" }}>
          {!products ||
            (products.length === 0 && (
              <div>No product pricings are defined</div>
            ))}
        </div>
      )}
      {/* <small>{JSON.stringify(props.venue)}</small>
      tmpProducts:
      {JSON.stringify(tmpProducts)} */}
    </MojeCard>
  )
}
