import { Box } from '@mui/system'
import { PageContainer } from 'src/components/containers/PageConatiner'
import { LoginScreen } from 'src/modules/auth/LoginScreen'

export const SignUpScreen = () => {
  return (
    <PageContainer>
      <Box mt={2}>
        <LoginScreen register />
      </Box>
    </PageContainer>
  )
}
