import { PageContainer } from "src/components/containers/PageConatiner"

import { useAuthContext } from "../../auth/auth-context"
import { PlaceForm } from "../../modules/places/PlaceForm"

export default function NewVenuePage() {
  const authContext = useAuthContext()
  return (
    <PageContainer title="Dodaj nowe miejsce">
      {authContext.currentUser ? (
        <PlaceForm isEdited={true} ownerUser={authContext.currentUser} />
      ) : (
        <div>Nie zalogowany</div>
      )}
    </PageContainer>
  )
}
