import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const LoadingWidget = ({
  label,
  big
}: {
  label?: string
  big?: boolean
}) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={faSpinner}
        spin
        style={{ width: big ? 48 : 24, height: big ? 48 : 24 }}
      />
      {label && <div>{label}</div>}
    </div>
  )
}
