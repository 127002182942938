import axios from "axios"
import { makeUseAxios } from "axios-hooks"
import { useState } from "react"

export const useOwnerReservations = () => {
  const [isSaving, setIsSaving] = useState(false)
  var url = process.env.REACT_APP_API_URL + "/v1/reservations"

  // const [{ data, loading, error }, refetch] = useAxios(url)
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { data, loading, refetch }
}

export const useReservation = (id) => {
  const [isSaving, setIsSaving] = useState(false)
  const url = process.env.REACT_APP_API_URL + "/v1/reservations/" + id
  // const [{ data, loading, error }, refetch] = useAxios(url)
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { data, loading, refetch }
}
export const useReservationApi = () => {
  const [isSaving, setIsSaving] = useState(false)

  const runCommand = async (command) => {
    console.log("Runing command", command)

    let commandUrl =
      process.env.REACT_APP_API_URL + `/v1/reservations/${command.aggregateId}`

    return new Promise(async (resolve, reject) => {
      setIsSaving(true)

      var result = await axios({
        method: "POST",
        url: commandUrl,
        data: {
          ...command
        }
      })

      console.log("result", result)
      setIsSaving(false)
      if (result && result.status == 200) {
        resolve(result.data)
      } else {
        reject(result.data)
      }
    })
  }

  return { runCommand, isSaving }
}
