import { Grid } from '@mui/material'
import { useState } from 'react'

import { BuyPackagePopup } from './BuyPackagePopup'
import { LicenseBox } from './components/LicenseBox'
import { PaymentPendingPopup } from './PaymentPendingPopup'

const products: any = [
  {
    code: "22_STANDARD",
    name: "Standard",
    monthlyPrice: 5000,
    yearlyPrice: 50000,
    description:
      "Ten pakiet jest dla Ciebie, jeżeli prowadzisz jeden obiekt i chcesz dotrzeć do nowych klientów. W ramach pakietu możesz dodać aż 10 ofert. Zajmij się klientem stacjonarnie a my zadbamy o Twoją ofertę online",
    options: [
      "Wizytówka firmy (1 lokalizacja)",
      "10 ofert dla klientów",
      "Komunikator",
      "Wsparcie techniczne",
      "Analiza oferty",
      "Kalendarz rezerwacji",
      "Publikacja cen i opini klientów",
      "Promocja na FB",
      "Dodawanie promocji",
      "Dodawanie wydarzeń",
      "Pakiet startowy MU (BigBox)"
    ]
  },
  {
    code: "22_PREMIUM",
    name: "Premium",
    monthlyPrice: 9000,
    yearlyPrice: 50000,
    description:
      "Twój biznes rośnie i prowadzisz więcej obiektów. <br />Ten pakiet pozwoli dodać 3 wizytówki z różnymi adresami i do każdej dodać do 20 ofert. Będziemy wspierać Twój rozwój aby niedługo spotkać się przy pakiecie dedykowanym gdy otworzysz 4 obiekt.",
    options: [
      "Wizytówka firmy (3 lokalizacje)",
      "20 ofert dla klientów",
      "Komunikator",
      "Wsparcie techniczne",
      "Analiza oferty",
      "Kalendarz rezerwacji"
    ]
  },
  {
    code: "22_CORPORATE",
    name: "Korporacyjny",
    monthlyPrice: 4500,
    yearlyPrice: 50000,
    description: "Najlepszy",
    options: [
      "Pakiet dla klientów posiadających więcej niż 3 lokalizacje",
      "Dedykowane rozwiązania do zarządzania kalendarzem"
    ]
  }
]

export const BuyLicenseForm = () => {
  const [item, setSelected] = useState<any>()

  const handleBuy = (product) => {
    setSelected(product)
  }

  const [mode, setMode] = useState("MONTH")

  return (
    <div>
      {!item && (
        <Grid container spacing={3}>
          {products.map((prod) => (
            <Grid item xs={4}>
              <LicenseBox product={prod} onClick={handleBuy} mode={mode} />
            </Grid>
          ))}
        </Grid>
      )}

      <BuyPackagePopup
        open={item}
        product={item}
        onClose={() => {
          setSelected(undefined)
        }}
        mode={mode}
      />
      <PaymentPendingPopup />
    </div>
  )
  //   return (<>{
  //     !item && (
  //       <Grid container spacing={3}>
  //         {products.map((prod) => (
  //           <Grid item xs={4}>
  //             <LicenseBox product={prod} onClick={handleBuy} />
  //           </Grid>
  //         ))}
  //       </Grid>
  //     )</>
  //   }
  //   {
  //     item && <Box>Buy</Box>
  //   }
}
