import { Box } from "@mui/material"
import { FormattedDate, FormattedTime } from "react-intl"
import { Link, useHistory } from "react-router-dom"
import { BusinessPaginatedTable } from "src/components/BusinessPaginatedTable"
import { MojeTypo } from "src/components/MojeTypo"

export const MerchantsList = (props: any) => {
  const apiUrl = "/admin/merchants"

  const columns = [
    {
      header: "Firma/Opis",
      accessor: "name",
      Cell: (row) => {
        return (
          <div>
            <MojeTypo.TableNormal>
              <Link to={`/admin/merchants/${row.id}`}>
                <a
                  href={`/admin/merchants/${row.id}`}
                  style={{
                    display: "block",
                    width: "100%",
                    color: "initial",
                    textDecoration: "none"
                  }}
                >
                  {row.name}
                </a>
              </Link>
            </MojeTypo.TableNormal>
            <MojeTypo.TableSubtext>{row.admin_comment}</MojeTypo.TableSubtext>
          </div>
        )
      }
    },
    {
      header: "Weryfikacja",
      accessor: "verification_count",
      Cell: (row) => {
        return <Box>{row.verification_count ?? 0}</Box>
      }
    },
    {
      header: "Zablokowana",
      accessor: "locked",
      Cell: (row: any) => <Box>{row.locked ? "Tak" : "Nie"}</Box>
    },
    {
      header: "Miejsca",
      accessor: "publishedVenuesCount",
      Cell: (row) => <>{row.allVenuesCount}</>
    },
    {
      header: "Aktywne",
      accessor: "publishedVenuesCount",
      Cell: (row) => <>{row.publishedVenuesCount}</>
    },
    {
      header: "Licencja",
      accessor: "license",
      Cell: (row: any) => (
        <Box>
          <MojeTypo.TableSubtext>
            {row.licenseStatus}
            {row.licenseValidTill}
          </MojeTypo.TableSubtext>
        </Box>
      )
    }
  ]
  const queryParams = {}

  return (
    <BusinessPaginatedTable
      apiUrl={apiUrl}
      columns={columns}
      queryParams={queryParams}
      rowLinkFn={(row) => `/admin/merchants/${row.id}`}
    />
  )
  // return (
  //   <>
  //     <Table>
  //       <TableHead>
  //         <TableRow>
  //           <TableCell width="100%">Firma/Opis</TableCell>
  //           {/* <TableCell width="100px">Komentarz</TableCell> */}
  //           <TableCell width="100px">Weryfikacja</TableCell>
  //           <TableCell width="100px">Zablokowana</TableCell>
  //           <TableCell width="100px">Miejsca</TableCell>
  //           <TableCell width="100px">Aktywne</TableCell>
  //           <TableCell width="100%">Licencja</TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <tbody>
  //         {merchants &&
  //           merchants.map((merchant) => (
  //             <tr onClick={() => history.push(`merchants/${merchant.id}`)}>
  //               <TableCell width="100%">
  //                 <MojeTypo.TableNormal>
  //                   <Link to={`/admin/venues?merchantId=${merchant.id}`}>
  //                     <a
  //                       href={`/admin/venues?merchantId=${merchant.id}`}
  //                       style={{
  //                         display: "block",
  //                         width: "100%",
  //                         color: "initial",
  //                         textDecoration: "none"
  //                       }}
  //                     >
  //                       {merchant.name}
  //                     </a>
  //                   </Link>
  //                 </MojeTypo.TableNormal>
  //                 <MojeTypo.TableSubtext>
  //                   {merchant.adminComment}
  //                 </MojeTypo.TableSubtext>
  //               </TableCell>

  //               <TableCell>
  //                 <Box>
  //                   {merchant.validatedAt && (
  //                     <>
  //                       <FormattedDate value={merchant.validatedAt} />{" "}
  //                       <FormattedTime value={merchant.validatedAt} />
  //                     </>
  //                   )}
  //                 </Box>
  //               </TableCell>
  //               <TableCell>
  //                 <Box>{merchant.locked ? "Tak" : "Nie"}</Box>
  //               </TableCell>
  //               <TableCell>
  //                 <Grid container>
  //                   <Grid item onClick={(ev) => ev.preventDefault()}></Grid>
  //                   <div title="Wszystkie sale">{merchant.allVenuesCount}</div>
  //                 </Grid>
  //               </TableCell>
  //               <TableCell>{merchant.publishedVenuesCount}</TableCell>
  //               <TableCell>
  //                 <Box title="Pakiet">
  //                   <MojeTypo.TableNormal>
  //                     {merchant.license?.package_code ?? "brak"}
  //                   </MojeTypo.TableNormal>
  //                 </Box>
  //                 <Box title="Status / Wazne do">
  //                   <MojeTypo.TableSubtext>
  //                     {merchant.licenseStatus}
  //                     {merchant.licenseValidTill}
  //                   </MojeTypo.TableSubtext>
  //                 </Box>
  //               </TableCell>
  //               {/* <Link to={`/admin/venues?merchantId=${merchant.id}`}>
  //                       Pokaż sale
  //                     </Link> */}
  //             </tr>
  //           ))}
  //       </tbody>
  //     </Table>
  //   </>
  // )
}
