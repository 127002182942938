import axios from "axios"
import { useState } from "react"

export const usePlaceApi = () => {
  const [isSaving, setIsSaving] = useState(false)
  const runCommand = async (command) => {
    console.log("Runing command")

    let commandUrl =
      process.env.REACT_APP_API_URL + `/admin/venues/${command.aggregateId}`

    return new Promise(async (resolve, reject) => {
      setIsSaving(true)

      var result = await axios({
        method: "POST",
        url: commandUrl,
        data: {
          ...command
        }
      })

      if (result && result.status == 200) {
        setIsSaving(false)
        resolve(result.data)
      } else {
        setIsSaving(false)
        reject(result.data)
      }
    })
  }

  return { runCommand, isSaving }
}
