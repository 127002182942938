import '@draft-js-plugins/image/lib/plugin.css'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'

import { Box, Button, Card, Grid } from '@mui/material'
import axios from 'axios'
import { format, parseISO } from 'date-fns'
import { convertToHTML } from 'draft-convert'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { Formik } from 'formik'
import { MojeTextArea } from 'src/components/MojeTextArea'
import { useToastr } from 'src/components/MojeToastr'
import { PageHeader } from 'src/components/PageHeader'

import { DraftEditor } from '../../components/DraftEditor'
import { SectionMedia } from '../../components/media/SectionMedia'
import { MojeTextInput } from '../../components/MojeTextInput'

export const EditArticlePage = ({ article }: { article?: any }) => {
  console.log("EditArticlePage", article)
  const showToastr = useToastr()
  const doSubmit = async (values: any, actions) => {
    console.log("doSubmit", values, actions)
    const contentHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        console.log("entity to html", entity, originalText)
        if (entity.type === "IMAGE") {
          var dataObj = Object.keys(entity.data).reduce((a, i) => {
            a["data-" + i] = entity.data[i]
            return a
          }, {})
          return <img src={entity.data.src} {...dataObj}></img>
        }
        return originalText
      }
    })(values.content.getCurrentContent())

    console.log("contentHTML", contentHTML)

    try {
      actions.setSubmitting(true)
      var res = await saveArticle({
        id: article?.id,
        title: values.title,
        content: contentHTML,
        contentRaw: JSON.stringify(
          convertToRaw(values.content.getCurrentContent())
        ),
        media: values.media,
        publishedAt: values.publishedAt,
        slug: values.slug,
        summary: values.summary,
        videoUrl: values.videoUrl
      })
      console.log("Saved", res)
      showToastr("Artykuł zapisany")
    } catch (err) {
      console.error("Error saving article", err)
    }
    actions.setSubmitting(false)
  }

  const saveArticle = async (article) => {
    console.log("saveArticle", article)
    let url = `${process.env.REACT_APP_API_URL}/v1/admin/articles/${
      article.id ?? "new"
    }`
    try {
      const res = await axios(url, {
        method: "POST",
        data: article
      })
      console.log("Saved", article)
      return res.data
    } catch (err) {}
  }

  const handleAddMedia = async (newPhoto, formikProps) => {
    console.log("handleAddMedia", newPhoto, formikProps)

    const media = formikProps?.values?.media
      ? [...formikProps.values?.media]
      : []
    // [{"id": "7df3d3e6-ccc6-4789-8007-a44043edb7c1", "url": "http://res.cloudinary.com/mapspire/image/upload/v1602606896/mapspire_web_upload/chicks_bistro2_flicqj.jpg", "type": "PHOTO"}]
    media.push({
      id: newPhoto.id,
      url: newPhoto.url,
      type: "PHOTO"
    })
    console.log("media", media)
    formikProps.setFieldValue("media", media)
  }

  const handleRemoveMedia = async (mediaId) => {
    console.log("handleRemoveMedia", mediaId)
  }
  const initialValues = {
    ...article,
    content: article?.contentRaw
      ? EditorState.createWithContent(convertFromRaw(article.contentRaw))
      : EditorState.createEmpty(),
    publishedAt: article?.publishedAt
      ? format(parseISO(article?.publishedAt), "yyyy-MM-dd")
      : undefined
  }
  console.log("initialValues", initialValues)

  const startSavingPhoto = (photo) => {
    console.log("startSavingPhoto", photo)
  }
  //   const insertImage = ( url) => {
  //     const contentState = editorState.getCurrentContent();
  //     const contentStateWithEntity = contentState.createEntity(
  //         ‘IMAGE’,
  //         ‘IMMUTABLE’,
  //         { src: url },)
  // const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  // const newEditorState = EditorState.set( editorState, { currentContent: contentStateWithEntity });
  // return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ‘ ‘);
  // };

  return (
    <Box p={2}>
      <PageHeader
        title="Edytuj artykuł"
        backTitle="Wróć do artykułów"
        backUrl="/admin/articles"
        toolsComponents={() => {
          return (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.open(
                    `https://www.mojeurodziny.pl/inspiracje/${article.id}`
                  )
                }}
              >
                Podejrzyj na stronie
              </Button>
            </>
          )
        }}
      ></PageHeader>

      <Card elevation={1}>
        <Box p={2}>
          <Formik
            initialValues={initialValues}
            onSubmit={doSubmit}
            enableReinitialize
          >
            {(formikProps: any) => {
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box my={2}>Tytuł Artykułu</Box>
                      <MojeTextInput
                        name="title"
                        id="title"
                        placeholder="Tytuł artykułu"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.title}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box my={2}>
                        Slug (adres widoczny a adresie www - krotki, bez spacji,
                        z kreskami np jak-zorganizowac-impreze)
                      </Box>
                      <MojeTextInput
                        name="slug"
                        id="slug"
                        placeholder="Slug (adress www)"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.slug}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box my={2}>
                        Podsumowanie (widocznie na karcie artykułu)
                      </Box>
                      <MojeTextArea
                        name="summary"
                        id="summary"
                        placeholder="Skrót (widoczny na karcie artykułu)"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.summary}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box my={2}>Treść</Box>
                      <DraftEditor
                        images={true}
                        media={formikProps.values.media}
                        value={formikProps.values.content}
                        onChange={(ev) => {
                          formikProps.setFieldValue("content", ev)
                        }}
                        placeholder={"Treść artykułu"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box my={2}>Zdjęcia</Box>
                      <Box my={2}>
                        <SectionMedia
                          media={formikProps.values.media}
                          onAddMedia={(newPhoto) =>
                            handleAddMedia(newPhoto, formikProps)
                          }
                          onRemoveMedia={handleRemoveMedia}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box my={2}>Video (wklej URL)</Box>
                      <MojeTextInput
                        name="videoUrl"
                        id="videoUrl"
                        placeholder="Video (wklej URL)"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.videoUrl}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box my={2}>Data Publikacji</Box>
                      <MojeTextInput
                        type="date"
                        name="publishedAt"
                        id="publishedAt"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.publishedAt}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box my={2} display="flex" alignItems="flex-end">
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() => {}}
                        >
                          Anuluj
                        </Button>
                        <Box flex={1} />
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Zapisz
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </Box>
      </Card>
    </Box>
  )
}
