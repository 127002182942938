import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns'
import React from 'react'
import { MButton } from '../../../../components/MButton'
import { useToastr } from '../../../../components/MojeToastr'

import { SpecialConditionForm } from '../special-dates/SpecialConditionForm'
import { useSpecialDatesApi } from '../special-dates/useSpecialDatesApi'

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: 32,
    // width: 400,
    // heigth: 400,
    margin: "auto"
  }
})
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`
  }
}
const ADD_AVAILABILIY = "ADD_AVAILABILIY"
const BLOCK = "BLOCK"

export const CalendarDayPopup = (props) => {
  const [mode, setMode] = React.useState<string>(undefined)
  const classes = useStyles()
  const { runCommand, isSaving } = useSpecialDatesApi()

  const showToastr = useToastr()
  console.log("Edytuj dzien", props)

  React.useEffect(() => {
    setMode(undefined)
  }, [props.open])
  const handleAddAvailability = () => {
    setMode(ADD_AVAILABILIY)
  }

  const handleRemoveUnavailability = async (unavailability) => {
    console.log("handleRemoveUnavailability", unavailability)
    const command = {
      aggregateId: unavailability.id,
      type: "SpecialDate.Remove",
      aggregateType: "SpecialDate",
      payload: {}
    }
    const res: any = await runCommand(command)
    if (res.success) {
      showToastr("Zapisano", {})
      props.refetchSpecialDates()
    } else {
      showToastr("Problem z zapisem")
    }
  }
  const handleBlock = () => {
    setMode(BLOCK)
  }
  var daySpecials =
    props.specialDates != null && props.day
      ? props.specialDates.filter(
          (d) => d.date === format(props.day, "yyyy-MM-dd")
        )
      : []

  var unavailability = daySpecials.find((s) => s.type == "UNAVAILABLE")

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      className={classes.root}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">
        Edytuj dzień: {props.day ? format(props.day, "yyyy-MM-dd") : ""}
      </DialogTitle>
      {/* <Box style={getModalStyle()}> */}
      {/* <Box>
        <Typography>Edytuj dzien</Typography>
      </Box> */}
      <DialogContent style={{ width: "fit-content", minWidth: "500px" }}>
        {mode === ADD_AVAILABILIY && (
          <>
            <SpecialConditionForm
              venue={props.venue}
              onSaved={() => {
                props.refetchSpecialDates()
                setMode(undefined)
              }}
              date={props.day}
            />
          </>
        )}
        {mode == undefined && (
          <>
            <Box padding={2}>
              <Typography variant="subtitle2">Rezerwacje</Typography>
              <Box>Brak rezerwacji na ten dzien</Box>
            </Box>

            <Box padding={2}>
              <Typography variant="subtitle2">Dostępność</Typography>
              {unavailability ? (
                <Box>Ten dzień jest oznaczony jako niedostępny</Box>
              ) : (
                <Box>Ten dzień jest dostępny</Box>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" padding={2} width="100%">
          {mode === undefined && (
            <>
              <Box mx={2}>
                {!unavailability ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleAddAvailability}
                  >
                    Oznacz jako niedostępny
                  </Button>
                ) : (
                  <MButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveUnavailability(unavailability)}
                    isSaving={isSaving}
                    label="Oznacz jako dostępny"
                  />
                )}
              </Box>

              {/* <Box mx={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBlock}
                >
                  Zablokuj
                </Button>
              </Box> */}
            </>
          )}
          <Box flex={1} />
          <Button variant="contained" color="primary" onClick={props.onClose}>
            Zamknij
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
