import React, { useState } from "react"

import { useAuthContext } from "../../auth/auth-context"
import { MojeCard } from "../../components/card/MojeCard"
import { MButton } from "../../components/MButton"
import { MojeFormField } from "../../components/MojeFormField"
import { RegisterProfileForm } from "./RegisterProfileForm"

export const MyProfileInfo = (props) => {
  const authContext = useAuthContext()
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <RegisterProfileForm
        onProfileCreated={() => {
          setIsEditing(false)
        }}
        isEditing
      />
    )
  }

  if (!authContext.currentUser) {
    return (
      <>
        <RegisterProfileForm
          onProfileCreated={() => {
            console.log("onProfileCreated ")
            authContext.refetchCurrentUser()
          }}
        />
      </>
    )
  }
  return (
    <MojeCard
      title="Twój profil"
      toolboxComponent={
        <MButton
          label="Edytuj"
          onClick={() => {
            console.log("isEditing", isEditing)
            setIsEditing(true)
          }}
        />
      }
    >
      <MojeFormField
        label="Nazwa"
        name="name"
        value={authContext.currentUser?.name}
      />
    </MojeCard>
  )
}
