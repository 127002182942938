import { LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import useAxios from 'axios-hooks'
import { useParams } from 'react-router-dom'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { MerchantPage } from '../../../modules/merchants/MerchantPage'

export default function MerchantIdx(props) {
  const match = useParams<{ id: string }>()

  const url = process.env.REACT_APP_API_URL + "/admin/merchants/" + match.id
  const [{ data, loading, error }, refetch] = useAxios(url)
  const merchant = data
  return (
    <PageContainer>
      <Box pt={1}>
        {loading && <LinearProgress />}
        {merchant && (
          <MerchantPage merchant={merchant} refetch={refetch} adminMode />
        )}
      </Box>
    </PageContainer>
  )
}
