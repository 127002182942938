import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { Formik } from "formik"
import { useState } from "react"

import { MojeCard } from "src/components/card/MojeCard"
import { MButton } from "src/components/MButton"
import { MojeFormField, MojeFormFieldLabel } from "src/components/MojeFormField"
import { MojeTextInput } from "src/components/MojeTextInput"
import { useToastr } from "src/components/MojeToastr"
import { VerificationCheckBox } from "src/components/VerificationCheckBox"

import { useMerchantApi } from "./api/use_merchant_api"

export const MerchantAdminTools = ({ merchant, onSaved }) => {
  const showToastr = useToastr()
  const { runCommand, isSaving } = useMerchantApi()
  const [isEditing, setIsEditing] = useState(false)

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("handleSubmit", values)
    const command = {
      aggregateId: merchant.id,
      aggregateType: "Merchant",
      type: "Merchant.AdminUpdate",
      payload: {
        ...values
      }
    }
    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr("Dane administratora zapisane")
      if (onSaved) {
        setIsEditing(false)
        onSaved()
      }
      // TODO - show toastr success, redirect to /merchants/:id
    } else {
      showToastr("Problem z zapisem")
    }

    return true
  }

  const initialValues: any = {
    ...merchant,
    verification: merchant?.verification ?? {}
  }

  return (
    <MojeCard title="Narzedzia administratora">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          return (
            <form onSubmit={formikProps.handleSubmit} className="Form">
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MojeFormField
                    label="Właściciel Firmy (zaawansowana opcja widoczna tylko dla admina)"
                    value={formikProps.values?.ownerUser?.name}
                    // formikProps={formikProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MojeFormField
                    label="Komentarz administratora"
                    name="adminComment"
                    formikProps={formikProps}
                    isEdited={isEditing}
                    component={MojeTextInput}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MojeFormFieldLabel>Weryfikacja Admina</MojeFormFieldLabel>
                  {/* 
                  {values.validatedAt ? (
                    <>
                      <FormattedDate value={values.validatedAt} />{" "}
                      <FormattedTime value={values.validatedAt} />
                    </>
                  ) : (
                    "Nie zweryfikowana"
                  )} */}

                  <VerificationCheckBox
                    label="Dane adresowe"
                    code="address"
                    isEditing={isEditing}
                    formikProps={formikProps}
                  />
                  <VerificationCheckBox
                    label="NIP"
                    code="nip"
                    isEditing={isEditing}
                    formikProps={formikProps}
                  />
                  <VerificationCheckBox
                    label="Rachunek bankowy"
                    code="bank"
                    isEditing={isEditing}
                    formikProps={formikProps}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MojeFormField
                    label="Blokada edycji"
                    roComponent={() => {
                      return formikProps.values.locked ? (
                        <>Zablokowana do edycji</>
                      ) : (
                        "Nie Zablokowana"
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex">
                    {!isEditing && (
                      <>
                        <Box flex={1} />
                        <MButton
                          label="Edytuj"
                          variant="contained"
                          color="primary"
                          onClick={() => setIsEditing(true)}
                        />
                      </>
                    )}
                    {isEditing && (
                      <>
                        <MButton
                          label="Anuluj"
                          variant="text"
                          color="secondary"
                          submit
                          onClick={() => setIsEditing(false)}
                        />
                        <MButton
                          label="Zapisz"
                          variant="contained"
                          color="primary"
                          submit
                          onClick={() => setIsEditing(true)}
                        />
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </MojeCard>
  )
}
