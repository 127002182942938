import { FormControl, MenuItem, Select } from '@mui/material'

export const MojeDropdown = (props: any) => {
  const handleChange = (event: any) => {
    console.log("handleChange", event, event.target.value)
    // event.persist()
    var value = props.options
      ? props.options.find((o: any) => o.value == event.target.value)
      : undefined
    if (props.input && props.input.onChange) {
      props.input.onChange(event.target.value, value)
    }
    if (props.onChange) {
      props.onChange(event, value)
    }
  }

  const options = props.options ? props.options : []
  const { name, id } = props

  // console.log("Moje dropd", props)
  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        name={name}
        id={id ?? name}
        onChange={handleChange}
        value={props.input ? props.input.value : props.value}
      >
        {options &&
          options.map((d: any) => (
            <MenuItem value={d.value} key={d.value}>
              {d.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )

  // return (
  //   <MojeDropdownStyled
  //     name={name}
  //     onChange={handleChange}
  //     value={props.input ? props.input.value : props.value}
  //   >
  //     {options.map((d: any) => (
  //       <option key={d.value} value={d.value}>
  //         {d.name}
  //       </option>
  //     ))}
  //   </MojeDropdownStyled>
  // )
}
