import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const PageHeaderStyled = styled.div`
  margin: 24px 8px;

  .top {
    display: flex;
    flex-direction: row;
  }
  .bottom {
    display: flex;
    flex-direction: row;
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    flex: 1;
  }
`

export const PageHeader = (
  {
    title,
    subtitle,
    toolsComponents,
    children,
    backTitle,
    backUrl
  }: {
    title: string
    subtitle?: string
    toolsComponents?: any
    children?: any
    backTitle?: string
    backUrl?: string
  } = {
    title: undefined,
    subtitle: undefined,
    toolsComponents: undefined,
    children: undefined,
    backTitle: undefined
  }
) => {
  return (
    <Box mb={2}>
      <PageHeaderStyled>
        {backTitle && (
          <Link to={backUrl}>
            <Typography variant="caption">
              {"< "} {backTitle}
            </Typography>
          </Link>
        )}
        <div className="top">
          <Typography variant="h6">{title}</Typography>
          {toolsComponents && (
            <Box display="flex" justifyContent="flex-end" flex={1}>
              {typeof toolsComponents === "function"
                ? toolsComponents()
                : toolsComponents}
            </Box>
          )}
        </div>
        <div className="bottom">
          <div className="subtitle">{subtitle}</div>
        </div>
        {children}
      </PageHeaderStyled>
    </Box>
  )
}
