import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Typography
} from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { AuthContextType, useAuthContext } from "../../auth/auth-context"
import { MButton } from "../../components/MButton"
import { MojeTextInput } from "../../components/MojeTextInput"

export const LoginByEmailFormStyled = styled.div`
  .Field {
    margin: 8px 0;
    width: 100%;
  }
`

export const LoginByEmailForm = (props: any) => {
  const [loginData, setLoginData] = useState<any>({})
  const [loginError, setLoginError] = useState(undefined)
  const [showPassword, setShowPassword] = useState(false)

  const history = useHistory()
  const authContext: AuthContextType = useAuthContext()
  const handleRegister = () => {
    console.log("handleRegister")
  }

  const { isLoggingIn, onRegisterByEmail, onSignInByEmail } = props

  const handleSubmit = async (values, props) => {
    console.log("handleSubmit", values, loginMode)
    let user
    try {
      if (loginMode === "signup") {
        user = await onRegisterByEmail(values, props)
        console.log("User Signed In", user)
      } else if (loginMode === "signin") {
        // user = await this.handleSigninByEmailAWS(values, props)
        user = await onSignInByEmail(values, props)
        console.log("User Signed In", user)
        if (user) {
          await authContext.refetchCurrentUser()
          history.push("/")
        }
      }
    } catch (err) {
      console.log("Signin error", err)
      var errorMessage = err.message ?? "Błąd logowania"
      if (err.message === "Incorrect username or password.") {
        errorMessage = "Niepoprawna nazwa użytkownika lub błędne hasło"
      }
      setLoginError(errorMessage)
      setTimeout(() => {
        setLoginError(undefined)
      }, 5000)
    }
    // if (user) {
    //   this.props.doPush('/business/dashboard')
    // }
  }

  const handleClickForgotPwd = () => {
    props.onClickResetPassword()
  }

  const { loginMode } = props
  const errorMessage = undefined
  console.log("Render LoginModal Inner", props)
  let confirmationLabel = "Zarejestruj"
  if (loginMode === "signin") {
    confirmationLabel = "Zaloguj"
  }
  if (loginMode === "confirmation") {
    confirmationLabel = "Potwierdź"
  }
  return (
    <LoginByEmailFormStyled>
      <Formik initialValues={loginData} onSubmit={handleSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitForm
        }) => (
          <form
            onSubmit={handleSubmit}
            className="Form"
            onKeyDown={(e) => {
              console.log("onKeyDown")
              if (e.key === "Enter") {
                submitForm()
              }
            }}
          >
            <div className="Field">
              <MojeTextInput
                label="E-mail"
                type="text"
                className="form-control mps-metro-input"
                id="email"
                name="email"
                placeholder="E-mail"
                required
                onChange={handleChange}
                value={values.email}
              />
            </div>

            {/* <FormControl className={classes.margin}> */}
            {/* <InputLabel shrink htmlFor="bootstrap-input">
          Bootstrap
        </InputLabel>
        <BootstrapInput defaultValue="react-bootstrap" id="bootstrap-input" />
      </FormControl>
       */}
            <FormControl className="Field">
              {/* <InputLabel shrink htmlFor="bootstrap-input">
                Hasło
              </InputLabel> */}
              <MojeTextInput
                label="Hasło"
                type={showPassword ? "text" : "password"}
                placeholder="Hasło"
                name="password"
                className="form-control mps-metro-input"
                id="password"
                required
                onChange={handleChange}
                value={values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                      onMouseDown={(event) => event.preventDefault()}
                      size="large"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {loginMode === "signin" && (
              <Box py={1} textAlign="right">
                <a href="#" onClick={handleClickForgotPwd}>
                  <Typography variant="body2" fontWeight="medium">
                    Zapomniałeś hasła?
                  </Typography>
                </a>
              </Box>
            )}
            <div style={{ marginTop: 20, textAlign: "right" }}>
              {errorMessage && (
                <span className="alert alert-danger help-block">
                  {errorMessage}
                </span>
              )}

              <MButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={async () => {
                  await setFieldValue("mode", "signin", false)
                  submitForm()
                }}
                isSaving={isLoggingIn}
                label={confirmationLabel}
              />
            </div>
            {loginError && (
              <div
                style={{
                  textAlign: "center",
                  padding: 10,
                  height: 30,
                  color: "red"
                }}
              >
                {loginError}
              </div>
            )}
          </form>
        )}
      </Formik>
    </LoginByEmailFormStyled>
  )
}
