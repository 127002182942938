import { Box, Typography } from "@mui/material"
import { useState } from "react"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { PageContainer } from "src/components/containers/PageConatiner"
import { useMerchantContext } from "src/contexts/merchant-context"

import { useAuthContext } from "../../auth/auth-context"
import { MojeCard } from "../../components/card/MojeCard"
import { MButton } from "../../components/MButton"
import { MojeInfoBar } from "../../components/MojeInfoBar"
import { PageHeader } from "../../components/PageHeader"
import Tabs from "../../components/Tabs"
import useTab from "../../components/Tabs/useTab"
import { MerchantForm } from "../../modules/merchants/MerchantForm"
import { CompanySectionPayment } from "./CompanySectionPayments"
import { ContinuePayment } from "./ContinuePayment"
import { CompanySectionDocuments } from "./documents/CompanySectionDocuments"
import { ComapnySectionSubscription } from "./MyCompanySubscription"

const MyCompanyCard = ({ merchant, onEditMerchant }) => {
  return (
    <MojeCard
      title={merchant.name}
      toolboxComponent={<MButton label="Edytuj" onClick={onEditMerchant} />}
    >
      <div>Wybrane podstawowe info tutaj, bieżące stats + TODOs</div>
    </MojeCard>
  )
}

export default function MyCompanyIndex(props) {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const currentTab = useTab() ?? "upcoming"

  console.log("MyCompanyIndex", query)
  return (
    <PageContainer>
      <Box py={2}>
        <Typography variant="h5">Ustawienia Moje Urodziny</Typography>
      </Box>
      <Box display="flex">
        <Tabs
          useRoutes
          sx={{
            padding: 0
          }}
          tabs={[
            {
              label: "Firma",
              route: "/firma"
            },
            {
              label: "Abonament",
              route: "/firma/abonament"
            },
            {
              label: "Płatności",
              route: "/firma/platnosci"
            },
            {
              label: "Dokumenty",
              route: "/firma/dokumenty"
            }
          ]}
        />
      </Box>

      <Switch>
        <Route path="/firma/abonament" component={ComapnySectionSubscription} />
        <Route path="/firma/platnosci/kontynuuj" component={ContinuePayment} />
        <Route path="/firma/platnosci" component={CompanySectionPayment} />
        <Route path="/firma/dokumenty" component={CompanySectionDocuments} />
        <Route component={MyCompanyInfo} />
      </Switch>
    </PageContainer>
  )
}

function MyCompanyInfo(props) {
  const router = useHistory()
  const [isCreating, setIsCreating] = useState(false)
  const [editedMerchant, setEditedMerchant] = useState(undefined)
  const authContext = useAuthContext()
  const merchantContext = useMerchantContext()

  console.log("My Company index")

  return (
    <Box>
      {/* <OnboardingBar /> */}
      <PageHeader
        title="Moja firma"
        subtitle="Zacznij od wprowadzenia danych firmy. Twoje miejsca (sale) wprowadzimy później."
        toolsComponents={() => {
          return (
            <>
              {!editedMerchant &&
                !isCreating &&
                merchantContext.merchants &&
                merchantContext.merchants.length > 0 && (
                  <MButton primary onClick={() => setIsCreating(true)}>
                    Dodaj Firmę
                  </MButton>
                )}
            </>
          )
        }}
      ></PageHeader>

      {!authContext.currentUser ? (
        <>Nie zalogowany</>
      ) : (
        <>
          {!editedMerchant && !isCreating && merchantContext.merchants && (
            <>
              {merchantContext.merchants.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <MojeInfoBar title="Nie masz jeszcze danych firmy">
                    <MButton
                      primary
                      onClick={() => setIsCreating(true)}
                      variant="contained"
                      color="primary"
                    >
                      Wprowadź dane firmy
                    </MButton>
                  </MojeInfoBar>
                </div>
              ) : (
                merchantContext.merchants.map((merchant) => {
                  return (
                    <MyCompanyCard
                      merchant={merchant}
                      onEditMerchant={() => {
                        setEditedMerchant(merchant)
                      }}
                    />
                  )
                })
              )}
            </>
          )}
          {isCreating && (
            <MerchantForm isEdited ownerUser={authContext.currentUser} />
          )}
          {/* {!editedMerchant && (
            <MButton label="Dodaj Firme" onClick={() => setIsCreating(true)} />
          )} */}
        </>
      )}
      {editedMerchant && (
        <MerchantForm
          merchant={editedMerchant}
          isEdited
          onSaved={() => {
            setEditedMerchant(undefined)
            merchantContext.reloadMerchants()
          }}
        />
      )}
    </Box>
  )
}
