import Container from "@mui/material/Container"
import { LoginForm } from "./LoginForm"

export const LoginScreen = (props) => {
  return (
    <Container maxWidth="xs" disableGutters className="mt-10">
      <LoginForm {...props} />
    </Container>
  )
}
