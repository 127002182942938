import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { Theme, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'

import { LoadingWidget } from '../../components/LoadingWidget'
import { MessagingRightWidget } from './components/MessagingRightWidget'
import { ChatWidget } from './components/MiniChatWidget'
import { useOwnerChats } from './useMessagesApi'

const rightWidth = 375

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    center: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    centerShifted: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawer: {
      position: "relative",
      width: rightWidth
    },
    drawerPaper: {
      position: "absolute",
      width: rightWidth
    }
  })
)

export const MessagesList = (props: any) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const classes = useStyles()
  const { data, loading } = useOwnerChats({})
  const [selectedChat, setSelectedChat] = useState(undefined)
  const [showRight, setShowRight] = useState(false)

  const centerContent = (
    <div>
      {selectedChat && (
        <>
          <Toolbar>
            <Button
              sx={{
                marginLeft: "auto"
              }}
              variant="contained"
              color="secondary"
              onClick={() => setShowRight(true)}
            >
              Details
            </Button>
          </Toolbar>
          <ChatWidget
            merchant={selectedChat.merchant}
            user={selectedChat.user}
          />
        </>
      )}
    </div>
  )

  const rightContent = (
    <Box sx={{ padding: 2 }}>
      {selectedChat?.user && (
        <MessagingRightWidget userId={selectedChat.user?.id} />
      )}
    </Box>
  )

  return (
    <Box
      sx={{
        display: {
          sm: "flex"
        },
        width: "100%",
        border: "1px solid #D1D5DB",
        height: "calc(100vh - 56px)",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0
      }}
    >
      <Box
        sx={{
          height: "100%",
          flex: "none",
          width: {
            xs: "100%",
            md: "375px"
          }
        }}
      >
        <Box
          sx={{
            height: "100%",
            borderRight: "1px solid #D1D5DB"
          }}
        >
          <Box
            sx={{
              padding: 2,
              borderBottom: "1px solid #D1D5DB"
            }}
          >
            Rozmowy
          </Box>
          {loading && (
            <Box sx={{ textAlign: "center", padding: 0.5 }}>
              <LoadingWidget />
            </Box>
          )}
          {data && Array.isArray(data) && (
            <>
              {data.map((chat, index) => {
                return (
                  <Box
                    sx={{
                      padding: 0.5,
                      borderBottom: "1px solid #F3F4F6",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#F3F4F6"
                      }
                    }}
                    onClick={() => {
                      setSelectedChat(chat)
                    }}
                    key={index}
                    tabIndex={0}
                  >
                    <div>{chat.user?.name}</div>
                    <div>
                      {chat.messages && chat.messages.length > 0
                        ? chat.messages[0].contentText
                        : ""}
                    </div>
                  </Box>
                )
              })}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "flex"
          },
          width: "100%",
          overflowX: "hidden"
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginRight: showRight ? 0 : -rightWidth
          }}
          className={showRight ? classes.centerShifted : classes.center}
        >
          {centerContent}
        </Box>
        <Drawer
          variant="persistent"
          anchor="right"
          open={showRight}
          className={classes.drawer}
          sx={{ zIndex: showRight ? 0 : -1 }}
          classes={{ paper: classes.drawerPaper }}
        >
          <div>
            <IconButton
              aria-label="close"
              onClick={() => setShowRight(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {rightContent}
        </Drawer>
      </Box>
    </Box>
  )
}
