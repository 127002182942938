import axios from "axios"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from "react"
import { Merchant, useAuthContext } from "src/auth/auth-context"

export type OnboardingProgress = {
  companyCompleted: boolean
  venueCreated: boolean
  onboardingCompleted: boolean
  licenseActivated: boolean
}
export enum InitializationStatus {
  INITIALIZING = "INITIALIZING",
  READY = "READY"
}
export interface MerchantContextType {
  merchant?: Merchant
  merchants: Array<Merchant>
  venues: Array<any>
  initializationStatus: InitializationStatus
  onboardingProgress: OnboardingProgress
  reloadMerchants: Function
  reloadVenues: Function
  onboardingSteps?: Array<{
    label: string
    completed?: boolean
  }>
}

const LOCAL_STORAGE_KEY = "mojeMerchantContext"
export const MerchantContext = createContext<MerchantContextType>({
  initializationStatus: InitializationStatus.INITIALIZING,
  merchants: [],
  venues: [],
  onboardingProgress: {
    companyCompleted: false,
    venueCreated: false,
    onboardingCompleted: false,
    licenseActivated: false
  },
  reloadMerchants: () => {},
  reloadVenues: () => {}
})

const initialState =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) as string) || {}

console.log("Merchsant context initial State", initialState)

function reducer(state, action) {
  switch (action.type) {
    case "merchantsLoaded":
      return {
        ...state,
        merchants: action.payload.merchants,
        merchant: action.payload.merchant
      }
    case "venuesLoaded":
      return {
        ...state,
        venues: action.payload.venues
      }
    case "initializationCompleted":
      return {
        ...state,
        initializationStatus: InitializationStatus.READY
      }
    default:
      throw new Error()
  }
}

export const MerchantContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  //   const [initializationStatus, setInitizationStatus] = useState(
  //     InitializationStatus.INITIALIZING
  //   )
  const authContext = useAuthContext()

  const initialize = async () => {
    console.log("MerchantContextProvider.initialize", authContext)
    await reloadMerchants()
    await reloadVenues()
    console.log("Dispatch initializationCompleted")
    dispatch({ type: "initializationCompleted" })
  }

  const reloadMerchants = useCallback(async () => {
    console.log("reloadMerchants: ", authContext.currentUser)
    if (!authContext.currentUser) {
      return
    }
    const url = `${process.env.REACT_APP_API_URL}/owner/merchants`
    const res = await axios.get(url)
    console.log("Reloaded merchants", res)

    const action = {
      merchants: res.data,
      merchant: res.data?.length > 0 ? res.data[0] : undefined
    }
    dispatch({ type: "merchantsLoaded", payload: action })
  }, [authContext.currentUser])

  const reloadVenues = useCallback(async () => {
    console.log("reloadVenues: ", authContext.currentUser, state)
    // Load venues
    if (!authContext.currentUser) {
      return
    }
    if (!state.merchant) {
      console.log("Reload venues - no merchant")
      return
    }
    const venuesUrl = `${process.env.REACT_APP_API_URL}/venues?merchantId=${state.merchant.id}`
    const venuesRes = await axios.get(venuesUrl)
    console.log("Reloaded venues", venuesRes)
    const action = {
      venues: venuesRes.data?.items ? venuesRes.data.items : []
    }
    dispatch({ type: "venuesLoaded", payload: action })
  }, [authContext.currentUser, state])

  const _rehydrate = async () => {
    console.log("_rehydrate MerchantContextProvider")
    if (window.localStorage) {
      const dataStr = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (dataStr != null) {
        console.log("_rehydrate data", dataStr)
        const data = JSON.parse(dataStr)
        // if (data.currentUser) {
        //   setCurrentUser(data.currentUser)
        //   return data.currentUser
        // }
      }
    }
  }

  //   useEffect(() => {
  //     console.log("AuthContext - merchants loaded", merchants)
  //     if (merchants && merchants.length === 1) {
  //       setMerchant(merchants[0])
  //     }
  //   }, [merchants])

  useEffect(() => {
    console.log("MerchantContext Initialization")
    initialize()
  }, [])

  useEffect(() => {
    reloadMerchants()
  }, [authContext.currentUser])

  const _persist = useCallback(() => {
    if (window.localStorage) {
      //   if (currentUser) {
      //     const data = {
      //       currentUser
      //     }
      //     console.log("AuthContext .persist")
      //     window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
      //   } else {
      //     window.localStorage.removeItem(LOCAL_STORAGE_KEY)
      //   }
    }
  }, [state.merchant])

  //   useEffect(() => {
  //     console.log("MerchantContext Persister")
  //     // if (isInitializationCompleted) {
  //     //   _persist()
  //     //   refreshHomeData()
  //     // }
  //   }, [currentUser, isInitializationCompleted, refreshHomeData])

  const venueCreated = (state.venues && state.venues.length > 0) || true

  const licenseActivated = false

  const onboardingSteps = [
    { label: "Wprowadź dane firmy", completed: !!state.merchant },
    { label: "Wprowadź dane sali", completed: venueCreated },
    { label: "Wybierz i aktywuj pakiet", completed: licenseActivated }
  ]
  const contextValue: MerchantContextType = {
    merchant: state.merchant,
    merchants: state.merchants,
    venues: state.venues,
    initializationStatus: state.initializationStatus,
    reloadMerchants,
    reloadVenues,
    onboardingSteps,
    onboardingProgress: {
      companyCompleted: !!state.merchant,
      venueCreated,
      onboardingCompleted:
        (!!state.merchant && venueCreated) || authContext.isSuperAdmin, // TODO: Add license
      licenseActivated
    }
  }
  console.log("Merchant context value", contextValue)
  return (
    <MerchantContext.Provider value={contextValue}>
      {children}
    </MerchantContext.Provider>
  )
}

export const useMerchantContext = (): MerchantContextType =>
  useContext(MerchantContext) as MerchantContextType
