import { Box, Typography } from '@mui/material'

import Tabs from '../../components/Tabs'
import { ReservationsTable } from './ReservationsTable'

export const ReservationsList = (props) => {
  return (
    <Box>
      <Box display="flex" py={2}>
        <Box width="100%">
          <Typography variant="h5">Rezerwacje</Typography>
          <Tabs
            tabs={[
              {
                label: "Nadchodzące",
                route: "upcoming"
              },
              {
                label: "Zakończone",
                route: "completed"
              },
              {
                label: "Anulowane",
                route: "canceled"
              },
              {
                label: "Wszystkie",
                route: "all"
              }
            ]}
            aria-label="Reservation tab"
          />
        </Box>
        <Box style={{ flex: 1 }} />
        {/* <Button
          onClick={() => router.push("/reservations/new")}
          variant="contained"
          color="primary"
        >
          Dodaj Rezerwację
        </Button> */}
      </Box>

      <ReservationsTable prefix={props.prefix} />
    </Box>
  )
}
