import axios from "axios"
import { makeUseAxios } from "axios-hooks"

const useAxios = makeUseAxios({ axios })
export const useAdminReservations = (filter: any = {}) => {
  let url = `${process.env.REACT_APP_API_URL}/v1/admin/reservations`
  if (filter?.merchantId) {
    url += "?merchantId=" + filter.merchantId
  }
  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })
  return { reservations: data, loading: false }
}

export const useOwnerReservations = (filters: any = {}) => {
  var url = `${process.env.REACT_APP_API_URL}/v1/owner/reservations`

  const query = []
  if (filters.userId) {
    query.push("userId=" + filters.userId)
  }
  if (filters.pending) {
    query.push("pending=true")
  }
  url += "?" + query.join("&")
  const useAxios = makeUseAxios({ axios })
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url
    },
    {
      useCache: false
    }
  )

  return { reservations: data, loading, refetch }
}
