import { ToastrType, useToastr } from "../../components/MojeToastr"
import { usePlaceApi } from "./api/use_place_api"
import { PlacePageContextProvider } from "./PlacePageContext"
import { VenuePageLayout } from "./VenuePageLayout"

export const VenuePage = (props) => {
  const { runCommand, isSaving } = usePlaceApi()
  const showToastr = useToastr()

  const handleAddMedia = async (newPhoto) => {
    console.log("handleAddMedia", newPhoto)

    const command = {
      type: "Venue.AddMedia",
      aggregateId: props.venue.id,
      payload: {
        ...newPhoto
      }
    }

    const res: any = await runCommand(command)
    if (res && res.success) {
      showToastr("Zdjęcie dodane")
      props.refetch()
    } else {
      showToastr("Save error")
    }
  }

  const handleRemoveMedia = async (mediaId) => {
    console.log("handleRemoveMedia", mediaId)

    showToastr("Czy chcesz usunąć to zdjęcie?", {
      type: ToastrType.CONFIRM,
      onYes: async () => {
        const command = {
          type: "Venue.RemoveMedia",
          aggregateId: props.venue.id,
          payload: {
            id: mediaId
          }
        }

        const res: any = await runCommand(command)
        if (res && res.success) {
          showToastr("Zdjęcie usunięte")
          props.refetch()
        } else {
          showToastr("Problem z zapisem")
        }
      }
    })
  }
  return (
    <PlacePageContextProvider
      venue={props.venue}
      onAddMedia={handleAddMedia}
      onRemoveMedia={handleRemoveMedia}
      isSaving={isSaving}
    >
      <VenuePageLayout
        venue={props.venue}
        isEdited={props.isEdited}
        refetch={props.refetch}
        onAddMedia={handleAddMedia}
        onRemoveMedia={handleRemoveMedia}
        adminMode={props.adminMode}
      />
    </PlacePageContextProvider>
  )
}
