import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import styled from "styled-components"

const PriceRow = styled.div<any>`
  display: flex;

  margin: 8px 0;

  ${(props) =>
    props.total &&
    `
    font-size: 20px;
    margin: 16px 0 !important;
    border-top: 1px solid #f0f0f0;
  `}
  > .label {
    flex: 1;
  }
  > .price {
    font-weight: 700;
    font-size: 1.2rem;
  }
`
export const ReservationConditions = ({
  reservation
}: {
  reservation: any
}) => {
  return (
    <Box sx={{ display: "flex", marginBottom: 4, width: "100%" }}>
      <Box width="100%">
        <Typography component="div" variant="h6" fontWeight="bold" m={1}>
          Cena
        </Typography>
        <PriceRow>
          <div className="label">Podstawowa cena za salę (do 10 osób)</div>
          <div className="price">{reservation.baseRate} </div>
        </PriceRow>
        {reservation.additionalGuestsCount > 0 && (
          <PriceRow>
            <div className="label">
              Dodatkowe osoby ({reservation.additionalGuestsCount} osob x (
              {reservation.additionalPersonRate} zł))
            </div>

            <div className="price">{reservation.additionalPrice} </div>
          </PriceRow>
        )}
        <PriceRow total>
          <div className="label">Całkowita cena</div>
          <div className="price">
            <Typography variant="h5" fontWeight="900">
              {reservation.totalPrice}
            </Typography>
          </div>
        </PriceRow>
      </Box>
    </Box>
  )
}
