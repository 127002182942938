import { Box } from "@mui/material"
import { FormattedDate, FormattedTime } from "react-intl"
import { BusinessPaginatedTable } from "src/components/BusinessPaginatedTable"

import { CouponUtils } from "./CouponUtils"

export const CouponsTable = (props) => {
  const columns = [
    {
      accessor: "created_at",
      header: "Dodany",
      Cell: (row) => {
        return (
          <div>
            {row.created_at && (
              <>
                <FormattedDate value={row.created_at} />{" "}
                <FormattedTime value={row.created_at} />
              </>
            )}
          </div>
        )
      }
    },
    {
      header: "Rodzaj/Opis",
      accessor: "type",
      Cell: (row) => {
        return (
          <Box textAlign="left">
            <Box>{row.type}</Box>
            <Box>{row.label}</Box>
          </Box>
        )
      }
    },
    {
      header: "Kod",
      accessor: "code",
      Cell: (row) => {
        return <Box textAlign="left">{row.code}</Box>
      }
    },
    {
      header: "Status",
      accessor: "status",
      Cell: (row) => {
        return (
          <Box textAlign="right">{CouponUtils.statusLabel(row.status)}</Box>
        )
      }
    }
  ]

  return (
    <Box>
      <BusinessPaginatedTable
        onRowClick={props.onRowClick}
        columns={columns}
        apiUrl={`/v1/coupons`}
        //   rowLinkFn={(article) => `/admin/articles/${article.id}`}
      />
    </Box>
  )
}
