export class PaymentUtils {
  static paymentTypeLabel(type) {
    if (type === "MERCHANT_SUBSCRIPTION") {
      return "Płatność abonamentu"
    }
    return type
  }
  static formatAmount(amount) {
    return Number(amount / 100).toFixed(2)
  }

  static formatStatus(value) {
    if (value === "VERIFIED") {
      return "Potwierdzona"
    }
    if (value === "NEW") {
      return "W trakcie"
    }
    return value
  }
}
