import useAxios from 'axios-hooks'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { LoadingWidget } from '../../../components/LoadingWidget'
import { MButton } from '../../../components/MButton'
import { ArticlesList } from '../../../modules/articles/ArticlesList'

export default function AdminArticlesIndex(props) {
  let url = `${process.env.REACT_APP_API_URL}/v1/admin/articles`
  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })
  const router = useHistory()
  return (
    <PageContainer
      title="Artykuły na blogu"
      toolsComponents={() => {
        return (
          <>
            <MButton
              variant="contained"
              color="primary"
              primary
              onClick={() => router.push("/admin/articles/new")}
            >
              Dodaj Artykuł
            </MButton>
          </>
        )
      }}
    >
      {loading && <LoadingWidget />}
      <ArticlesList articles={data} />
    </PageContainer>
  )
}
