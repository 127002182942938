import "@draft-js-plugins/image/lib/plugin.css"
import "@draft-js-plugins/inline-toolbar/lib/plugin.css"

import { Box, Button } from "@mui/material"
import axios from "axios"
import { Formik } from "formik"

import { MojeDropdown } from "../../components/MojeDropdown"
import { MojeFormField } from "../../components/MojeFormField"
import { MojeTextInput } from "../../components/MojeTextInput"

const locationTypeOptions = [
  {
    name: "Miasto",
    value: "miasto"
  },
  {
    name: "Wojewodztwo",
    value: "wojewodztwo"
  }
]
export const EditLocationPage = ({
  location,
  locations,
  onSaved
}: {
  location?: any
  locations: any
  onSaved: any
}) => {
  console.log("EditLocationPage", location)

  const doSubmit = async (values: any, actions) => {
    console.log("doSubmit", values, actions)

    try {
      actions.setSubmitting(true)
      var res = await save(values)
      console.log("Saved", res)
      onSaved()
    } catch (err) {
      console.error("Error saving article", err)
    }
    actions.setSubmitting(false)
  }

  const save = async (location) => {
    console.log("location", location)
    let url = `${process.env.REACT_APP_API_URL}/v1/admin/locations/${
      location.id ?? "new"
    }`
    const res = await axios(url, {
      method: "POST",
      data: location
    })
    console.log("Saved", location)
    return res.data
  }

  const locationsOptions = locations.map((l: any) => ({
    name: l.name,
    value: l.id
  }))
  const initialValues = {
    ...location
  }
  return (
    <Box p={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={doSubmit}
        enableReinitialize
      >
        {(formikProps: any) => {
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Box>Nazwa</Box>
              <MojeTextInput
                name="name"
                id="name"
                onChange={formikProps.handleChange}
                value={formikProps.values.name}
              ></MojeTextInput>

              <Box>
                <MojeFormField
                  label="Kod"
                  name="code"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.code}</div>}
                >
                  <MojeTextInput
                    name="code"
                    id="code"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.code}
                  ></MojeTextInput>
                </MojeFormField>
              </Box>

              <Box>
                <MojeFormField
                  label="Rodzaj"
                  name="type"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.type}</div>}
                >
                  <MojeDropdown options={locationTypeOptions} />
                </MojeFormField>
              </Box>
              <Box>
                <MojeFormField
                  label="Nadrzędna"
                  name="parentLocationId"
                  formikProps={formikProps}
                  isEdited={true}
                  roComponent={<div>{formikProps.values.parentLocationId}</div>}
                >
                  <MojeDropdown
                    options={locationsOptions}
                    value={formikProps.values.parentLocationId}
                  />
                </MojeFormField>
              </Box>

              <Box>
                <Button variant="contained" color="primary" type="submit">
                  Zapisz
                </Button>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
