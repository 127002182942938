import axios from 'axios'
import { useState } from 'react'

export const useMerchantApi = () => {
  const [isSaving, setIsSaving] = useState(false)
  const url = process.env.REACT_APP_MOJE_URL + "/public/venues"
  // const [{ data, loading, error }, refetch] = useAxios(url)

  const runCommand = async (command) => {
    console.log("Runing command", command)

    let commandUrl =
      process.env.REACT_APP_API_URL + `/admin/merchants/${command.aggregateId}`

    return new Promise(async (resolve, reject) => {
      setIsSaving(true)

      var result = await axios({
        method: "POST",
        url: commandUrl,
        data: {
          ...command
        }
      })

      console.log("result", result)
      setIsSaving(false)
      if (result && result.status === 200) {
        setIsSaving(false)
        resolve(result.data)
      } else {
        setIsSaving(false)
        reject(result.data)
      }
      // setTimeout(() => {
      //   setIsSaving(false);
      //   resolve({
      //     success: true,
      //   });

      //   // TODO - for tests do
      //   return {
      //     success: false,
      //     message: "Some error happened",
      //   };
      // }, 2000);
    })
  }

  return { runCommand, isSaving }
}
