import { Container } from "@mui/material"
import { useState } from "react"
import { useHistory } from "react-router-dom"

import {
  AdminMerchantsDropdown,
  MerchantItem
} from "../../../components/admin/AdminMerchantsDropdown"
import { MButton } from "../../../components/MButton"
import { PageHeader } from "../../../components/PageHeader"
import { ReservationsList } from "../../../modules/reservations/ReservationsList"

export default function AdminReservationsIndex(props) {
  const [merchant, setMerchant] = useState<MerchantItem>()
  const router = useHistory()
  return (
    <Container>
      <PageHeader
        title="Wszystkie Rezerwacje"
        subtitle="Tryb administratora"
        toolsComponents={() => {
          return (
            <>
              <MButton
                variant="contained"
                color="primary"
                primary
                onClick={() => router.push("/admin/reservations/new")}
              >
                Dodaj Rezerwacje
              </MButton>
            </>
          )
        }}
      >
        <AdminMerchantsDropdown
          value={merchant}
          onChange={(ev, value) => {
            setMerchant(value)
          }}
        />
      </PageHeader>

      <ReservationsList prefix={"/admin/reservations"} />
    </Container>
  )
}
