import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { addDays, addMonths, addWeeks, format, startOfMonth, startOfWeek } from 'date-fns'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useOwnerSpecialDates } from '../special-dates/useSpecialDatesApi'
import { CalendarDayPopup } from './CalendarDayPopup'

// import { IconButton } from '../ui/IconButton'

const useStyles = makeStyles({
  root: {},
  header: {
    [".actionWrapper"]: {
      backgroundColor: "yellow"
    }
  },

  dayAvailable: {
    backgroundColor: "#cfead3"
  },
  dayUnavailable: {
    backgroundColor: "var(--moje-color-red)",
    color: "white",
    opacity: 0.8,
    ["&:hover"]: {
      backgroundColor: "var(--moje-color-red) !important;",
      opacity: 1
    }
  },
  dayWrapper: {
    width: "100%",
    borderTop: `1px solid #dfdfdf`,
    borderLeft: `1px solid #dfdfdf`,
    // box-sizing: border-box;
    cursor: "pointer",
    // border-radius: 40px;
    // border: 1px solid transparent;

    // height: 60,
    justifyContent: "stretch",
    alignItems: "stretch",
    fontSize: 10,
    display: "flex",

    flexDirection: "column",
    ["&:hover"]: {
      backgroundColor: "#fafafa",
      border: "2px solid black",
      ["& .footer"]: {
        ["& .tools"]: {
          display: "initial !important"
        }
      }
    },
    ["& .header"]: {
      display: "flex",
      //   backgroundColor: "pink",
      padding: "4px 8px",
      ["& .name"]: {
        fontWeight: 900,
        fontSize: 12
      }
    },
    ["& .content"]: {
      padding: "4px 8px",
      //   backgroundColor: "yellow",
      overflow: "hidden",
      flex: 1
    },
    ["& .footer"]: {
      backgroundColor: "#cccccc80",
      height: 24,
      padding: "4px 8px",
      display: "flex",
      ["& .tools"]: {
        display: "none"
      },

      ["& :hover"]: {}
    }
  }
})
const dowNames = ["Pon", "Wt", "Sr", "Czw", "Pt", "Sob", "Ndz"]

const DaysTableStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, [col-start] 1fr);
  grid-template-rows: repeat(5, 100px);
  width: 100%;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
`

export const CalendarWidget = ({ venue, refetch }) => {
  const [weeksData, setWeeksData] = React.useState([])
  const [startDate, setStartDate] = React.useState(startOfMonth(new Date()))
  const [popupDay, setPopupDay] = React.useState(undefined)
  const { specialDates, refetch: refetchSpecialDates } = useOwnerSpecialDates(
    venue.merchantId,
    venue.id
  )
  const classes = useStyles()

  const handleDateSelected = (date) => {
    console.log("handleDateSelected", date)
    // props.onDateSelected(format(date, "dd-MM-yyyy"))
  }

  const recalculateWeeks = () => {
    console.log("recalculateWeeks", startDate)
    var weeks = []
    var weekStart = startOfWeek(startDate, { weekStartsOn: 1 })

    for (var i = 0; i < 5; i++) {
      weeks.push({
        startDate: weekStart
      })
      weekStart = addWeeks(weekStart, 1)
    }

    console.log("weeks", weeks)

    setWeeksData(weeks)
  }
  useEffect(() => {
    recalculateWeeks()
  }, [startDate])

  const renderHeader = () => {
    return (
      <Box display="flex" className={classes.header} alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => changeMonth(-1)} size="large">
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ width: 30, height: 30 }}
            />
          </IconButton>
        </Box>
        <Box flex={1} textAlign="center">
          {format(startDate, "LLLL")}
        </Box>
        <Box className="actionWrapper">
          <IconButton onClick={() => changeMonth(1)} size="large">
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ width: 30, height: 30 }}
            />
          </IconButton>
        </Box>
      </Box>
    );
  }
  const renderWeekDays = (week, index) => {
    var widgets = []

    var days = []

    for (var i = 0; i < 7; i++) {
      var day = addDays(week.startDate, i)
      days.push(new Date(day.getTime()))
    }
    console.log("days", days)

    return (
      <>
        {days.map((day: any, i) => {
          var daySpecials =
            specialDates != null
              ? specialDates.filter((d) => d.date === format(day, "yyyy-MM-dd"))
              : []
          // console.log("daySpecials", daySpecials)
          var isUnavailable = daySpecials.find((s) => s.type == "UNAVAILABLE")
          var _day = new Date(day.getTime())
          // console.log("_day", _day)

          const handleDateClicked = (event) => {
            // console.log("on click", _day, event.currentTarget.dataset)
            setPopupDay(_day)
          }
          return (
            <div
              style={{
                gridRow: index + 1,
                gridColumn: i + 1
              }}
              className={`${classes.dayWrapper} 
            ${isUnavailable ? classes.dayUnavailable : ""}
          ${day.getDate() % 3 == 0 ? "available" : "unavailable"}`}
              onClick={handleDateClicked}
            >
              <div className="header">
                <div className="name">{day.getDate()}</div>
              </div>
              <div className="content">
                {/* Tu rezerwacje albo cos */}
                {/* {isUnavailable ? <span>NIEDOSTEPNE</span> : <span />} */}
              </div>

              {/* <div className="footer">
            <Box flex={1}></Box>
            <div className="tools">
              {/* <FontAwesomeIcon
                icon={faToolbox}
                onClick={() => {
                  console.log("Show toolbox")
                }}
                style={{ width: 20, height: 20 }}
              /> */}
              {/* </div> */}
              {/* </div> */}
              {/* {props.showAvailability && (
                  <div style={{ fontSize: 8, padding: 2 }}>3 terminy</div>
                )} */}
              {/* {props.showPrices && <div>150zl</div>} */}
            </div>
            // </div>
          )
        })}
      </>
    )
  }

  const changeMonth = (delta) => {
    setStartDate(addMonths(startDate, delta))
  }
  const renderHeaders = () => {
    var widgets = []
    for (var i = 0; i < 7; i++) {
      widgets.push(<th>{dowNames[i]}</th>)
    }
    return <>{widgets}</>
  }

  return (
    <Box className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 8,
          marginBottom: 8,
          alignItems: "center",
          backgroundColor: "white"
        }}
      >
        <div style={{ width: 20 }}></div>
        <div style={{ flex: 1, textAlign: "center" }}></div>
        <div style={{ width: 20, padding: 4 }}></div>
      </div>
      {renderHeader()}
      <DaysTableStyled>
        {weeksData.map((week, index) => {
          return renderWeekDays(week, index)
        })}
      </DaysTableStyled>
      <CalendarDayPopup
        open={popupDay !== undefined}
        day={popupDay}
        onClose={() => {
          setPopupDay(undefined)
        }}
        refetch={refetch}
        refetchSpecialDates={refetchSpecialDates}
        venue={venue}
        specialDates={specialDates}
      />
    </Box>
  )
}
