import { Container } from "@mui/material"
import { useHistory } from "react-router-dom"

import { MerchantForm } from "../../../modules/merchants/MerchantForm"

export default function NewMerchantPage() {
  const router = useHistory()
  return (
    <Container maxWidth="xl">
      <div>Dodaj Firmę (tryb administratora)</div>
      <MerchantForm
        isEdited
        adminMode={true}
        onSaved={(value) => {
          console.log("On merchant saved")
          console.log(value)
          router.push("/admin/merchants")
        }}
      />
    </Container>
  )
}
