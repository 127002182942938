import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useHistory } from "react-router-dom"
import { useMerchantContext } from "src/contexts/merchant-context"

import { MojeCard } from "../../components/card/MojeCard"

const useStyles = makeStyles({
  root: {
    margin: "16px 0",
    backgroundColor: "white",
    borderRadius: 4,
    padding: "8px 16px",
    border: "1px solid var(--moje-border-color) !important"
    // boxShadow: "0 0px 3px 0px rgba(50, 50, 60, 0.2);"
    // boxShadow: "0 0px 10px 0px rgba(50, 50, 60, 0.1)"
  },
  title: {
    fontSize: 24,
    marginTop: 24
  },
  stepsWrapper: {
    display: "flex"
  },
  stepCompleted: {
    backgroundColor: "var(--clap-color-green-light)",
    textColor: "var(--moje-color-green-dark)"
  },
  step: {
    flex: 1,
    padding: 8
  },
  stepName: {
    fontWeight: 600,
    fontSize: 12,
    display: "flex",
    ["& .icon"]: {
      marginRight: 8
    },
    ["& .tools"]: {
      width: "100%",
      flex: 1,
      textAlign: "right"
    }
  },
  stepDescription: {
    fontWeight: 400,
    fontSize: 12
  },
  subStep: {
    fontSize: 10
  }
})

export const HomeOnboardingWidget = (props) => {
  const classes = useStyles()
  const merchantContext = useMerchantContext()
  const history = useHistory()
  const merchantCreated =
    merchantContext.merchants && merchantContext.merchants.length > 0
  const stepTitle = (label, isCompleted, toolsComponent = undefined) => {
    return (
      <div className={classes.stepName}>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="icon"
          style={{ width: 24, height: 24 }}
        />
        <div className="name">{label}</div>
        <div className="tools">{toolsComponent}</div>
      </div>
    )
  }
  return (
    <MojeCard title="Skonfiguruj MojeUrodziny i zacznij przyjmować rezerwacje!">
      <div className={classes.title}></div>
      <div className={classes.stepsWrapper}>
        <div className={`${classes.step} ${classes.stepCompleted}`}>
          {stepTitle("Wprowadź dane firmy", false)}
          <ul>
            <li className={classes.subStep}>Kontakt dla MojeUrodziny</li>
            <li className={classes.subStep}>Dane kontaktowe dla klientów</li>
          </ul>
          {!merchantCreated && (
            <div>
              <Button
                onClick={() => {
                  history.push("/merchants/new")
                }}
                variant="contained"
                color="primary"
              >
                Wprowadź dane firmy
              </Button>
            </div>
          )}
        </div>
        <div className={classes.step}>
          {stepTitle("Dodaj obiekt/usługę", false, <></>)}
          <ul>
            <li className={classes.subStep}>Podstawowe dane i opis</li>
            <li className={classes.subStep}>Zdjęcia</li>
            <li className={classes.subStep}>Scenariusze Zabaw</li>
            <li className={classes.subStep}>Dostępne terminy</li>
            <li className={classes.subStep}>Oferta</li>
          </ul>
          {merchantCreated && (
            <Button
              onClick={() => {
                history.push("/venues/new")
              }}
              variant="contained"
              color="primary"
            >
              Dodaj
            </Button>
          )}
        </div>
        <div className={classes.step}>
          {stepTitle("Rezerwacje (już wkrótce)", false)}
          <ul>
            <li className={classes.subStep}>Kalendarz</li>
            <li className={classes.subStep}>Zarządzanie rezerwacjami</li>
            <li className={classes.subStep}>Komunikacja z klientami</li>
            <li className={classes.subStep}>Zarządzanie promocjami</li>
            <li className={classes.subStep}>Rezerwacje</li>
          </ul>
        </div>
      </div>
    </MojeCard>
  )
}
