import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useState } from 'react'
import Cropper from 'react-easy-crop'

export const PhotoCropPopup = ({
  photo,
  isNew,
  onClose,
  open,
  onSave
}: {
  photo?: any
  isNew?: boolean
  onClose: any
  open: boolean
  onSave: any
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const handleSave = async () => {
    console.log("handleSave")
    onSave({
      ...crop,
      zoom
    })
  }

  console.log("Render photo crop popup", photo, zoom, crop)

  const onCropComplete = (res) => {
    console.log("onCropComplete", res)
    setCrop(res)
  }
  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle id="simple-dialog-title">Przytnij zdjęcie</DialogTitle>
      {/* <Box style={getModalStyle()}> */}
      {/* <Box>
        <Typography>Edytuj dzien</Typography>
      </Box> */}
      <DialogContent
        style={{ width: "fit-content", minWidth: "500px" }}
      ></DialogContent>
      <DialogActions>
        <Box display="flex" padding={1} width="100%" flexDirection="column">
          <Box flex={1} width={300} minHeight={200} position="relative" mb={1}>
            <Cropper
              image={photo?.url}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Box display="flex">
            <Button onClick={onClose} color="secondary" variant="text">
              Anuluj
            </Button>
            <Box flex={1} />
            <Button onClick={handleSave} color="primary" variant="contained">
              Zapisz
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
