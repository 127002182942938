import { Button } from '@mui/material'
import { PageContainer } from 'src/components/containers/PageConatiner'

import { ErrorBoundary } from '../../components/ErrorBoundary'
import { SectionMedia } from '../../components/media/SectionMedia'
import Tabs from '../../components/Tabs'
import useTab from '../../components/Tabs/useTab'
import { PlaceSectionCalendar } from './components/calendar/PlaceSectionCalendar'
import { PlaceSectionFAQ } from './components/faq/PlaceSectionFAQ'
import { PlaceSectionOpeningHours } from './components/open-hours/PlaceSectionOpeningHours'
import { PlaceSectionProducts } from './components/pricing-form/place-section-products'
import { PlaceSectionAvailability } from './components/special-dates/PlaceSectionAvailability'
import { VenueSectionTimeSlots } from './components/time-slots/venue-section-time-slots'
import { PlaceForm } from './PlaceForm'

export const VenuePageLayout = ({
  venue,
  isEdited,
  refetch,
  onAddMedia,
  onRemoveMedia,
  adminMode
}) => {
  const currentTab = useTab() ?? "general"

  const placePreviewUrl = `https://www.mojeurodziny.pl/miejsce/${
    venue.slug ?? venue.id
  }`
  return (
    <PageContainer
      title={venue.name}
      subtitle={venue.slug ? `krótki adres: ${venue.slug}` : ""}
      toolsComponents={
        <>
          <a href={placePreviewUrl} target="_blank">
            <Button variant="outlined">Podgląd strony</Button>
          </a>
        </>
      }
    >
      <Tabs
        tabs={[
          {
            label: "Lokalizacja",
            route: "general"
          },
          {
            label: "Zdjęcia",
            route: "media"
          },
          {
            label: "Godziny Otwarcia",
            route: "opening-hours"
          },
          {
            label: "Oferta",
            route: "prices"
          },
          {
            label: "Kalendarz",
            route: "calendar"
          },
          // {
          //   label: "Godziny",
          //   route: "slots"
          // },
          {
            label: "FAQ",
            route: "faq"
          }
        ]}
        aria-label="Venue tab"
      />
      <div>
        <ErrorBoundary>
          {currentTab === "general" && (
            <PlaceForm
              venue={venue}
              isEdited={isEdited}
              refetch={refetch}
              adminMode={adminMode}
            />
          )}
          {currentTab === "media" && (
            <SectionMedia
              media={venue.media}
              onAddMedia={onAddMedia}
              onRemoveMedia={onRemoveMedia}
            />
          )}
          {currentTab === "slots" && (
            <VenueSectionTimeSlots venue={venue} refetch={refetch} />
          )}
          {currentTab === "opening-hours" && (
            <PlaceSectionOpeningHours venue={venue} refetch={refetch} />
          )}
          {currentTab === "prices" && (
            <PlaceSectionProducts venue={venue} refetch={refetch} />
          )}
          {currentTab === "availability" && (
            <PlaceSectionAvailability venue={venue} refetch={refetch} />
          )}
          {currentTab === "calendar" && (
            <PlaceSectionCalendar venue={venue} refetch={refetch} />
          )}
          {currentTab === "faq" && (
            <PlaceSectionFAQ venue={venue} refetch={refetch} />
          )}
        </ErrorBoundary>
      </div>
    </PageContainer>
  )
}
