import GoogleMapReact from 'google-map-react'
import React from 'react'

import MapMarker from './map-marker'

const urlKeys = { key: "AIzaSyAodrP9bEZJwUu7RTIVgu6X7Ps5IYszvh4" }

class AddressMap extends React.PureComponent<any, any> {
  mapRef: any
  constructor(props: any) {
    super(props)
    let center = {
      lat: 59.95,
      lng: 30.33
    }

    if (props && props.location && props.location.lat) {
      center = {
        lat: props.location.lat,
        lng: props.location.lng
      }
    }
    this.state = {
      center,
      initialZoom: 13
    }
  }
  handleSetRef = (ref) => {
    this.mapRef = ref
  }
  handleOnGoogleApiLoaded = (event) => {
    console.log("handleOnGoogleApiLoaded")
    this.mapRef = event.map
  }

  createMapOptions() {
    if (!window || !window.navigator || !window.navigator.userAgent) {
      return {}
    }
    // const md = new MobileDetect(window.navigator.userAgent)
    // console.log('createMapOptions', md, md.mobile())
    const isMobile = false // md.mobile()
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: !isMobile, // Disable on mobile
      fullscreenControl: false,
      gestureHandling: "greedy",
      clickableIcons: false,
      maxZoom: 18,
      styles: [
        {
          stylers: [
            { saturation: -85 },
            { gamma: 0.95 },
            { lightness: 40 },
            { visibility: "on" }
          ]
        },
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }]
        }
      ]
    }
  }
  onCircleInteraction = (childKey, marker, mouse) => {
    console.log("onCircleInteraction", childKey, marker, mouse)
    if (marker) {
      this.setState({ isDraggingMarker: true })
    }
  }

  onMarkerDragEnd = (childKey, marker, mouse) => {
    console.log("onMarkerDragEnd", childKey, marker, mouse)
    console.log(this.props)
    if (marker) {
      if (this.props.onMarkerMoved) {
        this.props.onMarkerMoved({
          lat: this.state.lat,
          lng: this.state.lng
        })
      }
      this.setState({ isDraggingMarker: false, lat: undefined, lng: undefined })
    }
  }
  onMarkerMove = (childKey, marker, mouse) => {
    console.log("onMarkerMove", childKey, marker, mouse)
    if (marker) {
      this.setState({ lat: mouse.lat, lng: mouse.lng })
    }
  }
  render() {
    const location =
      this.props.location && this.props.location.lat
        ? this.props.location
        : undefined
    // console.log("Render AddresMap ", this.props, location)
    const item = {
      name: this.props.name
    }
    if (location && this.state.lat && this.state.lng) {
      location.lat = this.state.lat
      location.lng = this.state.lng
    }

    return (
      <div style={{ width: "100%", height: 250 }}>
        <GoogleMapReact
          draggable={!this.state.isDraggingMarker}
          ref={this.handleSetRef}
          bootstrapURLKeys={urlKeys}
          onGoogleApiLoaded={this.handleOnGoogleApiLoaded}
          options={this.createMapOptions}
          // defaultCenter={this.center}
          center={location ? location : this.state.center}
          zoom={location ? 16 : this.state.initialZoom}
          defaultZoom={this.state.initialZoom}
          className="GMap"
          onChildMouseDown={this.onCircleInteraction}
          onChildMouseUp={this.onMarkerDragEnd}
          onChildMouseMove={this.onMarkerMove}
          onChildClick={() => console.log("child click")}
        >
          {location && (
            <MapMarker item={item} lat={location.lat} lng={location.lng} />
          )}
        </GoogleMapReact>
      </div>
    )
  }
}

export default AddressMap
