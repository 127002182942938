import { Container } from "@mui/material"
import { useHistory } from "react-router-dom"

import { MerchantForm } from "../../modules/merchants/MerchantForm"

export default function NewMerchant() {
  const router = useHistory()
  return (
    <Container maxWidth="xl">
      <MerchantForm
        isEdited
        onSaved={(value) => {
          console.log("On merchant saved")
          console.log(value)
          router.push("/")
        }}
      />
    </Container>
  )
}
