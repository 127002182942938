import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'

import { SpecialConditionForm } from './SpecialConditionForm'
import { SpecialDatesList } from './SpecialDatesList'

export const PlaceSectionAvailability = ({ venue, refetch }) => {
  const [isAdding, setIsAdding] = React.useState(false)
  return (
    <Box>
      <Box display="flex">
        <Typography>
          W tym miejscu możesz wprowadzić terminy w któych Twoja sala nie jest
          dostępna (np. inne rezerwacje lub święta)
        </Typography>
        <div style={{ flex: 1 }} />
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsAdding(true)
          }}
        >
          Dodaj specjalne warunki
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsAdding(true)
          }}
        >
          Dodaj niedostępny termin
        </Button>
      </Box>
      {isAdding && (
        <Paper elevation={2}>
          <SpecialConditionForm
            onSaved={() => {
              setIsAdding(false)
            }}
            venue={venue}
          ></SpecialConditionForm>
        </Paper>
      )}
      {!isAdding && (
        <SpecialDatesList merchantId={venue.merchantId} venueId={venue.id} />
      )}
    </Box>
  )
}
