import { useState } from "react"
import axios from "axios"

export const useProfileApi = () => {
  const [isSaving, setIsSaving] = useState(false)
  const runCommand = async (command) => {
    console.log("Runing command")

    let commandUrl =
      process.env.REACT_APP_API_URL + `/me/` + command.aggregateId

    return new Promise(async (resolve, reject) => {
      setIsSaving(true)

      var result = await axios({
        method: "POST",
        url: commandUrl,
        data: {
          ...command
        }
      })

      if (result && result.status == 200) {
        resolve(result.data)
      } else {
        reject(result.data)
      }
    })
  }

  return { runCommand, isSaving }
}
