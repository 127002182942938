export class LicenseUtils {
  static licenseStatusLabel(value: string) {
    if (value === "ACTIVE") {
      return "Aktywna"
    }
    if (value === "TRIAL") {
      return "Trial"
    }
    if (value === "SUSPENDED") {
      return "Zawieszona"
    }
    return value
  }
  static licensePackageName(value: string) {
    if (value === "MOJE_2022") {
      return "Moje 2022"
    }

    return value
  }
}
