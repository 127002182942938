import { Box } from '@mui/material'
import { PageHeader } from 'src/components/PageHeader'
import { PaymentsTable } from 'src/modules/payments/PaymentsTable'

export const CompanySectionPayment = (props) => {
  // const url = `${process.env.REACT_APP_API_URL}/v1/payments`
  // const [{ data, loading, error }, refetch] = useAxios(
  //   {
  //     url
  //   },
  //   {
  //     useCache: false
  //   }
  // )

  return (
    <Box>
      <PageHeader
        title="Historia płatności"
        // subtitle="Płatności abonamentu"
        toolsComponents={() => {
          return (
            <>
              {/* {!editedMerchant &&
                  !isCreating &&
                  merchants &&
                  merchants.length > 0 && (
                    <MButton primary onClick={() => setIsCreating(true)}>
                      Dodaj Firmę
                    </MButton>
                  )} */}
            </>
          )
        }}
      ></PageHeader>

      <PaymentsTable />
      {/* {loading && <LoadingWidget />}
      {data && (
        <>
          <Table>
            <thead>
              <tr>
                <Th>Data</Th>
                <Th>Rodzaj</Th>
                <Th>Kwota</Th>
                <Th>Status</Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              {data.items &&
                data.items.map((r: any) => {
                  return (
                    <tr>
                      <Td>
                        {r.createdAt && (
                          <>
                            <FormattedDate value={r.createdAt} />{" "}
                            <FormattedTime value={r.createdAt} />
                          </>
                        )}
                      </Td>
                      <Td>{PaymentUtils.paymentTypeLabel(r.type)}</Td>
                      <Td>{PaymentUtils.formatAmount(r.amount)}</Td>
                      <Td>{PaymentUtils.formatStatus(r.status)}</Td>
                      {/* <Td>{r.finalConditions?.totalPrice} zł</Td>
                      <Td>
                        <a href={`${prefix}/${r.id}`} target="_blank">
                          <Button variant="outlined" color="primary">
                            Szczegóły
                          </Button>
                        </a>
                      </Td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
          {/* {data} 
        </>
      )} */}
    </Box>
  )
}
