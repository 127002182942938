import { Container } from '@mui/material'
import { Box } from '@mui/system'

import { PageHeader } from '../PageHeader'

export const PageContainer = ({
  children,
  title,
  subtitle,
  toolsComponents
}: {
  children: any
  title?: string
  subtitle?: string
  toolsComponents?: any
}) => {
  return (
    <Container>
      <Box sx={{ paddingTop: "56px" }}>
        {title && (
          <PageHeader
            title={title}
            subtitle={subtitle}
            toolsComponents={toolsComponents}
          ></PageHeader>
        )}
        {children}
      </Box>
    </Container>
  )
}
