import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import Tabs, { TabsProps } from "@mui/material/Tabs"
import Tab, { TabProps } from "@mui/material/Tab"

type CustomTabsProps = {
  tabs: { route: string; label: string; props?: TabProps }[]
  defaultTabIndex?: number
  useRoutes?: boolean
} & TabsProps

export default function CustomTabs({
  tabs,
  defaultTabIndex = 0,
  ...props
}: CustomTabsProps) {
  const history = useHistory()
  const { search, ...location } = useLocation()
  const query = new URLSearchParams(search)
  const match = useRouteMatch()

  var tabIndex = tabs.findIndex((tab) => tab["route"] === query.get("tab"))

  if (props.useRoutes) {
    console.log("location", location)
    tabIndex = tabs.findIndex(
      (tab) => tab["route"].indexOf(location.pathname) === 0
    )
  }
  const tabValue = tabIndex > -1 ? tabIndex : defaultTabIndex

  return (
    <Tabs
      value={tabValue}
      onChange={(event, newValue) => {
        if (props.useRoutes) {
          history.push(tabs[newValue]["route"])
        } else {
          query.set("tab", tabs[newValue]["route"])
          history.push({
            search: "?" + query.toString()
          })
        }
      }}
      sx={{
        borderBottom: "1px solid #ddd",
        margin: 2,
        "& .MuiTabs-indicator": {
          height: "2.5px",
          backgroundColor: "var(--moje-color-blue-ocean)"
        }
      }}
      textColor="inherit"
      {...props}
    >
      {tabs.map((tab) => (
        <Tab
          disableRipple
          label={tab["label"]}
          sx={{
            textTransform: "none",
            minWidth: {
              xs: "unset"
            },
            fontWeight: 600,
            opacity: 1,
            "&.Mui-selected": {
              color: "var(--moje-color-blue-ocean)"
            }
          }}
          {...tab["props"]}
        />
      ))}
    </Tabs>
  )
}
